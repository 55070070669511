import React from 'react';
import PrivacyLeftPanel from '../PrivacyLeftPanel/PrivacyLeftPanel';
import PrivacyRightPanel from '../PrivacyRightPanel/PrivacyRightPanel';

const PrivacyBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <PrivacyLeftPanel moduleName="privacy" />
          <PrivacyRightPanel />
        </div>
      </div>
    </section>
  );
};

export default PrivacyBody;
