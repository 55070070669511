import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const IndustryInterestedModal = () => {
  const { t } = useTranslation(); // translation marker
  // select data --------------------------
  const skills = [
    { label: t('Information Technology'), value: 'Information Technology' },
    { label: t('Aerospace'), value: 'Aerospace' },
    { label: t('Defence'), value: 'Defence' },
    { label: t('Automobiles'), value: 'Automobiles' },
    { label: t('Finance'), value: 'Finance' },
    { label: t('Banking'), value: 'Banking' },
    { label: t('Hospital'), value: 'Hospital' },
    { label: t('Marketing'), value: 'Marketing' },
  ];
  // useState to select data ------------------
  const [selectSkills, setSelectSkills] = useState(null);
  return (
    <div className="process_modal esg_modal">
      <div className="modal fade show" id="esg_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="process_hdng w-100">
                <h3>{t('Industry interested')}</h3>
                <p>{t('Please add at least 5 industry to continue"')}</p>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="multiple_select_area">
                  <div className="multiple_slct_bx">
                    <Select
                      placeholder="Select Role"
                      options={skills}
                      value={selectSkills}
                      onChange={val => {
                        setSelectSkills(val);
                      }}
                    />
                  </div>
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Deface
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Automobiles
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Finance
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Banking
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Hospital
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Marketing
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <Link to="#" data-bs-dismiss="modal" className="btn gray">
                    {t('Cancel')}
                  </Link>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#interest_success_mdl"
                    data-bs-dismiss="modal"
                    className="btn"
                  >
                    {t('Done')}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryInterestedModal;
