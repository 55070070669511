/* eslint-disable */
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
/*------ import url and method ------*/
import * as url from "helper/UrlHelper";

const SuccessStoriesLandingTestimonials = ({ storyList }) => {
  const { t } = useTranslation(); // translation marker
  const responsive = {
    singleSlide: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="succes_rewards_sec">
      <div className="container">
        <div className="success_testimonial_bx">
          {/* <div className="testimonial_slider">
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>
                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>
                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>
                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>
                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
          </div> */}
          {/* --- carousel start ---- */}
          <Carousel responsive={responsive} arrows={false} showDots infinite>
            {storyList.map((storyData, index) => {
              return (
                <div className="success_quote_item" key={index}>
                  <div className="success_quote_outer">
                    <div className="quote_leftpnl">
                      <span>
                        <img src="images/koma.png" alt="" />
                      </span>
                      <h4>CONSUMER TECH</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: storyData.ownertestimonial,
                        }}
                      ></p>
                      <div className="quote_author">
                        <h3>{storyData.companyowner}</h3>
                        <h5>{storyData.companyname}</h5>
                      </div>
                    </div>

                    <div className="quote_rightpnl">
                      <img
                        className="rounded"
                        src={url.SERVER_URL + storyData.companylogo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="success_quote_item">
              <div className="success_quote_outer">
                <div className="quote_leftpnl">
                  <span>
                    <img src="images/koma.png" alt="" />
                  </span>
                  <h4>CONSUMER TECH</h4>
                  <p>
                    "Mana Common empowers Visionary Industries. Their
                    data-driven insights and user-friendly interfaces drive
                    collaboration and informed decisions. A crucial partner for
                    CEOs focused on expansion."
                  </p>
                  <div className="quote_author">
                    <h3>Monie Disusa</h3>
                    <h5>Project Manager, Indra</h5>
                  </div>
                </div>

                <div className="quote_rightpnl">
                  <img src="images/findeldrone.svg" alt="" />
                </div>
              </div>
            </div> */}
          </Carousel>
          {/* --- carousel end ---- */}
        </div>

        <div className="rewards_outer">
          <div className="row">
            <div className="col-lg-4">
              <div className="rewards_point_item">
                <h2>3X</h2>
                <p>
                  {t("Improvements on")} <br /> {t("NPSsince 2019")}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rewards_point_item">
                <h2>242M</h2>
                <p>
                  {t(
                    "Product questions answered per year by our users with Lowbees"
                  )}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rewards_point_item">
                <h2>97.2%</h2>
                <p>
                  {t("Customer satisfaction score across")} <br />{" "}
                  {t("all support experiences")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesLandingTestimonials;
