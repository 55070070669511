/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const AddCommentModal = ({ onModalClose, forumId, selectedCommentId }) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");

  const [description, setdescription] = useState("");

  const getCommentDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COMMENT_DETAILS +
        `/${selectedCommentId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setdescription(response.data.description);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const saveCommentHandler = async () => {
    try {
      let forumData = {
        description,
      };

      let requestURL = url.API_BASE_URL;

      let response = null;

      if (selectedCommentId) {
        requestURL =
          requestURL +
          url.API_UPDATE_COMMENT +
          `/${selectedCommentId}?token=${token}`;

        response = await putData(requestURL, forumData);
      } else {
        requestURL =
          requestURL +
          url.API_ADD_COMMENT +
          `?token=${token}&forumid=${forumId}`;

        response = await postData(requestURL, forumData);
      }

      resetHandler();

      console.log(response);

      if (response.status) {
        //hide login modal
        let postModal = document.querySelector("#add_comment_modal");
        let modal = bootstrap.Modal.getInstance(postModal);
        modal.hide();

        onModalClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setdescription("");
  };

  useEffect(() => {
    if (selectedCommentId) {
      getCommentDetails();
    }
  }, [selectedCommentId]);

  return (
    <div className="post_modal">
      <div
        className="modal fade"
        id="add_comment_modal"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comment
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_left">
                <div className="pofile_pic">
                  <img
                    src={
                      userInfo.photoimage
                        ? url.SERVER_URL + userInfo.photoimage?.path
                        : assetImages.defaultUser
                    }
                    alt=""
                  />
                </div>
                <div className="pofile_info">
                  <h3>
                    {userInfo.name} {userInfo.surname}
                  </h3>
                  <Link to="#">
                    <i className="material-icons">schedule</i>
                    <span>{todayValue}</span>
                  </Link>
                </div>
              </div>

              {/* details */}
              <div className="post_description">
                <textarea
                  className="form-control"
                  placeholder="Add your comments"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                ></textarea>
              </div>

              <div className="post_btn" onClick={saveCommentHandler}>
                <button
                  className="btn"
                  // data-bs-toggle="modal"
                  // data-bs-dismiss="modal"
                  // data-bs-target="#gretings_mdl"
                >
                  Post Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommentModal;
