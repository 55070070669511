/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import ContactModal from "../Modals/ContactModal";
import GroupModal from "../Modals/GroupModal";
import CreateNewGroupModal from "../Modals/CreateNewGroupModal";

import { playAudioTone } from "helper/Common/CommonHelper";
import ChatLeftPanelPlaceholder from "./Placeholder/ChatLeftPanelPlaceholder";

const ConversationLeftPanel = ({
  selectedConversationId = null,
  io,
  updateDefaultScreen,
  updateSelectedChat,
  updateConversationName,
  updateSelectedChatLogo,
  updateSelectedChatEmail,
  updatedSelectedChatRoom,
  updatedSelectedChatDetails,
  updatedGroupModeratorName,
  updatedGroupModeratorImage,
  updatedGroupMembers,
  updatedGroupFiles,
  updateGroupModeratorId,
  updateGroupMemberString,
  setchatcomponentName = () => {},
  setchatComponentTitle = () => {},
  socket,
  allContacts,
  setAllContacts,
}) => {
  // const socket = io(url.SERVER_URL);

  const {
    setreloadChatPanel,
    reloadConversationLeftPanel,
    setReloadConversationLeftPanel,
    afterLeaveGroupReload,
    setafterLeaveGroupReload,
    isUnreadActive,
    setisUnreadActive,
    reloadContactList,
    setReloadContactList,
  } = useContext(GlobalProvider);

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const glowPlaceholderCount = [1, 2, 3, 4, 5, 6, 7];

  const { t } = useTranslation(); // translation marker

  const [chatList, setChatList] = useState([]);

  const [allGroups, setAllGroups] = useState([]);
  const [isChatPanelLoading, setIsChatPanelLoading] = useState(false);

  const [showChatPopup, setShowChatPopup] = useState(false);

  const [selectedChatParams, setselectedChatParams] = useState(null);

  const [selectedChatContactId, setselectedChatContactId] = useState(null); //state for remove require response and update require response
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [isClosingResponse, setisClosingResponse] = useState(false);

  const [selectedGroupId, setselectedGroupId] = useState(null);

  const [newMessageNotification, setnewMessageNotification] = useState(false);

  const [unreadMessages, setunreadMessages] = useState([]);

  //select contact chat
  const chatSelectionHandler = (
    conversationId, //mongo id of contact account
    conversationName, // contact name or group name
    conversationLogo, // contact image or group logo
    conversationEmail, // contact email . If gorup is empty string
    screen = "contact", //it can be contact or group
    conversationChatId, // contact mongo id or group mongo id. It will use for chat room id in socket
    conversationChatPhone,
    conversationChatAddress,
    conversationChatCompany,
    componentName = "",
    componentTitle = "",
    groupModeratorName = "",
    groupModeratorImage = "",
    groupMembers = [],
    groupFiles = [],
    groupModeratorId = null,
    groupMemberString = ""
  ) => {
    document.body.classList.toggle("chatmsg_open");
    updateDefaultScreen(screen);
    updateSelectedChat(conversationId);
    updateConversationName(conversationName);
    updateSelectedChatLogo(conversationLogo);
    updateSelectedChatEmail(conversationEmail);
    updatedSelectedChatRoom(conversationChatId);
    updatedSelectedChatDetails({
      conversationChatPhone,
      conversationChatAddress,
      conversationChatCompany,
    });

    updatedGroupModeratorName(groupModeratorName);
    updatedGroupModeratorImage(groupModeratorImage);
    updatedGroupMembers(groupMembers);
    updatedGroupFiles(groupFiles);
    updateGroupModeratorId(groupModeratorId);
    updateGroupMemberString(groupMemberString);

    if (componentName !== "") {
      setchatcomponentName(componentName);
    } else {
      setchatcomponentName("");
    }

    if (componentTitle !== "") {
      setchatComponentTitle(componentTitle);
    } else {
      setchatComponentTitle("");
    }

    updateChatStatusHandler(conversationChatId, conversationId);
  };

  //function for get all contacts for mapping in contact modal
  const getAllContactList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CONTACTS + `?token=${token}`;

      // console.log("url of contact list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in contact list------>", response);

      if (response.status) {
        setAllContacts(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all group records for mapping in group modal
  const getAllGroups = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_GROUP_LIST_FOR_CHAT + `?token=${token}`;

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in group list------->", response);

      if (response.status) {
        setAllGroups(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all chats
  const getAllUserChats = async (isLoading = false) => {
    try {
      isLoading && setIsChatPanelLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CHAT_LIST + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsChatPanelLoading(false);

      if (response.status) {
        setChatList(response.data);

        const unreadChats =
          response.data.length > 0
            ? response.data.filter((chat) => !chat.islastemessageseen)
            : [];

        if (unreadChats.length > 0) {
          setisUnreadActive(true);
          setunreadMessages(unreadChats);
        } else {
          setisUnreadActive(false);
          setunreadMessages([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for remove require response
  const removeRequireResponseHandler = async (
    contact = {},
    moduleName = "contact"
  ) => {
    try {
      setisClosingResponse(true);

      let chatData = {
        chatpersonid: contact.userid,
      };

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_REQUIRE_RESPONSE + `?token=${token}`;

      const response = await putData(requestURL, chatData);

      console.log(response);

      setisClosingResponse(false);

      if (response.status) {
        if (selectedChatContactId && selectedChatContactId === contact._id) {
          setreloadChatPanel(true);
        }

        getAllContactList();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get module details
  const getModuleDetails = async (
    conversationType = "",
    conversationId = null,
    moduleSlug = "",
    componentName = "",
    componentTitle = ""
  ) => {
    if (conversationType !== "" && moduleSlug !== "" && conversationId) {
      try {
        let requestUrl = url.API_BASE_URL;

        if (moduleSlug === "MOD_CONTACT") {
          requestUrl += `${url.API_GET_CONTACT_DETAILS}/${conversationId}?token=${token}`;

          const response = await getData(requestUrl);

          // console.log(response);

          if (response.status) {
            const data = response.data;

            setselectedChatParams({
              contactpersonid: data.contactpersonid,
              contactname: data.contactname,
              contactimage: data.contactimage,
              contactemail: data.contactemail,
              conversationType,
              id: data._id,
              contactphone: data.contactphone,
              address: data.contactcity + ", " + data.contactcountry,
              contactcompany: data.contactcompany,
              componentName,
              componentTitle,
            });
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for after create group modal close
  const afterGroupModalClose = (groupResponse = {}) => {
    // console.log(groupResponse);
    chatSelectionHandler(
      groupResponse.userid,
      groupResponse.contactname,
      groupResponse.contactimage,
      groupResponse.contactemail,
      "group",
      groupResponse.userid,
      groupResponse.contactphone,
      groupResponse.location,
      groupResponse.contactcompany,
      "",
      "",
      groupResponse.moderatorname,
      groupResponse.moderatorimage,
      groupResponse.groupmemberlist,
      groupResponse.uploads,
      groupResponse.moderatorid,
      groupResponse.groupmemberstring
    );
    getAllGroups();
  };

  //update chat status
  const updateChatStatusHandler = async (
    conversationChatId = null,
    conversationId = null
  ) => {
    if (conversationChatId && conversationId) {
      setisUnreadActive(false);
      document.title = "Conversation"; // Reset to default title
      try {
        let chatStatusData = {
          group: null,
          contactid: null,
          personid: null,
        };

        if (conversationChatId.toString() === conversationId.toString()) {
          chatStatusData.group = conversationId;
        } else {
          chatStatusData.contactid = conversationChatId;
          chatStatusData.personid = conversationId;
        }

        console.log(chatStatusData);

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_TRACKERS + `?token=${token}`;

        const response = await putData(requestURL, chatStatusData);

        console.log(response);

        if (response.status) {
          getAllUserChats();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    if (selectedChatParams) {
      chatSelectionHandler(
        selectedChatParams.contactpersonid,
        selectedChatParams.contactname,
        selectedChatParams.contactimage,
        selectedChatParams.contactemail,
        selectedChatParams.conversationType,
        selectedChatParams.id,
        selectedChatParams.contactphone,
        selectedChatParams.address,
        selectedChatParams.contactcompany,
        selectedChatParams.componentName,
        selectedChatParams.componentTitle,
        "",
        "",
        [],
        [],
        null,
        ""
      );
      setselectedChatParams(null);
    }
  }, [selectedChatParams]);

  useEffect(() => {
    setselectedChatContactId(null);
    setchatcomponentName("");
    setchatComponentTitle("");
    getAllContactList();
    getAllGroups();

    getAllUserChats(true);

    if (
      params.conversationtype ||
      params.conversationid ||
      params.moduleslug ||
      params.componentname ||
      params.componenttitle
    ) {
      if (params.moduleslug === "MOD_CONTACT") {
        getModuleDetails(
          params.conversationtype,
          params.conversationid,
          params.moduleslug,
          params.componentname,
          params.componenttitle
        );
      }

      if (params.moduleslug === "MOD_AI") {
        chatSelectionHandler(
          null,
          "",
          "",
          "",
          "ai",
          null,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          [],
          [],
          null,
          ""
        );
      }
    }
  }, []);

  useEffect(() => {
    if (reloadConversationLeftPanel) {
      getAllUserChats();
      setReloadConversationLeftPanel(false);
    }
  }, [reloadConversationLeftPanel]);

  useEffect(() => {
    if (afterLeaveGroupReload) {
      setselectedChatContactId(null);

      document.body.classList.toggle("chatmsg_open");

      let chatDetailsObj = {
        conversationChatPhone: "",
        conversationChatAddress: "",
        conversationChatCompany: "",
      };

      updateDefaultScreen("welcome");
      updateSelectedChat(null);
      updateConversationName("");
      updateSelectedChatLogo("");
      updateSelectedChatEmail("");
      updatedSelectedChatRoom("");
      updatedSelectedChatDetails(chatDetailsObj);

      updatedGroupModeratorName("");
      updatedGroupModeratorImage("");
      updatedGroupMembers([]);
      updatedGroupFiles([]);

      setchatcomponentName("");
      setchatComponentTitle("");

      getAllUserChats();
      setafterLeaveGroupReload(false);
    }
  }, [afterLeaveGroupReload]);

  useEffect(() => {
    socket.on("chatleftpanelreload", (data = []) => {
      if (data) {
        // console.log(data);

        if (data.includes(userInfo._id.toString())) {
          setTimeout(() => {
            if (selectedConversationId) {
              playAudioTone();
            }
            getAllUserChats();
          }, 1000);
        }
      }
    });

    socket.on("seentickreload", (data) => {
      if (data) {
        setTimeout(getAllUserChats, 1000);
      }
    });

    return () => {
      socket.off("chatlistreload"); // Remove the listener
      socket.disconnect(); // Disconnect the socket
    };
  }, [socket]);

  useEffect(() => {
    let intervalId;
    let index = 0;

    const updateTitle = () => {
      if (index < unreadMessages.length) {
        const contactName = unreadMessages[index].contactname;

        document.title = `${contactName} sent you message`;

        index++;
      } else {
        index = 0;
        // clearInterval(intervalId);
        document.title = "Conversation"; // Reset to default title
      }
    };

    if (isUnreadActive && unreadMessages.length > 0) {
      intervalId = setInterval(updateTitle, 1000);
    }

    return () => {
      clearInterval(intervalId); // Cleanup on component unmount
    };
  }, [unreadMessages, isUnreadActive]);

  useEffect(() => {
    if (reloadContactList) {
      getAllContactList();
      setReloadContactList(false);
    }
  }, [reloadContactList]);

  return (
    <div className="chat_lftpnl">
      <div className="contact_header">
        <div className="contact_hdng_row d-flex align-items-center justify-content-between">
          <div className="post_hdng">
            <h4>Contacts</h4>
          </div>
          <div className="contacts_srch_right">
            <ul className="list_stye_none d-flex align-items-center gap-2">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">search</i>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">more_vert</i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="contacts_pnl">
        <div className="chat_usrPnl">
          <div className="chat_innrScroll">
            {/* <!-- ========== Start AI chat Block Section ========== --> */}
            <div className="ai_assistant">
              <div className="chat_hdng">
                <span>AI Assistant</span>
              </div>
              <div className="chat_usrRow send_msg_row active_user">
                <Link
                  to="#"
                  onClick={() => {
                    chatSelectionHandler(
                      null,
                      "",
                      "",
                      "",
                      "ai",
                      null,
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      [],
                      [],
                      null,
                      ""
                    );
                  }}
                >
                  <div className="usr_lftPnl">
                    <div className="chatting_user">
                      <img src={assetImages.aiLogoSvg} alt="" />
                    </div>
                    <div className="user_name">
                      <h3>LobeesAI</h3>
                      {/* <p>I’m looking forward</p> */}
                    </div>
                  </div>
                  <div className="msg_statusRight">
                    {/* <span className="msg_time">now</span> */}
                    {/* <span className="pending_msg">5</span> */}
                  </div>
                </Link>
              </div>
            </div>
            {/* <!-- ========== End AI chat Block Section ========== --> */}

            {/* <!-- ========== Start contact and group chat block Section ========== --> */}
            <div className="left_msgbody">
              <div className="chat_hdng d-flex align-items-center justify-content-between gap-2">
                <span>My Contacts</span>
                <Link
                  to="#"
                  className="text-gray"
                  onClick={() => getAllUserChats(true)}
                >
                  <i className="material-icons-outlined fs-lg">refresh</i>
                </Link>
              </div>

              {isChatPanelLoading
                ? glowPlaceholderCount.map((count, index) => {
                    return <ChatLeftPanelPlaceholder key={index} />;
                  })
                : chatList.map((chat, index) => {
                    return (
                      <div
                        className={`chat_usrRow ${
                          chat.moduleslug === "MOD_CONTACT"
                            ? "send_msg_row"
                            : "group_msg_row"
                        }`}
                        key={index}
                      >
                        <div className="d-flex align-items-center justify-content-between py-2 px-3 chat_user_item">
                          <div
                            className="usr_lftPnl"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setselectedChatContactId(chat._id);
                              chatSelectionHandler(
                                chat.userid,
                                chat.contactname,
                                chat.contactimage,
                                chat.contactemail,
                                chat.moduleslug === "MOD_CONTACT"
                                  ? "contact"
                                  : "group",
                                chat._id,
                                chat.contactphone,
                                chat.location,
                                chat.contactcompany,
                                "",
                                "",
                                chat.moderatorname,
                                chat.moderatorimage,
                                chat.groupmemberlist,
                                chat.uploads,
                                chat.moderatorid,
                                chat.groupmemberstring
                              );
                            }}
                          >
                            {/* <!-- ========== Start image Section ========== --> */}
                            <div className="chatting_user">
                              {chat.moduleslug === "MOD_CONTACT" ? (
                                <img
                                  src={
                                    chat.contactimage === ""
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + chat.contactimage
                                  }
                                  alt={chat.contactname}
                                />
                              ) : chat.contactimage === "" ? (
                                <>
                                  <figure className="group_usr">
                                    <img
                                      src={
                                        chat.moderatorimage === ""
                                          ? assetImages.defaultUser
                                          : url.SERVER_URL + chat.moderatorimage
                                      }
                                      alt=""
                                    />
                                  </figure>
                                  {chat.groupmemberlist
                                    .slice(0, 2)
                                    .map((member, index2) => {
                                      return (
                                        <figure
                                          className="group_usr"
                                          key={index2}
                                        >
                                          <img
                                            src={
                                              member.groupmemberimage === ""
                                                ? assetImages.defaultUser
                                                : url.SERVER_URL +
                                                  member.groupmemberimage
                                            }
                                            alt=""
                                          />
                                        </figure>
                                      );
                                    })}
                                  {chat.groupmemberlist.length > 2 ? (
                                    <span className="group_usr plus_usr">
                                      {chat.groupmemberlist.length - 2}{" "}
                                      <em>+</em>
                                    </span>
                                  ) : null}
                                </>
                              ) : (
                                <img
                                  src={
                                    chat.contactimage === ""
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + chat.contactimage
                                  }
                                  alt={chat.contactname}
                                />
                              )}
                            </div>
                            {/* <!-- ========== End image Section ========== --> */}

                            {/* <!-- ========== Start name and message Section ========== --> */}
                            <div className="user_name">
                              <h3
                                className={
                                  chat.lastmessagesender.toString() ===
                                  userInfo._id.toString()
                                    ? ""
                                    : chat.islastemessageseen
                                    ? ""
                                    : "fw-bold"
                                }
                              >
                                {chat.contactname}
                              </h3>
                              <p>
                                {chat.lastmessagesender.toString() ==
                                userInfo._id.toString() ? (
                                  <span>You : </span>
                                ) : null}
                                {chat.lastmessagetext == "" ? (
                                  ""
                                ) : (
                                  <span
                                    className={
                                      chat.lastmessagesender.toString() ===
                                      userInfo._id.toString()
                                        ? ""
                                        : chat.islastemessageseen
                                        ? ""
                                        : "fw-bold text-success"
                                    }
                                  >
                                    {chat.lastmessagetext.slice(0, 25)}
                                  </span>
                                )}{" "}
                              </p>
                            </div>
                            {/* <!-- ========== End name and message Section ========== --> */}
                          </div>

                          {/* <!-- ========== Start message status time and dropdown Section ========== --> */}
                          <div className="chat_option_right d-flex align-items-center gap-2">
                            <div className="msg_statusRight">
                              <span className="msg_time">
                                {chat.lastmessagetimestring}
                              </span>
                              {chat.lastmessagesender.toString() ==
                              userInfo._id.toString() ? (
                                <span className="msg_status">
                                  <i
                                    className={`material-icons-round ${
                                      chat.moduleslug === "MOD_CONTACT"
                                        ? chat.tracker === "2"
                                          ? "text-primary"
                                          : ""
                                        : ""
                                    } `}
                                  >
                                    done_all
                                  </i>
                                </span>
                              ) : chat.unreadmessagecount === 0 ? null : (
                                <p className="pending_msg bg-primary text-white">
                                  {chat.unreadmessagecount}
                                </p>
                              )}
                            </div>
                            <div className="option_more dropdown">
                              <Link
                                to="#"
                                className="p-0 more_dots"
                                data-bs-toggle="dropdown"
                              >
                                <i className="material-icons-outlined">
                                  more_vert
                                </i>
                              </Link>
                              <ul className="list_stye_none dropdown-menu">
                                {chat.moduleslug === "MOD_CONTACT" ? (
                                  <li>
                                    {selectedChatIndex === index &&
                                    isClosingResponse ? (
                                      <div
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      <Link
                                        to="#"
                                        className=""
                                        onClick={() => {
                                          setSelectedChatIndex(index);
                                          removeRequireResponseHandler(chat);
                                        }}
                                      >
                                        Close Response
                                      </Link>
                                    )}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                          {/* <!-- ========== End message status time and dropdown Section ========== --> */}
                        </div>
                      </div>
                    );
                  })}
            </div>
            {/* <!-- ========== End contact and group chat block Section ========== --> */}
          </div>

          <div
            className={
              showChatPopup ? "chat_cmntBx show_chatpopup" : "chat_cmntBx"
            }
          >
            <Link
              to="#"
              className="chat_btn"
              onClick={() => {
                setShowChatPopup(!showChatPopup);
              }}
            >
              <span className="material-icons-round">add_comment</span>
            </Link>

            <div className="lft_cht_popup">
              <ul>
                {/* <!-- ========== Start new chat Section ========== --> */}
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#start_newchat"
                    onClick={() => {
                      setShowChatPopup(!showChatPopup);
                    }}
                  >
                    <i className="material-icons-round">person</i>
                    <span>Add Contact</span>
                  </Link>
                </li>
                {/* <!-- ========== End new chat Section ========== --> */}

                {/* <!-- ========== Start new group chat Section ========== --> */}
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#group_modal"
                    onClick={() => {
                      setShowChatPopup(!showChatPopup);
                    }}
                  >
                    <i className="material-icons-round">people</i>
                    <span>Add Group</span>
                  </Link>
                </li>
                {/* <!-- ========== End new group chat Section ========== --> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ContactModal
        myContacts={allContacts}
        chatSelectionHandler={chatSelectionHandler}
      />

      <GroupModal
        myGroups={allGroups}
        chatSelectionHandler={chatSelectionHandler}
      />

      <CreateNewGroupModal
        myContacts={allContacts}
        afterModalClose={afterGroupModalClose}
      />
    </div>
  );
};

export default ConversationLeftPanel;
