/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

const ProfessionalBackgroundModal = () => {
  // select data --------------------------
  const role = [
    { label: 'Manager', value: 'Manager' },
    { label: 'Team Lead', value: 'Team Lead' },
  ];

  // useState to select data ------------------
  const [selectPosition, setSelectPosition] = useState(null);
  const [selectCompany, setSelectCompany] = useState(null);
  return (
    <div className="process_modal esg_modal">
      <div className="modal fade show" id="esg_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="process_hdng w-100">
                <h3>Professional Background</h3>
                <p>Please add your details to check report.</p>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Position</label>
                    <Select
                      placeholder="Select Role"
                      options={role}
                      value={selectPosition}
                      onChange={val => {
                        setSelectPosition(val);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Company </label>
                    <Select
                      placeholder="Select Role"
                      options={role}
                      value={selectCompany}
                      onChange={val => {
                        setSelectCompany(val);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label>Date </label>
                      <label className="radio-check">
                        <p>Currently working</p>
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Start date"
                      />
                      <input
                        type="date"
                        className="form-control"
                        placeholder="End date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Location </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company address"
                    />
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <Link to="#" data-bs-dismiss="modal" className="btn gray">
                    Cancel
                  </Link>
                  <Link to="#" className="btn">
                    Save
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalBackgroundModal;
