/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";

//import glowplaceholder component from glowplaceholder folder
import BlogListByCategoryPlaceholder from "../BlogPlaceHolder/BlogListByCategoryPlaceholder";
import BlogCard from "../common/BlogCard";

const BlogListByCategory = () => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);

  //function for get all blogs
  const getallBlogs = async () => {
    window.scroll(0, 0);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_BLOG_BY_CATEGORY +
        `?slug=${params.slug}&ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setIsLoading(false);
        setBlogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getallBlogs();
  }, [params.slug]);

  return (
    <section
      className={`blog_landing_total ${isLoading ? "placeholder-glow" : ""}`}
    >
      <div className="container">
        {isLoading ? (
          <BlogListByCategoryPlaceholder />
        ) : (
          <div className="landing_row">
            <div className="landing_hdng_row">
              <div className="page_hdng">
                <div className="details_arw green_back">
                  <Link to="/blog">
                    <span className="material-icons-outlined">arrow_back</span>
                  </Link>
                </div>
                <h2>{params.slug.toUpperCase()}</h2>
              </div>
            </div>
            <div className="blog_listing">
              <div className="row">
                {/* ---- list start---- */}
                {blogList.map((blog, index) => {
                  return (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <BlogCard blog={blog} />
                    </div>
                  );
                })}
                {/* ---- list end---- */}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogListByCategory;
