import AuthUserBox from 'components/common/AuthUserBox';
import ProfileMenuPanel from 'components/common/ProfileMenuPanel';
import React from 'react';

const PrivacyLeftPanel = ({ moduleName }) => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <ProfileMenuPanel moduleName={moduleName} />
    </div>
  );
};

export default PrivacyLeftPanel;
