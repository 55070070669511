/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

import { assetImages } from "constants";

const ActivityDetailsMiddlePanel = ({
  title,
  description,
  tags,
  uploadedFiles,
  moderatorId,
  moderatorName,
  moderatorImage,
  forumTimeAgo,
  likeCount,
  commentCount,
  commentList,
  toggleLikeForum,
  onEditComment,
  onDeleteComment,
  winnerHandler,
  topCommentId,
  winnerId,
  toggleLikeComment,
  isPrivate,
}) => {
  const params = useParams();

  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_FORUM")
  ) {
    return (
      <div className="pnl_mdlpnl">
        <div className="post_item_outer">
          <div className="post_item">
            <div className="postuser_detailsbx white_shadow_bx">
              {/* user,date time and more vert section */}
              <div className="user_post_top">
                <div className="post_left">
                  <div className="pofile_pic">
                    <img
                      src={
                        moderatorImage == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + moderatorImage
                      }
                      alt=""
                    />
                  </div>
                  <div className="pofile_info">
                    <h3>{moderatorName}</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons">
                          {isPrivate ? "settings" : "public"}
                        </i>
                        <span className="ms-1">
                          {isPrivate ? "Private" : "Public"}
                        </span>
                      </li>
                      <li>
                        <span>{forumTimeAgo}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user_right">
                  <ul className="list_stye_none">
                    <li className="dropdown">
                      <Link to="#" data-bs-toggle="dropdown">
                        <i className="material-icons-outlined">more_vert</i>
                      </Link>
                      <ul className="list_stye_none dropdown-menu">
                        <li>
                          <Link
                            to="#"
                            data-bs-target="#tags_modal"
                            data-bs-toggle="modal"
                          >
                            <i className="material-icons-outlined">more</i>
                            <span>{t("Add Tag")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined">share</i>
                            <span>{t("Share")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined">report</i>
                            <span>{t("Report")}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              {/* post details */}
              <div className="user_post_paragraph">
                <p>{title}</p>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
              </div>

              <div className="postpic_outer">
                <div className="post_leftpnl">
                  {uploadedFiles.map((file, index) => {
                    return (
                      <div className="post_pic" key={index}>
                        <img src={url.SERVER_URL + file.path} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="tags_list  mt-4">
                <div className="option_btns">
                  <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                    {tags.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            className="tagblock d-flex align-items-center justify-content-center"
                          >
                            <span>{tag.title}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/* like comment winner section */}
              <div className="user_post_icons">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#" onClick={toggleLikeForum}>
                      <i className="material-icons ornge_icon">favorite</i>
                      <span>{likeCount}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons">chat</i>
                      <span>{commentCount}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons">stars</i>
                      <span>{t("Winner")}</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="post_comment_area">
                {/* toogle comment modal */}
                <div className="top_usr_srch">
                  <div className="search_profile">
                    <img
                      src={url.SERVER_URL + userInfo.photoimage.path}
                      alt=""
                    />
                  </div>
                  <div
                    className="search_fill comment_search"
                    data-bs-toggle="modal"
                    data-bs-target="#add_comment_modal"
                  >
                    <Link to="#" className="form-control">
                      {t("Share your comment")}
                    </Link>
                    {/* <input
                    type="text"
                    className="form-control"
                    placeholder={t("Share your comment")}
                  /> */}
                    <Link to="#" className="emoji_btn">
                      <i className="material-icons-outlined">emoji_emotions</i>
                    </Link>
                  </div>
                </div>

                {/* comment list */}
                <div className="recent_comments_area">
                  <div className="commnt_hdng">
                    <h3>
                      {t("Most Recent")}
                      <span className="material-icons-outlined">
                        expand_more
                      </span>
                    </h3>
                  </div>

                  {/* comment list */}
                  <div className="comments_outer">
                    {/* winner  */}
                    {/* <div className="comments_row selected">
                    <div className="comnts_user">
                      <div className="search_profile">
                        <img src="/images/people2.png" alt="" />
                      </div>
                    </div>
                    <div className="comnts_right">
                      <div className="comment_bx">
                        <div className="user_post_top">
                          <div className="post_left">
                            <div className="pofile_info">
                              <h3>Don Bradman</h3>
                              <ul className="list_stye_none">
                                <li>
                                  <span>5min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="user_right">
                            <ul className="list_stye_none">
                              <li className="dropdown">
                                <Link to="#" data-bs-toggle="dropdown">
                                  <i className="material-icons-outlined">
                                    more_vert
                                  </i>
                                </Link>
                                <ul className="list_stye_none dropdown-menu">
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        share
                                      </i>
                                      <span>{t("Share")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        visibility_off
                                      </i>
                                      <span>{t("Hide Post")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        report
                                      </i>
                                      <span>{t("Report")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        person_remove
                                      </i>
                                      <span>{t("Unfollow")}</span>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="user_post_paragraph">
                          <p>
                            when holding the device with one hand. Since many
                            users interact with
                          </p>
                        </div>
                      </div>
                      <div className="user_post_icons">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">
                              <i className="material-icons ornge_icon">
                                favorite
                              </i>
                              <span>250</span>
                            </Link>
                          </li>

                          <li className="active">
                            <Link to="#">
                              <i className="material-icons">stars</i>
                              <span>{t("Winner")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

                    {commentList.map((commentData, index) => {
                      return (
                        <div
                          className={`comments_row ${
                            topCommentId &&
                            topCommentId.toString() ===
                              commentData._id.toString()
                              ? "selected"
                              : ""
                          }`}
                          key={index}
                        >
                          <div className="comnts_user">
                            <div className="search_profile">
                              <img
                                src={
                                  commentData.commentownerimage == ""
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL +
                                      commentData.commentownerimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="comnts_right">
                            <div className="comment_bx">
                              <div className="user_post_top">
                                <div className="post_left">
                                  <div className="pofile_info">
                                    <h3>{commentData.commentownername}</h3>
                                    <ul className="list_stye_none">
                                      <li>
                                        <span>
                                          {commentData.commenttimestring}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="user_right">
                                  <ul className="list_stye_none">
                                    <li className="dropdown">
                                      {userInfo.role.name == "Admin" ||
                                      userInfo._id.toString() ===
                                        moderatorId.toString() ||
                                      userInfo._id.toString() ===
                                        commentData.userid._id.toString() ? (
                                        <Link to="#" data-bs-toggle="dropdown">
                                          <i className="material-icons-outlined">
                                            more_vert
                                          </i>
                                        </Link>
                                      ) : null}

                                      {/* desktop menu */}

                                      <ul className="list_stye_none dropdown-menu">
                                        <li>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#add_comment_modal"
                                            onClick={() => {
                                              onEditComment(commentData._id);
                                            }}
                                          >
                                            Edit Comment
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              onDeleteComment(commentData._id);
                                            }}
                                          >
                                            Delete
                                          </Link>
                                        </li>
                                        {userInfo.role.name == "Admin" ||
                                        moderatorId.toString() ===
                                          userInfo._id.toString() ? (
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                winnerHandler(
                                                  commentData._id,
                                                  commentData.userid._id
                                                );
                                              }}
                                            >
                                              Winner
                                            </Link>
                                          </li>
                                        ) : null}
                                      </ul>

                                      {/* dont know what is for */}
                                      {/* <ul className="list_stye_none dropdown-menu">
                                      <li>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            share
                                          </i>
                                          <span>{t("Share")}</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            visibility_off
                                          </i>
                                          <span>{t("Hide Post")}</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            report
                                          </i>
                                          <span>{t("Report")}</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            person_remove
                                          </i>
                                          <span>{t("Unfollow")}</span>
                                        </Link>
                                      </li>
                                    </ul> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="user_post_paragraph">
                                <p>{commentData.description}</p>
                              </div>
                            </div>
                            <div className="user_post_icons">
                              <ul className="list_stye_none">
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleLikeComment(commentData._id);
                                    }}
                                  >
                                    <i className="material-icons ornge_icon">
                                      favorite
                                    </i>
                                    <span>{commentData.likeids.length}</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    topCommentId &&
                                    topCommentId.toString() ===
                                      commentData._id.toString()
                                      ? `active`
                                      : ""
                                  }
                                >
                                  <Link to="#">
                                    <i className="material-icons">stars</i>
                                    <span>{t("Winner")}</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="comments_row">
                    <div className="comnts_user">
                      <div className="search_profile">
                        <img src="/images/people2.png" alt="" />
                      </div>
                    </div>
                    <div className="comnts_right">
                      <div className="comment_bx">
                        <div className="user_post_top">
                          <div className="post_left">
                            <div className="pofile_info">
                              <h3>Don Bradman</h3>
                              <ul className="list_stye_none">
                                <li>
                                  <span>5min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="user_right">
                            <ul className="list_stye_none">
                              <li className="dropdown">
                                <Link to="#" data-bs-toggle="dropdown">
                                  <i className="material-icons-outlined">
                                    more_vert
                                  </i>
                                </Link>
                                <ul className="list_stye_none dropdown-menu">
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        share
                                      </i>
                                      <span>{t("Share")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        visibility_off
                                      </i>
                                      <span>{t("Hide Post")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        report
                                      </i>
                                      <span>{t("Report")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        person_remove
                                      </i>
                                      <span>{t("Unfollow")}</span>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="user_post_paragraph">
                          <p>
                            when holding the device with one hand. Since many
                            users interact with
                          </p>
                        </div>
                      </div>
                      <div className="user_post_icons">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">
                              <i className="material-icons ornge_icon">
                                favorite
                              </i>
                              <span>250</span>
                            </Link>
                          </li>

                          <li>
                            <Link to="#">
                              <i className="material-icons">stars</i>
                              <span>{t("Winner")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="comments_row">
                    <div className="comnts_user">
                      <div className="search_profile">
                        <img src="/images/people2.png" alt="" />
                      </div>
                    </div>
                    <div className="comnts_right">
                      <div className="comment_bx">
                        <div className="user_post_top">
                          <div className="post_left">
                            <div className="pofile_info">
                              <h3>Don Bradman</h3>
                              <ul className="list_stye_none">
                                <li>
                                  <span>5min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="user_right">
                            <ul className="list_stye_none">
                              <li className="dropdown">
                                <Link to="#" data-bs-toggle="dropdown">
                                  <i className="material-icons-outlined">
                                    more_vert
                                  </i>
                                </Link>
                                <ul className="list_stye_none dropdown-menu">
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        share
                                      </i>
                                      <span>{t("Share")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        visibility_off
                                      </i>
                                      <span>{t("Hide Post")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        report
                                      </i>
                                      <span>{t("Report")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        person_remove
                                      </i>
                                      <span>{t("Unfollow")}</span>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="user_post_paragraph">
                          <p>
                            when holding the device with one hand. Since many
                            users interact with
                          </p>
                        </div>
                      </div>
                      <div className="user_post_icons">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">
                              <i className="material-icons ornge_icon">
                                favorite
                              </i>
                              <span>250</span>
                            </Link>
                          </li>

                          <li>
                            <Link to="#">
                              <i className="material-icons">stars</i>
                              <span>{t("Winner")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="comments_row">
                    <div className="comnts_user">
                      <div className="search_profile">
                        <img src="/images/people2.png" alt="" />
                      </div>
                    </div>
                    <div className="comnts_right">
                      <div className="comment_bx">
                        <div className="user_post_top">
                          <div className="post_left">
                            <div className="pofile_info">
                              <h3>Don Bradman</h3>
                              <ul className="list_stye_none">
                                <li>
                                  <span>5min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="user_right">
                            <ul className="list_stye_none">
                              <li className="dropdown">
                                <Link to="#" data-bs-toggle="dropdown">
                                  <i className="material-icons-outlined">
                                    more_vert
                                  </i>
                                </Link>
                                <ul className="list_stye_none dropdown-menu">
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        share
                                      </i>
                                      <span>{t("Share")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        visibility_off
                                      </i>
                                      <span>{t("Hide Post")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        report
                                      </i>
                                      <span>{t("Report")}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        person_remove
                                      </i>
                                      <span>{t("Unfollow")}</span>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="user_post_paragraph">
                          <p>
                            when holding the device with one hand. Since many
                            users interact with
                          </p>
                        </div>
                      </div>
                      <div className="user_post_icons">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">
                              <i className="material-icons ornge_icon">
                                favorite
                              </i>
                              <span>250</span>
                            </Link>
                          </li>

                          <li>
                            <Link to="#">
                              <i className="material-icons">stars</i>
                              <span>{t("Winner")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ============= add details modal =========== */}
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default ActivityDetailsMiddlePanel;
