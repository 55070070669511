/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import /images
import { assetImages } from "constants";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import languages ------------------------------------------------
import languageOptions from "../../Data/Language.json";

const GuestHeader = ({ moduleName }) => {
    
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation(); // translation marker

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="header_inner">
          <div className="left_serch_logo">
            <div className="hdr_hamberger">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.add("sidebar_open");
                }}
              >
                <img src="/images/menu.svg" alt="" />
              </Link>
            </div>
            <Link to="/feed'" className="small_logo">
              <img src="/images/small-logo.svg" alt="" />
            </Link>
            <div className="header_serach">
              <input
                type="text"
                // value=""
                className="form-control"
                placeholder="Search "
              />
              <button type="button" className="srch_btn">
                <span className="material-icons-outlined">search</span>
              </button>
            </div>
          </div>
          <div className="right_user_icon">
          
          </div>
        </div>
      </div>

      <div className="hdr_lftmenu">
        <span
          className="cross_btn material-icons-outlined"
          onClick={() => document.body.classList.remove("sidebar_open")}
        >
          close
        </span>
        <div className="left_menulist">
         
        </div>
      </div>
    </>
  );
};

export default GuestHeader;
