import React from 'react';

const ForgetPasswordModal = () => {
  return (
    <div className="post_modal forgot_mdl">
      <div className="modal fade" id="forgot_mdl">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Forgot Password{' '}
                <span>Please enter email id we will send you a OTP</span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_description">
                <label>Email</label>
                <input
                  type="email"
                  value=""
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
              <div className="post_btn">
                <button
                  className="btn"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#verify_mdl"
                >
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordModal;
