import React, { useState } from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';
import kitDigitalServiceData from 'Data/KitDigitalServiceData.json';
import ServiceCard from './ServiceCard/ServiceCard';
import KitDigitalRequestModal from 'components/common/Modals/KitDigitalRequestModal';
import SuccessModal from 'components/common/Modals/SuccessModal';

const KitDigitalServices = () => {
  const { t } = useTranslation(); // translation marker
  const [kitName, setKitName] = useState('');
  const [serviceRate, setServiceRate] = useState('');
  const afterModalClose = () => {};
  return (
    <section className="digital_service_area pd_130">
      <div className="container">
        <div className="page_hdng">
          <h5>{t('Our services')}</h5>
          <h2>
            {t('What we can')} <br /> {t('Help you with')}
          </h2>
        </div>

        {/* ---- cards section starts ---- */}
        <div className="digital_srvc_innr">
          {kitDigitalServiceData.map((service, index) => {
            return (
              <ServiceCard
                key={index}
                service={service}
                setKitName={setKitName}
                setServiceRate={setServiceRate}
              />
            );
          })}
        </div>
        {/* ---- cards section end ---- */}
      </div>
      <KitDigitalRequestModal
        kitName={kitName}
        setKitName={setKitName}
        serviceRate={serviceRate}
        setServiceRate={setServiceRate}
      />
      <SuccessModal afterModalClose={afterModalClose} />
    </section>
  );
};

export default KitDigitalServices;
