/* eslint-disable */
import ActivitiesLandingBody from "components/ActivitiesComponents/ActivitiesLandingComponents/ActivitiesLandingBody/ActivitiesLandingBody";
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

const ActivitiesLanding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AuthHeader moduleName="activity" />
      {/* ----- body section ---- */}
      <ActivitiesLandingBody />
    </>
  );
};

export default ActivitiesLanding;
