/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const TechnicalCertificateModal = ({
  technicalExpIndex,
  settechnicalExpIndex,
  technicalExperienceData,
  settechnicalExperienceData,
  technicalExpBlock,
  settechnicalExpBlock,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_BY_SEARCH +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      settechnicalExperienceData((prevData) => ({
        ...prevData,
        companyvalue: val,
        companyname: val.label,
      }));
    } else {
      settechnicalExperienceData((prevData) => ({
        ...prevData,
        companyvalue: null,
        companyname: "",
      }));
    }
  };

  //function for add edcuation block
  const addTechExpHandler = () => {
    settechnicalExpBlock([...technicalExpBlock, technicalExperienceData]);
    closeModalHandler();
  };

  //function for edit education block
  const editTechExpBlockHandler = () => {
    const updatedTechExpList = [...technicalExpBlock];
    updatedTechExpList[technicalExpIndex] = technicalExperienceData;
    settechnicalExpBlock(updatedTechExpList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    settechnicalExperienceData({
      name: "",
      companyvalue: null,
      companyname: "",
      start: "",
      end: "",
      description: "",
      grade: "",
    });
    settechnicalExpIndex(null);
  };

  const saveInfoHandler = () => {
    if (technicalExpIndex != null) {
      editTechExpBlockHandler();
    } else {
      addTechExpHandler();
    }

    let loginModal = document.querySelector("#technical_certificate");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="technical_certificate">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Technical Certificates</h3>
                <h5>Completed Course</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Name of Course</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Course Name"
                      value={technicalExperienceData.name}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Company </label>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      value={technicalExperienceData.companyname}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          companyname: e.target.value,
                        }));
                      }}
                    /> */}
                    <CreatableSelect
                      placeholder="Type Company name"
                      isClearable
                      options={companyOptionsToShow}
                      value={technicalExperienceData.companyvalue}
                      onChange={(val) => {
                        companySelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        setcompanyInput(val);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label>Date </label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Start date"
                        value={technicalExperienceData.start}
                        onChange={(e) => {
                          settechnicalExperienceData((prevData) => ({
                            ...prevData,
                            start: e.target.value,
                          }));
                        }}
                      />
                      <input
                        type="date"
                        className="form-control"
                        placeholder="End date"
                        value={technicalExperienceData.end}
                        onChange={(e) => {
                          settechnicalExperienceData((prevData) => ({
                            ...prevData,
                            end: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Details </label>
                    <textarea
                      className="form-control"
                      placeholder="Enter here"
                      value={technicalExperienceData.description}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          description: e.target.value,
                        }));
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>Grade </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Grade"
                      value={technicalExperienceData.grade}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          grade: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={closeModalHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalCertificateModal;
