/* eslint-disable */
import EventDetailsBody from "components/EventComponents/EventDetailsComponents/EventDetailsBody/EventDetailsBody";
import AuthHeader from "components/common/AuthHeader";
//import context
import { GlobalProvider } from "context/GlobalContext";
import React, { useEffect, useContext } from "react";

const EventDetails = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ----------body section -------- */}
      <EventDetailsBody />
    </>
  );
};

export default EventDetails;
