/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import CompanyLandingBody from "components/CompanyComponents/CompanyLandingComponents/CompanyLandingBody/CompanyLandingBody";

const CompanyLanding = () => {
  const { t, i18n } = useTranslation(); // translation marker

  const [params, setParams] = useState(null);

  const parmsChange = (dataItem) => {
    setParams({
      sortby: dataItem,
    });
  };
  return (
    <>
      <AuthHeader moduleName={"company"} />

      {/* --------- body section ------------ */}
      <CompanyLandingBody />
    </>
  );
};

export default CompanyLanding;
