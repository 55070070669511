import React from "react";
import AuthContactPanel from "components/common/AuthContactPanel";

const AppUserRightPanel = () => {
  return (
    <>
      <AuthContactPanel />
    </>
  );
};

export default AppUserRightPanel;
