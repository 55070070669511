import { assetImages } from "constants";
import React from "react";

const ChatRightPanelPlaceholder = () => {
  return (
    <div className="placeholder_row placeholder-glow">
      <div className="user_placeHoledr">
        <div className="default_usr">
          <img src={assetImages.defaultUser} alt="" />
        </div>
        <div className="default_usr_name">
          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
          <span className="placeholder placeholder-sm col-2 rounded-3"></span>
        </div>
      </div>
      <p className="card-text placeholder-glow">
        <span className="placeholder placeholder-sm col-12 rounded-3"></span>
        <span className="placeholder placeholder-sm col-10 rounded-3"></span>
        <span className="placeholder placeholder-sm col-9 rounded-3"></span>
        <span className="placeholder placeholder-sm col-8 rounded-3"></span>
        <span className="placeholder placeholder-sm col-7 rounded-3"></span>
        <span className="placeholder placeholder-sm col-6 rounded-3"></span>
      </p>
    </div>
  );
};

export default ChatRightPanelPlaceholder;
