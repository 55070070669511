import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { assetImages } from "constants";

const SuggestGroup = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic pd_10">
                <img src="images/company_logo2.svg" alt="" />
            </div> 
            <div className="ai_procudt_text">
                <h3>Marketing Group</h3>
                <p className="d-flex align-items-center gap-1">  <span className="material-icons-outlined">place </span>Madrid, Spain</p>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Backend</li>
                        <li>Data analyst</li>
                        <li>Data</li>
                    </ul>
                </div>
            </div>
        </Link>

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic pd_10">
                <img src="images/company_logo2.svg" alt="" />
            </div> 
            <div className="ai_procudt_text">
                <h3>Startup Groups</h3>
                <p className="d-flex align-items-center gap-1">  <span className="material-icons-outlined">place </span>Madrid, Spain</p>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Backend</li>
                        <li>Data analyst</li>
                        <li>Data</li>
                    </ul>
                </div>
            </div>
        </Link>

    
        <div><Link to="#" className="btn outline" >View more groups?</Link></div>

    </div>

    
  );
};

export default SuggestGroup;
