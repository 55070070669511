/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

//import images
import { assetImages } from "constants";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";

const EventCard = ({ eventData }) => {
  const { t } = useTranslation(); // translation marker

  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="event_slideItem w-auto">
      <div className="event_slideInnr">
        <div className="event_hdng">
          <h3>{eventData.title?.slice(0, 40)}</h3>
          <h5>
            <i className="material-icons-outlined"> event_note </i>
            <span>{eventData.eventstartdate}</span>
          </h5>
        </div>
        <div className="event_tags">
          <ul className="list_stye_none">
            <li>
              <i className="material-icons-outlined ornge_icon">star</i>
              <span className="rating d-flex gap-1">
                {eventData.ratingtotal} <em>({eventData.reviewtotal})</em>
              </span>
            </li>
            <li className="event_loc">
              <i className="material-icons-outlined">place</i>
              <span>{eventData.eventlocation}</span>
            </li>
          </ul>
        </div>
        <div className="event_para">
          <p>{eventData.summary.slice(0, 60)}</p>
        </div>
        <div className="event_user grps_user">
          <ul className="list_stye_none">
            {eventData.eventmemberlist
              .slice(0, memberLimit)
              .map((member, index) => {
                return (
                  <li key={index}>
                    <Link to="#">
                      <img
                        height={40}
                        width={40}
                        className="rounded-circle"
                        src={
                          member.eventmemberimage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + member.eventmemberimage
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                );
              })}
            {eventData.eventmembercount ? (
              <li>
                {eventData.eventmembercount > memberLimit ? (
                  <Link to="#">
                    +{eventData.eventmembercount - memberLimit} more
                  </Link>
                ) : null}
              </li>
            ) : null}
            {/* <li>
              <Link to="#">
                <img src="images/expert1.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="images/expert2.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="images/expert3.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="images/expert4.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="images/expert5.png" alt="" />
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
