/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import * as url from "helper/UrlHelper";

const MembersTab = ({ memberList }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="post_item_outer">
      <div className="post_item">
        {memberList.map((memberData, index) => {
          return (
            <div className="white_shadow_bx people_bx" key={index}>
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img
                      src={
                        memberData.image == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + memberData.image
                      }
                      alt=""
                    />
                  </div>
                  <div className="people_profile_info">
                    <h3>{memberData.name}</h3>
                    <h5>{memberData.role}</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            {memberData.totalratings}{" "}
                            <em>({memberData.totalreviews})</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link
                    to={`/peoples/details/${memberData.userid}`}
                    className="btn outline"
                  >
                    {t("View Details")}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people2.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Clare MacLearn</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="people_details.html" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people3.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Nasino Yangchang</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Pierre Gagnaire</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people2.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Clare MacLearn</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="/images/people3.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Nasino Yangchang</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn outline">
                {t('View Details')}
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MembersTab;
