import { useState } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

import { fileDownloadHandler } from "utils/DownloadFile";

const ContentTab = ({
  lessonDetails = {},
  nextLessonHandler,
  userCourseRole = "",
}) => {
  const token = localStorage.getItem("token");

  const [lessonCompleted, setlessonCompleted] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);

  const changeLessonHandler = async () => {
    if (lessonCompleted) {
      try {
        setisUpdating(true);

        let requestURL =
          url.API_BASE_URL +
          url.API_COMPLETE_COURSE_LESSON +
          `/${lessonDetails?._id}?token=${token}`;

        const response = await putData(requestURL);

        console.log(response);

        setisUpdating(false);
        if (response.status) {
          setlessonCompleted(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    nextLessonHandler();
  };

  return (
    <div className="content_pnl lesson_pnl">
      {/* <!-- ========== Start title Section ========== --> */}
      <div className="lesson_detailsHdng">
        <h2>{lessonDetails.title}</h2>
      </div>
      {/* <!-- ========== End title Section ========== --> */}

      {/* <!-- ========== Start details, duration Section ========== --> */}
      <div className="jb_rating mb-3">
        <i className="material-icons-outlined">schedule</i>
        <span>{lessonDetails.duration} min</span>
      </div>
      {/* <!-- ========== End details, duration Section ========== --> */}

      {/* <!-- ========== Start lesson details Section ========== --> */}
      <div className="course_contentOuter">
        <div className="course_contentBx">
          <div className="contnt_hdng">
            <h4>About</h4>
          </div>
          <div className="results_para">
            <p
              dangerouslySetInnerHTML={{
                __html: lessonDetails?.description,
              }}
            ></p>
          </div>
        </div>
        {/* <div className="course_contentBx">
          <div className="contnt_hdng">
            <h4>Heading Number 02</h4>
          </div>
          <div className="results_para">
            <p>
              I love coding and problem solving. I have 3+ years of experience
              in the web development sector. Love working on both server and
              client side code. I like to get to know my clients closely to
              facilitate better communication.
            </p>
            <p>
              Superb developer, he goes over and above in most cases to make
              sure the job is done and done well. He has the right skill set for
              a developer and more importantly he aligns himself with the vision
              of the product and provides great insights on how to build a
              product the right way. Will look forward to work with him on more
              projects.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </p>
          </div>
        </div> */}
      </div>
      {/* <!-- ========== End lesson details Section ========== --> */}

      {/* <!-- ========== Start lesson uploads/videos Section ========== --> */}
      {lessonDetails?.uploads
        ? lessonDetails?.uploads
            ?.sort((a, b) => a.rank - b.rank)
            .map((upload, index) => {
              if (
                upload.uploadid &&
                (upload.uploadid?.name?.split(".").pop() === "mp4" ||
                  upload.uploadid?.name?.split(".").pop() === "mov")
              ) {
                return (
                  <div className="course_vdopnl" key={index}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={url.SERVER_URL + upload?.uploadid?.path}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              } else {
                return upload.uploadid ? (
                  <div
                    className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3 mb-3"
                    key={index}
                  >
                    <p className="fw-medium">{upload?.uploadid?.name}</p>
                    <Link
                      to="#"
                      onClick={() => fileDownloadHandler(upload?.uploadid)}
                    >
                      <span className="d-block material-icons-outlined">
                        download
                      </span>
                    </Link>
                  </div>
                ) : null;
              }
            })
        : null}
      {/* <!-- ========== End lesson uploads/videos Section ========== --> */}

      {/* <!-- ========== Start checkbox complete Section ========== --> */}
      {userCourseRole !== "" ? null : (
        <div className="trms_check lessons_check">
          <label className="radio-check">
            <input
              type="checkbox"
              checked={lessonCompleted}
              onChange={(e) => {
                setlessonCompleted(e.target.checked);
              }}
            />
            <span></span>
            <p>I have completed this leasson</p>
          </label>
        </div>
      )}
      {/* <!-- ========== End checkbox complete Section ========== --> */}

      {/* <!-- ========== Start next lesson button Section ========== --> */}
      <div className="lesson_btn">
        <button
          className="btn"
          onClick={changeLessonHandler}
          disabled={isUpdating ? true : false}
          style={{
            cursor: isUpdating ? "not-allowed" : "pointer",
          }}
        >
          Go to Next Lesson
          {isUpdating && (
            <div
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </button>
      </div>
      {/* <!-- ========== End next lesson button Section ========== --> */}
    </div>
  );
};

export default ContentTab;
