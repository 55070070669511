import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const HomeWhyLobees = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="why_lobess_sec pd_130">
      <div className="container">
        <div className="lobees_outer">
          <div className="row">
            <div className="col-lg-6">
              <div className="why_leftpnl">
                <div className="page_hdng white_hdng">
                  <h5>{t("Are you asking yourself")}</h5>
                  <h2>
                    {t("Why")} <br /> {t("Lobees")}?
                  </h2>
                </div>
                <div className="lobees_para">
                  <p>
                    {t(
                      "Lobees is not just an employment platform. If you like to collaborate, Lobees is the ideal ecosystem to do so. But what is an Ecosystem? It is a space where the members that integrate it can compete or collaborate with each other, but coexisting and progressing together. In an Ecosystem, the key and success is collaboration and joint innovation, as well as an agent that leads it. The magic of ecosystems makes 'the whole' greater than the sum of 'the parts'"
                    )}
                  </p>
                </div>
                <div className="user_comments">
                  <h5>
                    “
                    {t(
                      "Lobees is an ecosystem to grow by collaborating. Together we look for the best projects to work on them"
                    )}
                    ”
                  </h5>
                  <h6>-Victor Herrero</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why_rightpnl">
                <figure>
                  <img src={assetImages.whyLobees} alt="" />
                </figure>
                <div className="play_btn">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video_modal"
                  >
                    <span className="material-icons">play_arrow</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWhyLobees;
