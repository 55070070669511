import React from 'react';

const AwardsTab = () => {
  return (
    <div className="rewards_outer">
      <div className="row">
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>DNA Paris Design Winner</h3>
              <h6>DNA PARIS DESIGN AWARD, 2021</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>Top B2B Companies</h3>
              <h6>CLUTCH GLOBAL AWARDS, 2022</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>DNA Paris Design Winner</h3>
              <h6>DNA PARIS DESIGN AWARD, 2020</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>Interface Design Award</h3>
              <h6>RED DOT AWARD, EUROPE - 2019</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>UX UI Design Leaders</h3>
              <h6>CLUTCH, 2019</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="rewards_item white_shadow_bx">
            <div className="rewards_pic">
              <img src="/images/rewards.svg" alt="" />
            </div>
            <div className="rewards_text">
              <h3>Interface Design Award</h3>
              <h6>RED DOT AWARD, EUROPE - 2019</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardsTab;
