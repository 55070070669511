import { useEffect } from "react";
import GuestHeader from "components/common/GuestHeader";

import CustomerFeedbackMain from "components/CvBuilder/CustomerFeedback/CustomerFeedbackMain";

const CustomerCvFeedbackBuilder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <header className="aftre_login_header">
        <GuestHeader />
      </header>

      <CustomerFeedbackMain />
    </>
  );
};

export default CustomerCvFeedbackBuilder;
