/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import { assetImages } from "constants";

const ScoreTab = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const [courseName, setCourseName] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [totalScore, settotalScore] = useState("");
  const [obtainedScore, setobtainedScore] = useState("");
  const [certificateFeedback, setCertificateFeedback] = useState("-");
  const [completeDate, setCompleteDate] = useState("");
  const [isCertified, setIsCertified] = useState(false);

  //function for get details of event member
  const getSubscriberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CERTIFICATE_INFORMATION +
        `?token=${token}&course=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setCourseName(response.data.coursename);
        setSubscriberName(response.data.name);
        settotalScore(response.data.subscribertotalscore);
        setobtainedScore(response.data.subscriberobtainedscore);
        setCertificateFeedback(response.data.certificationcomment || "-");
        setCompleteDate(response.data.certificationdate || "");
        setIsCertified(response.data.iscertified || false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getSubscriberDetails();
    }
  }, [params.id]);

  if (isCertified) {
    return (
      <div className="scores_pnl lesson_pnl">
        <div className="scorebx_outer">
          <div className="scorebx_item">
            <div className="scorebx_innr">
              <h5>Date of Completion</h5>
              <h4>{completeDate}</h4>
            </div>
          </div>
          <div className="scorebx_item">
            <div className="scorebx_innr">
              <h5>Score</h5>
              <h4>
                {obtainedScore} out of {totalScore}
              </h4>
            </div>
          </div>
          <div className="scorebx_item">
            <div className="scorebx_innr">
              <h5>Feedback</h5>
              <h4>{certificateFeedback}</h4>
            </div>
          </div>
        </div>

        <div className="certificate_bx">
          <div
            className="donate_certificate"
            style={{
              backgroundImage: "url(images/certificate.png)",
            }}
          >
            <div className="certificate_hdng">
              <h2>certificate of completion </h2>
              <span className="cerficate_line">
                <img src={assetImages.lineImage} alt="" />
              </span>
            </div>
            <div className="certificate_nameHdng">
              <p>We hereby express our sincere appreciation to</p>
              <h3>{subscriberName}</h3>
            </div>
            <div className="certificate_para">
              <p>
                This certifies that{" "}
                <span className="fw-semibold">{subscriberName}</span> has
                completed the course{" "}
                <span className="fw-semibold">{courseName}</span> successfully!
                and has obtained the certificate
              </p>
            </div>
            <div className="crtificate_signOuter">
              <div className="certificate_date">
                <h5>
                  Date <span>{completeDate}</span>
                </h5>
              </div>
              <div className="certificate_stamp">
                <img src={assetImages.certificateStamp} alt="" />
              </div>
              <div className="certificate_sign">
                <img src={assetImages.signCopy} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="download_btn">
          <Link to="#" className="btn">
            Download PDF
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="pnl_mdlpnl">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You have not obtained the certificate for this course
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ScoreTab;
