import PasswordUpdateBody from 'components/PasswordComponents/PasswordUpdateComponents/PasswordUpdateBody/PasswordUpdateBody';
import AuthHeader from 'components/common/AuthHeader';
import React from 'react';

const PasswordUpdate = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <PasswordUpdateBody />
    </>
  );
};

export default PasswordUpdate;
