import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const SustainabilityAchievements = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section
      className="blue_ecosystem_sec join_part_sec pd_130"
      style={{ backgroundImage: "url(/images/banner-dots.png)" }}
    >
      <div className="container">
        <div className="page_hdng text-center">
          <h2>
            {t("Join Now and Be")} <br />
            {t("Part of the Change")}
          </h2>
          <h5>
            {t(
              "We Publish Your Success Story: Highlight your ESG achievements and commitments"
            )}
          </h5>
        </div>
        <div className="system_btn text-center">
          <Link
            to="#"
            className="btn"
            data-bs-toggle="modal"
            data-bs-target="#register_mdl"
          >
            {t("Sign up here")}!
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityAchievements;
