/* eslint-disable */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
//import context
import { GlobalProvider } from "context/GlobalContext";
import { ecosystemSlug } from "Config/Config";

const RegisterModal = () => {
  const { createAccountMessage, setcreateAccountMessage, setuserEmailforOtp } =
    useContext(GlobalProvider);

  const { t } = useTranslation(); // translation marker
  /*----- UseState to send username and password for Login ----*/
  const [userName, setuserName] = useState("");
  const [userSurname, setuserSurname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAgreePolicy, setIsAgreePolicy] = useState(false);
  const [isSubscribeCommercialMail, setIsSubscribeCommercialMail] =
    useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    firstNameWarning: false,
    lastNameWarning: false,
    emailWarning: false,
    validEmailWarning: false,
    passwordWarning: false,
    agreeWarning: false,
  });
  /* Hook for validation end */

  // validation function
  const formValidation = () => {
    let isValid = true;

    if (userName === "") {
      setValidation((prevState) => ({ ...prevState, firstNameWarning: true }));
      isValid = false;
    }

    if (userSurname === "") {
      setValidation((prevState) => ({ ...prevState, lastNameWarning: true }));
      isValid = false;
    }

    if (userEmail === "") {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    } else {
      setValidation((prevState) => ({ ...prevState, emailWarning: false }));
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!mailformat.test(userEmail)) {
        setValidation((prevState) => ({
          ...prevState,
          validEmailWarning: true,
        }));
        isValid = false;
      } else {
        setValidation((prevState) => ({
          ...prevState,
          validEmailWarning: false,
        }));
      }
    }

    if (userPassword === "") {
      setValidation((prevState) => ({ ...prevState, passwordWarning: true }));
      isValid = false;
    }

    if (isAgreePolicy == false) {
      setValidation((prevState) => ({ ...prevState, agreeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  //function for sign up
  const signupHandler = async () => {
    if (formValidation()) {
      setIsLoading(true);
      try {
        let userData = {
          name: userName,
          surname: userSurname,
          email: userEmail,
          password: userPassword,
          subscribecommercialmail: isSubscribeCommercialMail ? "1" : "0",
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_REGISTRATION +
          `?ecosystemslug=${ecosystemSlug}`;

        console.log("request url -->", requestURL);

        const response = await postData(requestURL, userData);

        console.log("response -->", response);

        setIsLoading(false);
        if (response.status) {
          setuserEmailforOtp(userEmail);
          resetRegisterData();
          //hide register modal
          let registerModal = document.querySelector("#register_mdl");
          let modal = bootstrap.Modal.getInstance(registerModal);
          modal.hide();

          //show otpconfirm modal
          let myModal = new bootstrap.Modal(
            document.getElementById("otp_confirm_login_mdl")
          );
          myModal.show();
        } else {
          setResponseMessage(response.message);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetRegisterData = () => {
    setuserName("");
    setuserSurname("");
    setUserEmail("");
    setUserPassword("");
    setIsAgreePolicy(false);
    setIsSubscribeCommercialMail(false);
    setValidation({
      firstNameWarning: false,
      lastNameWarning: false,
      emailWarning: false,
      validEmailWarning: false,
      passwordWarning: false,
      agreeWarning: false,
    });
    setcreateAccountMessage("");
  };

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="register_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog medium_modal" role="document">
          <div className="modal-content">
            {/* ---- modal header section start ---- */}
            <div className="modal-header">
              {/* ---- modal title start ---- */}
              <div className="signin_hdng ">
                <h3>{t("Create Account")}</h3>
                <h5>
                  <span className="error">{createAccountMessage}</span>{" "}
                  {t("Please enter details to proceed")}
                </h5>
              </div>
              {/* ---- modal title end ---- */}

              {/* ---- modal close button start ---- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                // data-bs-toggle="modal"
                // data-bs-target="#login_modal"
                onClick={resetRegisterData}
              >
                <i className="material-icons-outlined">close</i>
              </button>
              {/* ---- modal close button end ---- */}
            </div>
            {/* ---- modal header section end ---- */}

            {/* ---- modal body section start ---- */}
            <div className="modal-body">
              <div className="login_body">
                {/* ----- register form section start ----- */}
                <form onSubmit={(e) => e.preventDefault()}>
                  {/* ----- first name,last name,email and password input section start ----- */}
                  <div className="landing_login_fillOuter">
                    <div className="row">
                      {/* ----- first name section start ----- */}
                      <div className="col-md-6">
                        <div className="input_fill_landing login_inputfill">
                          <label>{t("First Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Enter First Name")}
                            value={userName}
                            onChange={(e) => {
                              setValidation((prevState) => ({
                                ...prevState,
                                firstNameWarning: false,
                              }));
                              setuserName(e.target.value);
                              setResponseMessage("");
                            }}
                          />
                        </div>
                        {validation.firstNameWarning && (
                          <p className="error mb-2">
                            <span className="material-icons-outlined me-2">
                              info
                            </span>
                            {t("Please enter first name")}
                          </p>
                        )}
                      </div>
                      {/* ----- first name section end ----- */}

                      {/* ----- last name section start ----- */}
                      <div className="col-md-6">
                        <div className="input_fill_landing login_inputfill">
                          <label>{t("Last Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Enter Last Name")}
                            value={userSurname}
                            onChange={(e) => {
                              setValidation((prevState) => ({
                                ...prevState,
                                lastNameWarning: false,
                              }));
                              setuserSurname(e.target.value);
                              setResponseMessage("");
                            }}
                          />
                        </div>
                        {validation.lastNameWarning && (
                          <p className="error mb-2">
                            <span className="material-icons-outlined me-2">
                              info
                            </span>
                            {t("Please enter last name")}
                          </p>
                        )}
                      </div>
                      {/* ----- last name section end ----- */}

                      {/* ----- email section start ----- */}
                      <div className="col-md-12">
                        <div className="input_fill_landing login_inputfill">
                          <label>{t("Email")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("Enter Email")}
                            value={userEmail}
                            onChange={(e) => {
                              setValidation((prevState) => ({
                                ...prevState,
                                emailWarning: false,
                                validEmailWarning: false,
                              }));
                              setUserEmail(e.target.value);
                              setResponseMessage("");
                            }}
                          />
                        </div>

                        {/* ------ email warning start ------ */}
                        {validation.emailWarning && (
                          <p className="error mb-2">
                            <span className="material-icons-outlined me-2">
                              info
                            </span>
                            {t("Please enter email")}
                          </p>
                        )}
                        {/* ----- valid email warning ------ */}
                        {validation.validEmailWarning && (
                          <p className="error mb-2">
                            <span className="material-icons-outlined me-2">
                              info
                            </span>
                            {t("Please enter valid email")}
                          </p>
                        )}
                        {/* ------ email warning end ------ */}

                        {/* ----- create and signin section end ----- */}
                        {responseMessage === "" ? null : (
                          <p className="error mt-3">
                            <span className="material-icons-outlined me-2">
                              info
                            </span>
                            {responseMessage}
                          </p>
                        )}
                      </div>
                      {/* ----- email section end ----- */}

                      {/* ----- password section start ----- */}
                      <div className="col-md-12">
                        <div className="input_fill_landing login_inputfill">
                          <label>{t("Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder={t("Enter Password")}
                              value={userPassword}
                              onChange={(e) => {
                                setValidation((prevState) => ({
                                  ...prevState,
                                  passwordWarning: false,
                                }));
                                setUserPassword(e.target.value);
                                setResponseMessage("");
                              }}
                            />
                            <Link
                              to="#"
                              className="copy_link"
                              onClick={toggleText}
                            >
                              <span className="material-icons-outlined">
                                {" "}
                                visibility
                              </span>
                            </Link>
                          </div>
                          {validation.passwordWarning && (
                            <p className="error mb-2">
                              <span className="material-icons-outlined me-2">
                                info
                              </span>
                              {t("Please enter password")}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* ----- password section end ----- */}
                    </div>
                  </div>
                  {/* ----- first name,last name,email and password input section end ----- */}

                  {/* ----- agree checkbox section start ----- */}
                  <div className="create_chk_outer mt-3">
                    <div className="trms_check">
                      <label className="radio-check">
                        <input
                          type="checkbox"
                          checked={isAgreePolicy}
                          onChange={(e) => {
                            setValidation((prevState) => ({
                              ...prevState,
                              agreeWarning: false,
                            }));
                            setIsAgreePolicy(e.target.checked);
                            setResponseMessage("");
                          }}
                        />
                        <span></span>
                        <p>
                          {t("Agree with the")}{" "}
                          <Link to="#">{t("Legal Notice")}</Link> &amp;{" "}
                          <Link to="#">{t("Privacy Policy")}</Link>
                        </p>
                      </label>
                      {validation.agreeWarning && (
                        <p className="error mt-2">
                          <span className="material-icons-outlined me-2">
                            info
                          </span>
                          {t(
                            "Please Agree with the Legal Notice and Privacy Policy"
                          )}
                        </p>
                      )}
                    </div>
                    <div className="trms_check">
                      <label className="radio-check">
                        <input
                          type="checkbox"
                          checked={isSubscribeCommercialMail}
                          onChange={(e) => {
                            setIsSubscribeCommercialMail(e.target.checked);
                            setResponseMessage("");
                          }}
                        />
                        <span></span>
                        <p>
                          {t(
                            "I agree to receive commercial information that MPFORALL Business & Collaboration considers appropriate to send me by email"
                          )}
                        </p>
                      </label>
                    </div>
                  </div>
                  {/* ----- agree checkbox section end ----- */}

                  {/* ----- create and signin section start ----- */}
                  <div className="signup_btns_outer d-flex align-items-center justify-content-between">
                    {/* ----- login modal link start ----- */}
                    <Link
                      to="#"
                      className="btn outline"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      data-bs-target="#login_modal"
                    >
                      {t("Sign In")}
                    </Link>
                    {/* ----- login modal link end ----- */}

                    {/* ----- register button start ----- */}
                    <button
                      type="button"
                      className="btn"
                      onClick={signupHandler}
                      disabled={isLoading ? true : false}
                      style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    >
                      {t("Create Account")}
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                    {/* ----- register button end ----- */}
                  </div>
                  {/* ----- create and signin section end ----- */}

                  {/* <div className="or_text mb-4">{t("Or")}</div> */}

                  {/* ----- linkdin and google login section start ----- */}
                  {/* <div className="create_userOuter d-flex align-items-center gap-2">
                    <div className="linkedin_btn w-100">
                      <Link to="#">
                        <span>
                          <img src="images/linkedin-login.svg" alt="" />
                        </span>
                        {t("Login with Linkedin")}
                      </Link>
                    </div>
                    <div className="linkedin_btn google_btn mb-0 w-100">
                      <Link to="#">
                        <span>
                          <img src="images/google.svg" alt="" />
                        </span>
                        {t("Login with Google")}
                      </Link>
                    </div>
                  </div> */}
                  {/* ----- linkdin and google login section end ----- */}
                </form>
                {/* ----- register form section end ----- */}
              </div>
            </div>
            {/* ---- modal body section end ---- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
