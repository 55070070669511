import React from 'react';
import ExperienceLeftPanel from '../ExperienceLeftPanel/ExperienceLeftPanel';
import ExperienceRightPanel from '../ExperienceRightPanel/ExperienceRightPanel';

const ExperienceBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <ExperienceLeftPanel moduleName="experience" />
          <ExperienceRightPanel />
        </div>
      </div>
    </section>
  );
};

export default ExperienceBody;
