import React from 'react';
import { Link } from 'react-router-dom';
import MostImportantPanel from '../../MostImportantPanel/MostImportantPanel';

const EventsTab = () => {
  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="activity_innr">
          <div className="groups_item">
            <div className="top_company_row">
              <div className="event_hdng">
                <h3>DTDC Business Meeting</h3>
                <h5>
                  <i className="material-icons-outlined"> event_note </i>
                  <span>Saturday, 12 January 2022</span>
                </h5>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="event_para">
              <p>
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
            </div>
            <div className="event_user">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="/images/expert1.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert2.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert3.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert4.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert5.png" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="groups_item">
            <div className="top_company_row">
              <div className="event_hdng">
                <h3>DTDC Business Meeting</h3>
                <h5>
                  <i className="material-icons-outlined"> event_note </i>
                  <span>Saturday, 12 January 2022</span>
                </h5>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="event_para">
              <p>
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
            </div>
            <div className="event_user">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="/images/expert1.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert2.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert3.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert4.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert5.png" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="groups_item">
            <div className="top_company_row">
              <div className="event_hdng">
                <h3>DTDC Business Meeting</h3>
                <h5>
                  <i className="material-icons-outlined"> event_note </i>
                  <span>Saturday, 12 January 2022</span>
                </h5>
              </div>
              <div className="event_tags">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined ornge_icon">star</i>
                    <span className="rating">
                      4.5 <em>(32)</em>
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">place</i>
                    <span>Madrid, Spain</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="event_para">
              <p>
                We are honu.ai, a venture backed startup building the next
                generation.
              </p>
            </div>
            <div className="event_user">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="/images/expert1.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert2.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert3.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert4.png" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/expert5.png" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default EventsTab;
