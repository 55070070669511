/* eslint-disable */
import React from "react";
import * as url from "helper/UrlHelper";

const RecievedTab = ({ inviteList, rejectInviteHandler, acceptHandler }) => {
  return (
    <div className="notification_inner">
      <div className="post_item">
        {inviteList.map((inviteData, index) => {
          return (
            <div className="white_shadow_bx people_bx" key={index}>
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img
                      src={
                        inviteData.senderimage == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + inviteData.senderimage
                      }
                      alt=""
                    />
                  </div>
                  <div className="people_profile_info">
                    <h3>{inviteData.sendername}</h3>
                    <h5>{inviteData.senderposition}</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            {inviteData.senderratings}{" "}
                            <em>({inviteData.senderreviews})</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <button
                    className="btn gray_btn me-1"
                    onClick={() => {
                      rejectInviteHandler(inviteData.owner._id);
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className="btn outline"
                    onClick={() => {
                      acceptHandler(inviteData._id);
                    }}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecievedTab;
