/* eslint-disable */
import { assetImages } from "constants";
import React, { useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";
import { ecosystemSlug } from "Config/Config";

const CompanyConnectModal = ({ moderatorId, companyid, companyName }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [message, setmessage] = useState("");
  const [isSending, setisSending] = useState(false);

  //function submit contact data
  const submitRequestHandler = async () => {
    try {
      setisSending(true);

      let contactData = {
        moderator: moderatorId,
        kitdigital: "",
        name: userInfo.name ? userInfo.name : "",
        surname: userInfo.surname ? userInfo.surname : "",
        email: userInfo.email,
        customercomment: message,
        source: `Company : ${companyName}`,
        companyid: companyid,
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_SAVE_GUEST_LEAD +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await postData(requestUrl, contactData);

      // console.log(response);

      if (response.status) {
        setisSending(false);

        resetHandler();

        //hide kit_digital modal
        let kitDigitalModal = document.querySelector("#company_connect");
        let modal = bootstrap.Modal.getInstance(kitDigitalModal);
        modal.hide();

        //show otpconfirm modal
        //     let myModal = new bootstrap.Modal(
        //       document.getElementById("success_modal")
        //     );
        //     myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  const resetHandler = () => {
    setmessage("");
  };

  return (
    <div className="post_modal request_modal">
      <div
        className="modal fade"
        id="company_connect"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Send Request</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_description">
                <textarea
                  className="form-control"
                  placeholder="Send Message"
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                ></textarea>
              </div>

              <div className="post_btn">
                <button
                  onClick={submitRequestHandler}
                  className="btn"
                  // data-bs-toggle="modal"
                  // data-bs-dismiss="modal"
                  // data-bs-target="#gretings_mdl"
                >
                  Send Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyConnectModal;
