import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const HomeBanner = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section
      className="banner_area"
      style={{ backgroundImage: `url(${assetImages.connectBannerDotsImg})` }}
    >
      <div className="container">
        <div className="banner_outer">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h1>
                  {t("Creating")} <br /> {t("The Digital")} <br />{" "}
                  {t("Transformation")}
                </h1>
                <p>
                  {t(
                    "Tools that enables an Ecosystem to Digitize your business opportunities and processes"
                  )}
                </p>
                <div className="bnr_btns">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#register_mdl"
                  >
                    {t("Make Your Ecosystem")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
