import { assetImages } from "constants";
import React from "react";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ConnectHowWeDo = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section className="we_doSec pd_130">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t("How do we do it")}?</h5>
          <h2>
            {t("We Do This in Two")} <br /> {t("Different Ways")}
          </h2>
        </div>
        <div className="offer_col_outer">
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src={assetImages.connectOfferOne} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="ofr_icon blue_circle">
                    <span className="material-icons-outlined">videocam</span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>{t("Publishing Offers")}</h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        "You tell us what you need and we propose a meeting by video conference in which we explain in detail how we can help you. We show you our system inside with examples of processes."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src={assetImages.connectOfferTwo} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="ofr_icon orange_circle">
                    <span className="material-icons-outlined">
                      person_search
                    </span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>
                      {t("Launching")} <br /> {t("Challenges")}
                    </h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        "Depending on the chosen proposal, we present the groups and people with whom you are going to collaborate, we explain the work methodology and we give you access to all the tools of the Ecosystem. It's time to get to work..."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectHowWeDo;
