import React from "react";
import { Link } from "react-router-dom";
import MostImportantPanel from "../../MostImportantPanel/MostImportantPanel";

const ForumTab = () => {
  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="forum_bx white_shadow_bx">
          <div className="details_hdng">
            <h4>Forum</h4>
          </div>
          <div className="form_commnts_area">
            <textarea
              className="form-control"
              placeholder="Write your query here"
            ></textarea>
            <div className="furmcmnt_btn">
              <button className="cmnt_btn">
                <input type="file" className="file_btn" />
                <span className="material-icons-outlined">image</span>
              </button>
              <button className="cmnt_btn">
                <span className="material-icons-outlined">send</span>
              </button>
            </div>
          </div>
          <div className="forum_commnts_pnl">
            <div className="forum_row active_forum">
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <Link to="#">
                    <span className="material-icons-outlined">share</span>
                  </Link>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <img src="/images/expert1.png" alt="" />
                  </div>
                  <div className="team_usrTxt">
                    <h3>Roma Keller</h3>
                    <h5>20/07/2021 17:31</h5>
                    <div className="forum_para">
                      <p>
                        My bad, I don't actually have those Processor's to test,
                        but, the i5 9600k is about ( a little worse) on par with
                        the Ryzen 5 2600, so I am just going to go with it. I
                        read on some website that the 1600 and 2600 was better,
                        but, they are both worse than the 9600k. And, anyways I
                        already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                        the most logical upgrade.
                      </p>
                    </div>
                    <div className="forumpic_outer">
                      <div className="forum_pic">
                        <img src="/images/forum-pic1.png" alt="" />
                      </div>
                      <div className="forum_pic">
                        <img src="/images/forum-pic2.png" alt="" />
                      </div>
                    </div>
                    <div className="form_share">
                      <Link to="#">
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>Likes</em>
                        <span>2</span>
                      </Link>
                      <Link to="#" className="commnt_forum">
                        <i className="material-icons-outlined">chat</i>
                        <em>Comment</em>
                        <span>3</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="top_usr_srch commnts_user_fill">
                <div className="search_profile">
                  <img src="/images/user.png" alt="" />
                </div>
                <div className="search_fill comment_search">
                  <input
                    type="text"
                    // value=""
                    className="form-control"
                    placeholder="Start your comments"
                  />
                  <button className="emoji_btn">
                    <i className="material-icons-outlined">send</i>
                  </button>
                </div>
              </div>
              <div className="commnets_reply">
                <div className="forum_cmntsRow reply_row">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#">
                      <span className="material-icons-outlined">share</span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <img src="/images/expert1.png" alt="" />
                    </div>
                    <div className="team_usrTxt">
                      <h3>Roma Keller</h3>
                      <h5>20/07/2021 17:31</h5>
                      <div className="forum_para">
                        <p>
                          There are 2 types of Ryzen 1600(x), There's 1600AE
                          which is from first gen Ryzen and 1600AF which is 2nd
                          gen, same gen as 2600(x).
                        </p>
                      </div>

                      <div className="form_share">
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>Likes</em>
                          <span>2</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="forum_cmntsRow reply_row">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#">
                      <span className="material-icons-outlined">share</span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <img src="/images/expert1.png" alt="" />
                    </div>
                    <div className="team_usrTxt">
                      <h3>Roma Keller</h3>
                      <h5>20/07/2021 17:31</h5>
                      <div className="forum_para">
                        <p>
                          There are 2 types of Ryzen 1600(x), There's 1600AE
                          which is from first gen Ryzen and 1600AF which is 2nd
                          gen, same gen as 2600(x).
                        </p>
                      </div>

                      <div className="form_share">
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>Likes</em>
                          <span>2</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="forum_cmntsRow reply_row">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#">
                      <span className="material-icons-outlined">share</span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <img src="/images/expert1.png" alt="" />
                    </div>
                    <div className="team_usrTxt">
                      <h3>Roma Keller</h3>
                      <h5>20/07/2021 17:31</h5>
                      <div className="forum_para">
                        <p>
                          There are 2 types of Ryzen 1600(x), There's 1600AE
                          which is from first gen Ryzen and 1600AF which is 2nd
                          gen, same gen as 2600(x).
                        </p>
                      </div>

                      <div className="form_share">
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>Likes</em>
                          <span>2</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="forum_cmntsRow reply_row">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#">
                      <span className="material-icons-outlined">share</span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <img src="/images/expert1.png" alt="" />
                    </div>
                    <div className="team_usrTxt">
                      <h3>Roma Keller</h3>
                      <h5>20/07/2021 17:31</h5>
                      <div className="forum_para">
                        <p>
                          There are 2 types of Ryzen 1600(x), There's 1600AE
                          which is from first gen Ryzen and 1600AF which is 2nd
                          gen, same gen as 2600(x).
                        </p>
                      </div>

                      <div className="form_share">
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>Likes</em>
                          <span>2</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum_row">
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <Link to="#">
                    <span className="material-icons-outlined">share</span>
                  </Link>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <img src="/images/expert1.png" alt="" />
                  </div>
                  <div className="team_usrTxt">
                    <h3>Roma Keller</h3>
                    <h5>20/07/2021 17:31</h5>
                    <div className="forum_para">
                      <p>
                        My bad, I don't actually have those Processor's to test,
                        but, the i5 9600k is about ( a little worse) on par with
                        the Ryzen 5 2600, so I am just going to go with it. I
                        read on some website that the 1600 and 2600 was better,
                        but, they are both worse than the 9600k. And, anyways I
                        already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                        the most logical upgrade.
                      </p>
                    </div>

                    <div className="form_share">
                      <Link to="#">
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>Likes</em>
                        <span>2</span>
                      </Link>
                      <Link to="#" className="commnt_forum">
                        <i className="material-icons-outlined">chat</i>
                        <em>Comment</em>
                        <span>3</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum_row">
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <Link to="#">
                    <span className="material-icons-outlined">share</span>
                  </Link>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <img src="/images/expert1.png" alt="" />
                  </div>
                  <div className="team_usrTxt">
                    <h3>Roma Keller</h3>
                    <h5>20/07/2021 17:31</h5>
                    <div className="forum_para">
                      <p>
                        My bad, I don't actually have those Processor's to test,
                        but, the i5 9600k is about ( a little worse) on par with
                        the Ryzen 5 2600, so I am just going to go with it. I
                        read on some website that the 1600 and 2600 was better,
                        but, they are both worse than the 9600k. And, anyways I
                        already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                        the most logical upgrade.
                      </p>
                    </div>

                    <div className="form_share">
                      <Link to="#">
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>Likes</em>
                        <span>2</span>
                      </Link>
                      <Link to="#" className="commnt_forum">
                        <i className="material-icons-outlined">chat</i>
                        <em>Comment</em>
                        <span>3</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum_row">
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <Link to="#">
                    <span className="material-icons-outlined">share</span>
                  </Link>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <img src="/images/expert1.png" alt="" />
                  </div>
                  <div className="team_usrTxt">
                    <h3>Roma Keller</h3>
                    <h5>20/07/2021 17:31</h5>
                    <div className="forum_para">
                      <p>
                        My bad, I don't actually have those Processor's to test,
                        but, the i5 9600k is about ( a little worse) on par with
                        the Ryzen 5 2600, so I am just going to go with it. I
                        read on some website that the 1600 and 2600 was better,
                        but, they are both worse than the 9600k. And, anyways I
                        already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                        the most logical upgrade.
                      </p>
                    </div>

                    <div className="form_share">
                      <Link to="#">
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>Likes</em>
                        <span>2</span>
                      </Link>
                      <Link to="#" className="commnt_forum">
                        <i className="material-icons-outlined">chat</i>
                        <em>Comment</em>
                        <span>3</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default ForumTab;
