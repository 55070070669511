/* eslint-disable */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import images
import { assetImages } from "constants";

import AboutTab from "./Tabs/AboutTab";
import ProductTab from "./Tabs/ProductTab";
import MembersTab from "./Tabs/MembersTab";
import GalleryTab from "./Tabs/GalleryTab";
import ForumTab from "./Tabs/ForumTab";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import ReviewsTab from "./Tabs/ReviewsTab";

const EventDetailsMiddlePanel = ({
  eventDetails,
  eventSummary,
  eventCategories,
  eventInterests,
  eventSearchings,
  eventDocs,
  ratingCount,
  reviewsList,
  ratingPercentArr,
  memberList,
  galleryImages,
}) => {
  // const { screenHeight } = useContext(GlobalProvider);

  const { t } = useTranslation(); // translation marker

  return (
    <div className="pnl_mdlpnl">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                {t("About")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#product">
                {t("Product")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#members">
                {t("Members")}
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#portfolio">
                {t("Gallery")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#forum">
                {t("Forum")}
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#meeting">
                {t("Meeting")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#review">
                {t("Review")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          {/* ------ about tab --------- */}
          <div className="tab-pane fade show active" id="overview">
            <AboutTab
              eventDetails={eventDetails}
              eventSummary={eventSummary}
              eventCategories={eventCategories}
              eventInterests={eventInterests}
              eventSearchings={eventSearchings}
              eventDocs={eventDocs}
            />
          </div>

          {/* --------- gallery tab -------- */}
          <div className="tab-pane fade" id="portfolio">
            <GalleryTab galleryImages={galleryImages} />
          </div>

          {/* ---- members tab ------ */}
          <div className="tab-pane fade" id="members">
            <MembersTab memberList={memberList} />
          </div>

          {/* ------ forum tab ----- */}
          <div className="tab-pane fade" id="forum">
            <ForumTab />
          </div>

          {/* ---- product tab ----- */}
          <div className="tab-pane fade" id="product">
            <ProductTab />
          </div>

          <div className="tab-pane fade" id="review">
            <ReviewsTab
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>

          <div className="tab-pane fade" id="meeting"></div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsMiddlePanel;
