/* eslint-disable */
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

//custom arrow button for react carousel outside
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="inBtns">
      <button className={previous ? "" : "disable"} onClick={previous} />
      <button onClick={previous} className="slick-prev slick-arrow" />
      <button onClick={next} className="slick-next slick-arrow" />
    </div>
  );
};

const CustomerFeedback = () => {
  const { t } = useTranslation(); // translation marker

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // Number of slides to slide at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="review_area pd_130">
      <div className="container">
        <div className="rvw_outer">
          <div className="row">
            <div className="col-lg-5">
              <div className="rvw_leftpnl_heading">
                <div className="page_hdng">
                  <h5>{t("Frequently asked questions")}</h5>
                  <h2>{t("What Our Customer Say`s")}</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="slide_Outer">
                <div className="review_slider">
                  <Carousel
                    responsive={responsive}
                    infinite
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                  >
                    <div className="review_slideItem">
                      <div className="review_slideInner">
                        <div className="koma">
                          <img src={assetImages.komaImg} alt="" />
                        </div>
                        <div className="rvw_para">
                          <p>
                            Wise has changed the game in terms of simplicity,
                            and certainly been a lifesaver for expat living. ”
                          </p>
                        </div>
                        <div className="rvw_users">
                          <div className="user_rvwpic">
                            <img src={assetImages.userImg} alt="" />
                          </div>
                          <div className="user_name">
                            <h4>Monie Disusa</h4>
                            <h6>Project Manager, Indra</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="review_slideItem">
                      <div className="review_slideInner">
                        <div className="koma">
                          <img src={assetImages.komaImg} alt="" />
                        </div>
                        <div className="rvw_para">
                          <p>
                            Wise has changed the game in terms of simplicity,
                            and certainly been a lifesaver for expat living. ”
                          </p>
                        </div>
                        <div className="rvw_users">
                          <div className="user_rvwpic">
                            <img src={assetImages.userImg} alt="" />
                          </div>
                          <div className="user_name">
                            <h4>Monie Disusa</h4>
                            <h6>Project Manager, Indra</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="review_slideItem">
                      <div className="review_slideInner">
                        <div className="koma">
                          <img src={assetImages.komaImg} alt="" />
                        </div>
                        <div className="rvw_para">
                          <p>
                            Wise has changed the game in terms of simplicity,
                            and certainly been a lifesaver for expat living. ”
                          </p>
                        </div>
                        <div className="rvw_users">
                          <div className="user_rvwpic">
                            <img src={assetImages.userImg} alt="" />
                          </div>
                          <div className="user_name">
                            <h4>Monie Disusa</h4>
                            <h6>Project Manager, Indra</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="review_slideItem">
                      <div className="review_slideInner">
                        <div className="koma">
                          <img src={assetImages.komaImg} alt="" />
                        </div>
                        <div className="rvw_para">
                          <p>
                            Wise has changed the game in terms of simplicity,
                            and certainly been a lifesaver for expat living. ”
                          </p>
                        </div>
                        <div className="rvw_users">
                          <div className="user_rvwpic">
                            <img src={assetImages.userImg} alt="" />
                          </div>
                          <div className="user_name">
                            <h4>Monie Disusa</h4>
                            <h6>Project Manager, Indra</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
                {/* <div className="inBtns"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerFeedback;
