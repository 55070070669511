/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

import AppUserLeftPanel from "../LeftPanel/AppUserLeftPanel";
import AppUserMiddlePanel from "../MiddlePanel/AppUserMiddlePanel";
import AppUserRightPanel from "../RightPanel/AppUserRightPanel";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { deleteData, getData, putData, postData } from "utils/Gateway";

import GiveReviewModal from "components/common/Modals/GiveReviewModal";
import ConnectModal from "../Modals/ConnectModal";
import GreetingsModal from "../Modals/GreetingsModal";
import TagsModal from "components/common/Modals/TagsModal";
import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";
import ReviewAddedSuccessModal from "components/common/Modals/ReviewAddedSuccessModal";
import { assetImages } from "constants";
import RecomandationModal from "../Modals/RecomandationModal";
import RecomnadSuccessModal from "../Modals/RecomnadSuccessModal";

const AppUserDetailsBody = () => {
  const { t } = useTranslation(); // translation marker

  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { reloadContactList, setReloadContactList } =
    useContext(GlobalProvider);

  /* use states for top section */
  const [isLoading, setIsLoading] = useState(false);
  const [userAccountID, setUserAccountId] = useState(null);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userPosition, setuserPosition] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [userRate, setExpertRate] = useState("");
  const [userTags, setExpertTags] = useState([]);
  const [isProfileOwner, setIsProfileOwner] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [requestDate, setRequestDate] = useState("");
  const [connectStatus, setConnectStatus] = useState(false);
  const [connectedDate, setConnectedDate] = useState("");
  const [unfriendStatus, setunfriendStatus] = useState("0");
  const [resumeContactOwner, setresumeContactOwner] = useState("0");
  const [resumeContactMember, setresumeContactMember] = useState("0");
  const [expertNotExist, setExpertNotExist] = useState(false);
  const [expertRating, setExpertRating] = useState("");
  const [totalNoReviews, setTotalNoReviews] = useState("");
  const [expertDetails, setExpertDetails] = useState("");
  const [expertSkills, setExpertSkills] = useState([]);
  const [expertEmail, setExpertEmail] = useState("");
  const [expertLanguage, setExpertLanguage] = useState("");
  const [expertEducations, setexpertEducations] = useState([]);
  const [expertExperiences, setExpertExperiences] = useState([]);

  const [ratingCount, setRatingCount] = useState("0.0");
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const [similarProfileList, setSimilarProfileList] = useState([]);

  //function for get user details
  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("details resp======>", response);

      if (response.status && response.data) {
        setExpertNotExist(false);
        const data = response.data;
        setUserAccountId(data._id);
        setUserImage(data.userimage); // Ensure default value if undefined
        setUserName(data.name ? data.name + " " + data.surname : data.email);
        setuserPosition(data.position || "");
        setExpertRate(data.costperhour || "");
        setStateName(data.state || "");
        setCityName(data.city || "");
        setCountryName(data.country || "");
        setExpertRating(data.totalratings);
        setTotalNoReviews(data.totalreviews);
        setExpertTags(data.tags);
        setexpertEducations(data?.educationids ? data?.educationids : []);
        setExpertExperiences(data?.experienceids ? data?.experienceids : []);

        if (data._id == userInfo._id) {
          setIsProfileOwner(true);
        } else {
          setIsProfileOwner(false);
        }

        if (data.isconnect) {
          setIsConnect(true);
          setRequestDate(data.requestdate);
        } else {
          setIsConnect(false);
          setRequestDate("");
        }

        if (data.connectstatus) {
          setConnectStatus(true);
          setConnectedDate(data.connectdate);
        } else {
          setConnectStatus(false);
          setConnectedDate("");
        }

        if (data.unfriendstatus) {
          setunfriendStatus(data.unfriendstatus);
        }

        if (data.resumecontactowner) {
          setresumeContactOwner(data.resumecontactowner);
        }

        if (data.resumecontactmember) {
          setresumeContactMember(data.resumecontactmember);
        }

        setExpertDetails(data.about);
        setExpertSkills(data.skills || []); // Ensure default value if undefined
        setExpertEmail(data.email || "");
        setExpertLanguage(data.language || "");
      } else {
        setExpertNotExist(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    setIsReviewLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=appuser&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      // console.log("review list response", response);

      setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount("");
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for cancel request
  const cancelRequestHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_REJECT_CONTACT_REQUEST +
        `?token=${token}&personid=${params.id}`;

      const response = await deleteData(requestURL);

      console.log(response);

      if (response.status) {
        reloadUserDetailsComponent();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for unfriend contact
  const unfriendHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_UNFRIEND_CONTACT +
        `?token=${token}&personid=${params.id}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        reloadUserDetailsComponent();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for resume contact
  const resumeContactHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_RESUME_CONTACT +
        `?token=${token}&personid=${params.id}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        reloadUserDetailsComponent();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get similar profiles
  const getSimilarProfiles = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_APP_USERS + `?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const filteredResults = response.data.filter(
          (user) => user._id.toString() !== params.id.toString()
        );

        setSimilarProfileList(filteredResults.slice(0, 5));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //fucntion for get api
  const reloadUserDetailsComponent = () => {
    getUserDetails();
    getReviewsLists();
    getSimilarProfiles();
  };

  useEffect(() => {
    if (reloadContactList) {
      reloadUserDetailsComponent();
      setReloadContactList(false);
    }
  }, [reloadContactList]);

  useEffect(() => {
    if (params.id) {
      reloadUserDetailsComponent();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER")
  ) {
    return (
      <section className="feed_pnltotal">
        {isLoading ? null : (
          <div className="container-fluid">
            <div className="back_btn">
              <Link to="/peoples">
                <i className="material-icons-outlined">arrow_back</i>
                <span>{t("Back")}</span>
              </Link>
            </div>

            <div className="pnl_outer">
              {/* -------- left panel -------- */}
              <AppUserLeftPanel
                userName={userName}
                userPosition={userPosition}
                userImage={userImage}
                userRating={expertRating}
                totalNoReviews={totalNoReviews}
                userCity={cityName}
                userState={stateName}
                userCountry={countryName}
                userRate={userRate}
                userTags={userTags}
                isConnect={isConnect}
                connectStatus={connectStatus}
                connectedDate={connectedDate}
                requestDate={requestDate}
                unfriendStatus={unfriendStatus}
                resumeContactOwner={resumeContactOwner}
                resumeContactMember={resumeContactMember}
                isProfileOwner={isProfileOwner}
                cancelRequestHandler={cancelRequestHandler}
                unfriendHandler={unfriendHandler}
                resumeContactHandler={resumeContactHandler}
              />

              {/* ------ mdddle panel ------- */}
              <AppUserMiddlePanel
                expertDetails={expertDetails}
                expertSkills={expertSkills}
                expertEmail={expertEmail}
                expertLanguage={expertLanguage}
                expertExperiences={expertExperiences}
                expertEducations={expertEducations}
                ratingCount={ratingCount}
                reviewsList={reviewsList}
                ratingPercentArr={ratingPercentArr}
              />

              {/* ------ right panel ------- */}
              <AppUserRightPanel similarProfileList={similarProfileList} />
            </div>
          </div>
        )}

        {/* ====== popup and modals area ======== */}

        <RecomandationModal
          recieverId={params.id}
          token={token}
          userName={userName}
          userImage={userImage}
        />

        <RecomnadSuccessModal />
        {/* ------- give review modal ---- */}
        <GiveReviewModal moduleName="appuser" />

        <ReviewAddedSuccessModal afterModalClose={reloadUserDetailsComponent} />

        {/* ------ connect modal -------- */}
        <ConnectModal />

        {/* -------- greetings modal -------- */}
        <GreetingsModal afterModalClose={reloadUserDetailsComponent} />

        {/* --------- tags modal ----------- */}
        <TagsModal moduleName="appuser" selectedId={params.id} />

        {/* --------- tags added success / greetings modal ------------- */}
        <TagsAddedSuccessModal
          afterTagModalClose={reloadUserDetailsComponent}
        />
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default AppUserDetailsBody;
