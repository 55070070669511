/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

//import /images
import { assetImages } from "constants";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import languages ------------------------------------------------
import languageOptions from "../../Data/Language.json";
import { getCurrentDateInString } from "utils/getcurrentdatestring";
import { getAlertDateFormat } from "helper/TimeCalculation";

const AuthHeader = ({ moduleName }) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemLogo = localStorage.getItem("ecosystemlogo");
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const cartItemCount = localStorage.getItem("cartitemcount");

  const { t, i18n } = useTranslation(); // translation marker

  const { setReloadContactList, reloadRequestList, setreloadRequestList } =
    useContext(GlobalProvider);

  const [todayUnDoneAlertList, setTodayUnDoneAlertList] = useState([]);
  const [todayDoneAlertList, setTodayDoneAlertList] = useState([]);
  const [otherDayUndoneAlertList, setOtherDayUndoneAlertList] = useState([]);
  const [otherDayDoneAlertList, setOtherDayDoneAlertList] = useState([]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [recievedInviteList, setRecievedInviteList] = useState([]);
  const [sentRequestList, setSentRequestList] = useState([]);

  //function get all alerts
  const getTodaysAlerts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TODAYS_ALERT +
        `?token=${token}&filterdate=${todayValue}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setTodayUnDoneAlertList(
            response.data.filter((alert) => !alert.isdone)
          );
          setTodayDoneAlertList(response.data.filter((alert) => alert.isdone));
        } else {
          setTodayUnDoneAlertList([]);
          setTodayDoneAlertList([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getOtherDaysAlerts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_OTHERDAYS_ALERT + `?token=${token}`;

      if (startDate !== "" && endDate !== "") {
        const filterDateRange = `${startDate},${endDate}`;
        requestUrl += `&filterdate=${filterDateRange}`;
      } else {
        if (startDate !== "") {
          requestUrl += `&filterdate=${startDate}`;
        }

        if (endDate !== "") {
          requestUrl += `&filterdate=${endDate}`;
        }
      }

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setOtherDayUndoneAlertList(
            response.data.filter((alert) => !alert.isdone)
          );
          setOtherDayDoneAlertList(
            response.data.filter((alert) => alert.isdone)
          );
        } else {
          setOtherDayUndoneAlertList([]);
          setOtherDayDoneAlertList([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //view alert
  const viewAndUpdateAlert = async (id, isDone, isDoneValue) => {
    try {
      let alertData = {};

      if (isDone) {
        alertData.isdone = isDoneValue;
      } else {
        alertData.isread = true;
      }

      let requestUrl = `${url.API_BASE_URL}${url.API_UPDATE_ALERT}/${id}?token=${token}`;

      const response = await putData(requestUrl, alertData);

      console.log(response);

      if (response.status) {
        getTodaysAlerts();
        getOtherDaysAlerts();
        if (!isDone) {
          if (response.data) {
            if (response.data.leadid) {
              history.push(
                `/admin/crm/lead/information/${response.data.leadid}`
              );
            }

            if (response.data.contactid) {
              history.push(
                `/admin/crm/contact/details/timeline/${response.data.contactid}`
              );
            }

            if (response.data.taskid) {
              history.push(`/admin/task/details/${response.data.taskid}`);
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all invite
  const getAllInvites = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_RECIEVED_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setRecievedInviteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all sent requests
  const getAllSentRequests = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_SEND_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setSentRequestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for accept invite
  const acceptInviteHandler = async (contactId) => {
    try {
      let inviteData = {
        memberstatus: "1",
        connecteddate: getCurrentDateInString(),
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_ACCEPT_INVITE +
        `/${contactId}?token=${token}`;

      const response = await putData(requestURL, inviteData);

      if (response.status) {
        setReloadContactList(true);
        getAllInvites();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reject invite
  const rejectInviteHandler = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_REJECT_CONTACT_REQUEST +
        `?token=${token}&personid=${personId}`;

      const response = await deleteData(requestURL);

      console.log(response);

      if (response.status) {
        getAllInvites();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (reloadRequestList) {
      getAllSentRequests();
      setreloadRequestList(false);
    }
  }, [reloadRequestList]);

  useEffect(() => {
    // i18n.changeLanguage("en");

    getTodaysAlerts();
    getOtherDaysAlerts();
    getAllInvites();
    getAllSentRequests();
  }, []);

  return (
    <header className="aftre_login_header">
      <div className="container-fluid">
        <div className="header_inner">
          <div className="left_serch_logo">
            <div className="hdr_hamberger">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.add("sidebar_open");
                }}
              >
                <img src="/images/menu.svg" alt="" />
              </Link>
            </div>
            <Link to="/feed'" className="small_logo">
              <img src="/images/small-logo.svg" alt="" />
            </Link>
            <div className="header_serach">
              <input
                type="text"
                className="form-control"
                placeholder="Search "
              />
              <button type="button" className="srch_btn">
                <span className="material-icons-outlined">search</span>
              </button>
            </div>
          </div>
          <div className="right_user_icon">
            <ul className="list_stye_none">
              <li className="search_btn">
                <Link to="#">
                  <i className="material-icons-outlined">search</i>
                </Link>
              </li>
              <li>
                <Link
                  to="/conversation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Chat"
                >
                  <i className="material-icons-outlined">forum</i>
                </Link>
              </li>

              <li className="dropdown notification">
                <Link
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="material-icons-outlined"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Invites"
                  >
                    group
                  </i>
                  {recievedInviteList.length === 0 ? null : (
                    <span className="notify_nmbr">
                      {recievedInviteList.length}
                    </span>
                  )}
                </Link>

                <ul className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg notification-dropdown border-0">
                  <div className="tabs_links_wrap">
                    <div
                      className="nav nav-tabs body-bg"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link active fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <i className="material-symbols-outlined fs-lg">
                          {" "}
                          outbox{" "}
                        </i>
                        <span>Sent</span>
                      </button>
                      <button
                        className="nav-link fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <i className="material-symbols-outlined fs-lg">
                          {" "}
                          inbox{" "}
                        </i>
                        <span>Recived</span>
                      </button>
                    </div>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    {/* <!-- ========== Start sent request Section ========== --> */}
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      tabIndex="0"
                    >
                      <div className="notify_wrapper">
                        {sentRequestList.length > 0 ? (
                          <div className="notify_innrscroll">
                            {sentRequestList.map((inviteData, index) => {
                              return (
                                <div
                                  className="notify_row d-flex align-items-center gap-2 justify-content-between"
                                  key={index}
                                >
                                  <div className="notify_user_info d-flex align-items-center gap-2">
                                    <div className="notify_icon notify_user d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center gradient-light">
                                      <img
                                        height={30}
                                        width={30}
                                        className="rounded-circle object-fit-cover"
                                        src={
                                          inviteData.memberimage == ""
                                            ? assetImages.defaultUser
                                            : url.SERVER_URL +
                                              inviteData.memberimage
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="notify_text notify_user_info">
                                      <h4 className="text-black fs-sm mb-0 fw-semibold">
                                        {inviteData.membername}
                                      </h4>
                                      <h5 className="fs-sm text-gray mb-0">
                                        {inviteData.memberposition}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="notify_status d-flex align-items-center gap-2">
                                    <h6 className="fs-xs text-gray">
                                      {inviteData.memberstatus == "1"
                                        ? "Accepted"
                                        : "Pending"}
                                    </h6>
                                    <div className="notify_cross">
                                      <Link
                                        to="#"
                                        className={`${
                                          inviteData.memberstatus == "1"
                                            ? "text-success"
                                            : ""
                                        }`}
                                      >
                                        <span
                                          className={`material-symbols-outlined ${
                                            inviteData.memberstatus === "1"
                                              ? ""
                                              : "icon-fill"
                                          }`}
                                        >
                                          {inviteData.memberstatus == "1"
                                            ? "check_circle"
                                            : "cancel"}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                            <img
                              height={150}
                              width={150}
                              src={assetImages.noAlert}
                              alt="noalert"
                            />
                            <h6 className="text-secondary fw-semibold">
                              {" "}
                              Sorry... there is no peding requests
                            </h6>
                          </div>
                        )}
                        <div className="refresh">
                          <Link
                            to="#"
                            className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                            onClick={(e) => {
                              e.stopPropagation();
                              setreloadRequestList(true);
                            }}
                          >
                            <span className="material-symbols-outlined fs-lg">
                              {" "}
                              refresh{" "}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ========== End sent request Section ========== --> */}

                    {/* <!-- ========== Start invite list Section ========== --> */}
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      tabIndex="0"
                    >
                      <div className="notify_wrapper">
                        {recievedInviteList.length > 0 ? (
                          <div className="notify_innrscroll">
                            {recievedInviteList.map((inviteData, index) => {
                              return (
                                <div
                                  className="notify_row d-flex align-items-center gap-2 justify-content-between"
                                  key={index}
                                >
                                  <div className="notify_user_info d-flex align-items-center gap-2">
                                    <div className="notify_icon notify_user d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center gradient-light">
                                      <img
                                        height={30}
                                        width={30}
                                        className="rounded-circle object-fit-cover"
                                        src={
                                          inviteData.senderimage == ""
                                            ? assetImages.defaultUser
                                            : url.SERVER_URL +
                                              inviteData.senderimage
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="notify_text notify_user_info">
                                      <h4 className="text-black fs-sm mb-0 fw-semibold">
                                        {inviteData.sendername}
                                      </h4>
                                      <h5 className="fs-sm text-gray mb-0">
                                        {inviteData.senderposition}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="notify_status d-flex align-items-center gap-2">
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        acceptInviteHandler(inviteData._id);
                                      }}
                                      className="btn btn-outline-primary d-inline-flex align-items-center justify-content-center"
                                    >
                                      Accept
                                    </Link>
                                    <div className="notify_cross">
                                      <Link
                                        to="#"
                                        className=""
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          rejectInviteHandler(
                                            inviteData.owner._id
                                          );
                                        }}
                                      >
                                        <span className="material-symbols-outlined icon-fill">
                                          {" "}
                                          cancel{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                            <img
                              height={150}
                              width={150}
                              src={assetImages.noAlert}
                              alt="noalert"
                            />
                            <h6 className="text-secondary fw-semibold">
                              {" "}
                              Sorry... there is no peding invites
                            </h6>
                          </div>
                        )}
                        <div className="refresh">
                          <Link
                            to="#"
                            className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                            onClick={(e) => {
                              e.stopPropagation();
                              getAllInvites();
                            }}
                          >
                            <span className="material-symbols-outlined fs-lg">
                              {" "}
                              refresh{" "}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ========== End invite list Section ========== --> */}
                  </div>
                </ul>
              </li>

              {/* <li className="dropdown notification">
                <Link
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="material-icons-outlined"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Notifications"
                  >
                    notifications
                  </i>
                  <span className="notify_nmbr">8</span>
                </Link>

                <ul className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg notification-dropdown border-0">
                  <div className="tabs_links_wrap">
                    <div
                      className="nav nav-tabs body-bg"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link active fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                        id="today-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#today"
                        type="button"
                        role="tab"
                        aria-controls="today"
                        aria-selected="true"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="material-symbols-outlined fs-lg">
                          {" "}
                          today{" "}
                        </i>
                        <span>Today</span>
                      </button>
                      <button
                        className="nav-link fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                        id="other-day-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#other-day"
                        type="button"
                        role="tab"
                        aria-controls="other-day"
                        aria-selected="false"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="material-symbols-outlined fs-lg">
                          {" "}
                          calendar_month{" "}
                        </i>
                        <span>Other Day</span>
                      </button>
                    </div>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="today"
                      role="tabpanel"
                      aria-labelledby="today-tab"
                    >
                      <div className="notify_wrapper">
                        <div className="notify_innrscroll">
                          {todayUnDoneAlertList.length > 0 ? (
                            todayUnDoneAlertList.map((alert, index) => {
                              return (
                                <div
                                  className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                    alert.isread ? "" : "body-bg"
                                  }`}
                                  key={index}
                                >
                                  <div className="notify_text">
                                    <p className="fs-xs mb-0">
                                      {alert.alerttype}
                                    </p>
                                    <h4 className="text-black fs-sm mb-0">
                                      {alert.title}
                                    </h4>
                                    <h5 className="fs-sm text-gray mb-0">
                                      {getAlertDateFormat(alert.date)} at{" "}
                                      {alert.time}
                                    </h5>
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewAndUpdateAlert(alert._id, true, true);
                                    }}
                                    className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                      alert.isread
                                        ? "gradient-light"
                                        : "bg-gray-300"
                                    } `}
                                  >
                                    <span
                                      className={`material-symbols-outlined fs-lg icon-fill ${
                                        alert.isread
                                          ? "text-primary"
                                          : "text-gray"
                                      }`}
                                    >
                                      check
                                    </span>
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewAndUpdateAlert(
                                        alert._id,
                                        false,
                                        false
                                      );
                                    }}
                                    className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                      alert.isread
                                        ? "gradient-light"
                                        : "bg-gray-300"
                                    }`}
                                  >
                                    <span
                                      className={`material-symbols-outlined fs-lg icon-fill ${
                                        alert.isread
                                          ? "text-primary"
                                          : "text-gray"
                                      }`}
                                    >
                                      redo
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                              <img
                                height={150}
                                width={150}
                                src={assetImages.noAlert}
                                alt="noalert"
                              />
                              <h6 className="text-secondary fw-semibold">
                                {" "}
                                Sorry... there is no pending alert
                              </h6>
                            </div>
                          )}

                          {todayDoneAlertList.length === 0 ? null : (
                            <div className="task_complete_list dropdown">
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                className="px-3 py-2 bg-primary text-white fw-semibold completed d-flex align-items-center justify-content-between"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                Completed{" "}
                                <span className="material-symbols-outlined  down_arw">
                                  {" "}
                                  keyboard_arrow_down{" "}
                                </span>
                              </Link>

                              <div className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg">
                                {todayDoneAlertList.map((alert, index) => {
                                  return (
                                    <div
                                      className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                        alert.isread ? "" : "body-bg"
                                      }`}
                                      key={index}
                                    >
                                      <div className="notify_text">
                                        <p className="fs-xs mb-0">
                                          {alert.alerttype}
                                        </p>
                                        <h4 className="text-black fs-sm mb-0">
                                          <del>{alert.title}</del>
                                        </h4>
                                        <h5 className="fs-sm text-gray mb-0">
                                          {getAlertDateFormat(alert.date)} at{" "}
                                          {alert.time}
                                        </h5>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          viewAndUpdateAlert(
                                            alert._id,
                                            true,
                                            false
                                          );
                                        }}
                                        className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                          alert.isread
                                            ? "gradient-light"
                                            : "bg-gray-300"
                                        } `}
                                      >
                                        <span
                                          className={`material-symbols-outlined fs-lg icon-fill ${
                                            alert.isread
                                              ? "text-primary"
                                              : "text-gray"
                                          }`}
                                        >
                                          check
                                        </span>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          viewAndUpdateAlert(
                                            alert._id,
                                            false,
                                            false
                                          );
                                        }}
                                        className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                          alert.isread
                                            ? "gradient-light"
                                            : "bg-gray-300"
                                        } `}
                                      >
                                        <span
                                          className={`material-symbols-outlined fs-lg icon-fill ${
                                            alert.isread
                                              ? "text-primary"
                                              : "text-gray"
                                          }`}
                                        >
                                          redo
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="refresh">
                          <Link
                            to="#"
                            className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                            onClick={(e) => {
                              e.stopPropagation();
                              setstartDate("");
                              setendDate("");
                              setReloadAlertList(true);
                            }}
                          >
                            <span className="material-symbols-outlined fs-lg">
                              {" "}
                              refresh{" "}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="other-day"
                      role="tabpanel"
                      aria-labelledby="other-day-tab"
                    >
                      <div className="notify_wrapper">
                        <div className="date_fill_outer d-flex align-items-center p-2 gap-2">
                          <input
                            type="date"
                            className="form-control bg-white border-0"
                            placeholder=""
                            value={startDate}
                            onChange={(e) => setstartDate(e.target.value)}
                          />
                          <input
                            type="date"
                            className="form-control bg-white border-0"
                            placeholder=""
                            value={endDate}
                            onChange={(e) => setendDate(e.target.value)}
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              getOtherDaysAlerts();
                            }}
                            className="apply_btn fs-md fw-semibold text-primary gap-1 d-flex align-items-center"
                          >
                            <i className="material-symbols-outlined fs-lg icon-fill text-primary">
                              {" "}
                              check{" "}
                            </i>
                            <span>Apply</span>
                          </button>
                        </div>
                        <div className="notify_innrscroll">
                          {otherDayUndoneAlertList.length > 0 ? (
                            otherDayUndoneAlertList.map((alert, index) => {
                              return (
                                <div
                                  className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                    alert.isread ? "" : "body-bg"
                                  }`}
                                  key={index}
                                >
                                  <div className="notify_text">
                                    <p className="fs-xs mb-0">
                                      {alert.alerttype}
                                    </p>
                                    <h4 className="text-black fs-sm mb-0">
                                      {alert.title}
                                    </h4>
                                    <h5 className="fs-sm text-gray mb-0">
                                      {getAlertDateFormat(alert.date)} at{" "}
                                      {alert.time}
                                    </h5>
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewAndUpdateAlert(alert._id, true, true);
                                    }}
                                    className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                      alert.isread
                                        ? "gradient-light"
                                        : "bg-gray-300"
                                    } `}
                                  >
                                    <span
                                      className={`material-symbols-outlined fs-lg icon-fill ${
                                        alert.isread
                                          ? "text-primary"
                                          : "text-gray"
                                      }`}
                                    >
                                      check
                                    </span>
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewAndUpdateAlert(
                                        alert._id,
                                        false,
                                        false
                                      );
                                    }}
                                    className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                      alert.isread
                                        ? "gradient-light"
                                        : "bg-gray-300"
                                    }`}
                                  >
                                    <span
                                      className={`material-symbols-outlined fs-lg icon-fill ${
                                        alert.isread
                                          ? "text-primary"
                                          : "text-gray"
                                      }`}
                                    >
                                      redo
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                              <img
                                height={150}
                                width={150}
                                src={assetImages.noAlert}
                                alt="noalert"
                              />
                              <h6 className="text-secondary fw-semibold">
                                {" "}
                                Sorry... there is no pending alert
                              </h6>
                            </div>
                          )}

                          {otherDayDoneAlertList.length === 0 ? null : (
                            <div className="task_complete_list dropdown">
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                className="px-3 py-2 bg-primary text-white fw-semibold completed d-flex align-items-center justify-content-between"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                Completed{" "}
                                <span className="material-symbols-outlined  down_arw">
                                  {" "}
                                  keyboard_arrow_down{" "}
                                </span>
                              </Link>

                              <div className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg">
                                {otherDayDoneAlertList.map((alert, index) => {
                                  return (
                                    <div
                                      className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                        alert.isread ? "" : "body-bg"
                                      }`}
                                      key={index}
                                    >
                                      <div className="notify_text">
                                        <p className="fs-xs mb-0">
                                          {alert.alerttype}
                                        </p>
                                        <h4 className="text-black fs-sm mb-0">
                                          <del>{alert.title}</del>
                                        </h4>
                                        <h5 className="fs-sm text-gray mb-0">
                                          {getAlertDateFormat(alert.date)} at{" "}
                                          {alert.time}
                                        </h5>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          viewAndUpdateAlert(
                                            alert._id,
                                            true,
                                            false
                                          );
                                        }}
                                        className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                          alert.isread
                                            ? "gradient-light"
                                            : "bg-gray-300"
                                        }`}
                                      >
                                        <span
                                          className={`material-symbols-outlined fs-lg icon-fill ${
                                            alert.isread
                                              ? "text-primary"
                                              : "text-gray"
                                          }`}
                                        >
                                          check
                                        </span>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          viewAndUpdateAlert(
                                            alert._id,
                                            false,
                                            false
                                          );
                                        }}
                                        className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                          alert.isread
                                            ? "gradient-light"
                                            : "bg-gray-300"
                                        }`}
                                      >
                                        <span
                                          className={`material-symbols-outlined fs-lg icon-fill ${
                                            alert.isread
                                              ? "text-primary"
                                              : "text-gray"
                                          }`}
                                        >
                                          redo
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="refresh">
                          <Link
                            onClick={(e) => {
                              e.stopPropagation();
                              setstartDate("");
                              setendDate("");
                              setReloadAlertList(true);
                            }}
                            to="#"
                            className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                          >
                            <span className="material-symbols-outlined fs-lg">
                              {" "}
                              refresh{" "}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li> */}

              <li>
                <Link
                  to="/cart"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Cart"
                >
                  <i className="material-icons-outlined">shopping_cart</i>
                  {parseInt(cartItemCount) > 0 ? (
                    <span className="notify_nmbr">{cartItemCount}</span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
              <li>
                <Link
                  to="/pricing"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Plans"
                >
                  <i className="material-icons-outlined">euro</i>
                </Link>
              </li>

              <li className="dropdown">
                <Link
                  to="#"
                  className="user_profile_img"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={
                      userInfo.photoimage
                        ? url.SERVER_URL + userInfo.photoimage?.path
                        : assetImages.defaultUser
                    }
                    alt=""
                  />
                </Link>
                <div className="dropdown-menu">
                  <div className="user_profile_outer d-flex align-items-center justify-content-between">
                    <div className="left_usr_info">
                      <Link
                        to="/profile"
                        className="d-flex align-items-center justify-content-center gap-2"
                      >
                        <span className="menu_usr_profile">
                          <img
                            src={
                              userInfo.photoimage
                                ? url.SERVER_URL + userInfo.photoimage?.path
                                : assetImages.defaultUser
                            }
                            alt=""
                          />
                        </span>

                        {userInfo.name ? (
                          <span>
                            {userInfo.name} {userInfo.surname}
                          </span>
                        ) : (
                          <span>{userInfo.email}</span>
                        )}
                      </Link>
                    </div>
                    <div className="right_usr_logout">
                      <Link to="/logout">
                        <i className="material-icons-outlined">logout</i>
                      </Link>
                    </div>
                  </div>
                  <ul className="list_stye_none">
                    <li>
                      <a
                        href={
                          url.BACKEND_BASE_URL +
                          `/auth/validate?email=${userInfo.email}&token=${token}`
                        }
                      >
                        <i className="material-icons-outlined">
                          space_dashboard
                        </i>
                        <span className="">{t("Dashboard")}</span>
                      </a>
                    </li>
                    <li>
                      <Link
                        to={
                          url.BACKEND_BASE_URL +
                          `/auth/validate?email=${userInfo.email}&token=${token}&redirectto=MY_PROFILE`
                        }
                      >
                        <i className="material-icons-outlined">
                          account_circle
                        </i>
                        <span className="">{t("Profile")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/mycvs">
                        <i className="material-icons-outlined">contact_page</i>
                        <span className="">{t("My CVs")}</span>
                      </Link>
                    </li> */}
                  </ul>

                  {/* ------- language section start ------ */}
                  <div className="language_row mt-3 d-flex align-items-center">
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("en");
                      }}
                      className={i18n.language === "en" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag1.svg" alt="" />
                      </i>
                      <span>En</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("es");
                      }}
                      className={i18n.language === "es" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag2.svg" alt="" />
                      </i>
                      <span>Es</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        i18n.changeLanguage("ca");
                      }}
                      className={i18n.language === "ca" ? "active" : ""}
                    >
                      <i>
                        <img src="/images/flag3.svg" alt="" />
                      </i>
                      <span>CAT</span>
                    </Link>
                  </div>
                  {/* ------- language section end ------ */}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="hdr_lftmenu">
          <span
            className="cross_btn material-icons-outlined"
            onClick={() => document.body.classList.remove("sidebar_open")}
          >
            close
          </span>
          <div className="left_menulist">
            <ul className="list_stye_none">
              <li className={moduleName == "activity" ? "active" : ""}>
                <Link
                  to="/activities"
                  onClick={() => document.body.classList.remove("sidebar_open")}
                >
                  <span className="feed_menu_left">
                    <i className="material-icons-outlined">rss_feed</i>
                    <span>{t("Activities")} </span>
                  </span>
                </Link>
              </li>
              <li className={moduleName == "appuser" ? "active" : ""}>
                <Link
                  to="/peoples"
                  onClick={() => document.body.classList.remove("sidebar_open")}
                >
                  <span className="feed_menu_left">
                    <i className="material-icons-outlined">group</i>
                    <span>{t("People")}</span>
                  </span>
                </Link>
              </li>
              <li className={moduleName == "company" ? "active" : ""}>
                <Link
                  to="/company"
                  onClick={() => document.body.classList.remove("sidebar_open")}
                >
                  <span className="feed_menu_left">
                    <i className="material-icons-outlined">meeting_room</i>
                    <span>{t("Company")}</span>
                  </span>
                </Link>
              </li>
              <li className={moduleName == "event" ? "active" : ""}>
                <Link
                  to="/events"
                  onClick={() => document.body.classList.remove("sidebar_open")}
                >
                  <span className="feed_menu_left">
                    <i className="material-icons-outlined">calendar_month</i>
                    <span>{t("Events")}</span>
                  </span>
                  {/* <span className="feed_count">9+</span> */}
                </Link>
              </li>
              <li className={moduleName == "group" ? "active" : ""}>
                <Link
                  to="/groups"
                  onClick={() => document.body.classList.remove("sidebar_open")}
                >
                  <span className="feed_menu_left">
                    <i className="material-icons-outlined">diversity_2</i>
                    <span>{t("Groups")}</span>
                  </span>
                </Link>
              </li>

              {/* <li>
              <Link to="course.html">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">
                    collections_bookmark
                  </i>
                  <span>{t("Courses")}</span>
                </span>
              </Link>
            </li> */}
              {/* <li>
              <Link to="job.html">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">work_outline</i>
                  <span>{t("Jobs")}</span>
                </span>
              </Link>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuthHeader;
