/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import KitDigitalBanner from "../../components/KitDigitalComponents/kitDigitalBanner/KitDigitalBanner";
import Footer from "../../components/common/Footer";
import Brands from "../../components/common/Brands";
import KitDigitalProjects from "../../components/KitDigitalComponents/KitDigitalProjects/KitDigitalProjects";
import KitDigitalServices from "../../components/KitDigitalComponents/KitDigitalServices/KitDigitalServices";
import ContactNow from "../../components/common/ContactNow";
import KitDigitalWorkProcess from "components/KitDigitalComponents/KitDigitalWorkProcess/KitDigitalWorkProcess";
import Conversation from "components/common/Conversation";

const KitDigital = () => {
  // Create a ref to the ContactNow component's DOM element
  const contactNowRef = useRef(null);

  const [sourceName, setSourceName] = useState("");

  // Function to auto-scroll to the ContactNow component
  const autoScollContactNow = () => {
    // Scroll to the ContactNow component's DOM element with smooth behavior
    contactNowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Set the document title when the KitDigital component mounts
    document.title = "Kit Digital";
  }, []);

  return (
    <>
      {/* ---- Header section start----- */}
      <Header />
      {/* ---- Header section end----- */}

      {/* --- banner start --- */}
      <KitDigitalBanner />
      {/* --- banner end --- */}

      {/* --- brands start --- */}
      <Brands />
      {/* --- brands end --- */}

      {/* --- how we do start --- */}
      <KitDigitalWorkProcess />
      {/* --- how we do end --- */}

      {/* --- conversation start --- */}
      <Conversation
        source="Kit Digital"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />
      {/* --- conversation end --- */}

      {/* --- project start --- */}
      <KitDigitalProjects />
      {/* --- project end --- */}

      {/* --- services start --- */}
      <KitDigitalServices />
      {/* --- services end --- */}

      {/* --- contact now start --- */}
      <div ref={contactNowRef} />
      <ContactNow sourceName={sourceName} setSourceName={setSourceName} />
      {/* --- contact now end --- */}

      {/* --- footer start --- */}
      <Footer />
      {/* --- footer end --- */}
    </>
  );
};

export default KitDigital;
