import React from "react";

const AppUserPlaceHolder = () => {
  return (
    <div className="white_shadow_bx people_bx">
      <div className="people_row">
        <div className="left_people">
          <div className="people_profile">
            <span
              className="placeholder rounded-circle"
              style={{
                width: "60px",
                height: "60px",
                minWidth: "60px",
              }}
            ></span>
          </div>
          <div className="people_profile_info">
            <h3>
              <span className="placeholder" style={{ width: "50%" }}></span>
            </h3>
            <h5>
              <span className="placeholder" style={{ width: "200px" }}></span>
            </h5>
            <div className="event_tags">
              <span className="placeholder" style={{ width: "40%" }}></span>
            </div>
          </div>
        </div>
        <div className="right_people_btn">
          <span
            className="placeholder"
            style={{
              width: "110px",
              height: "40px",
              borderRadius: "30px",
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default AppUserPlaceHolder;
