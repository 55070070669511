/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import CourseListPlaceHolder from "./PlaceHolder/CourseListPlaceHolder";
import CourseListCard from "./Card/CourseListCard";

const CourseLandingMiddlePanel = () => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const placeHolderCount = [1, 2];

  const [isLoading, setIsLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  const [filterTitle, setfilterTitle] = useState("");

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //get course list
  const getAllCourses = async (
    start = "0",
    limit = "100",
    sortingBy = null
  ) => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_AVAILABLE_COURSES +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterTitle != "") {
        requestURL = requestURL + `&filtertitle=${filterTitle}`;
      }

      const response = await getData(requestURL);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setCourseList(response.data);

        if (response.data.length < limit) {
          sethideNextButton(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset filer search
  const resetFilterData = () => {
    setfilterTitle("");
  };

  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
  };

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllCourses("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    if (reloadData) {
      getAllCourses("0", "10", null);
      setReloadData(false);
    }
  }, [reloadData]);

  //use effect for filter search
  useEffect(() => {
    if (filterTitle != "") {
      getAllCourses("0", "10", null);
    }
  }, [filterTitle]);

  useEffect(() => {
    getAllCourses("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>{t('Back')}</span>
        </Link>
      </div> */}

        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
              <input
                type="text"
                className="form-control"
                placeholder="Search by course title"
                value={searchedText}
                onChange={(e) => {
                  setsearchedText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setfilterTitle(searchedText);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  setfilterTitle(searchedText);
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              // data-bs-toggle="offcanvas"
              // data-bs-target="#"
              role="button"
              // aria-controls="event_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>

        {/* <!-- ========== End filter search Section ========== --> */}

        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {placeHolderCount.map((count, index) => {
                  return <CourseListPlaceHolder key={index} />;
                })}
              </div>
            ) : courseList.length === 0 ? (
              <div className="empty_access text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    Sorry....! No course available at this moment
                  </p>
                </div>
              </div>
            ) : (
              <div className="activity_innr">
                {courseList.map((courseData, index) => {
                  return <CourseListCard courseData={courseData} key={index} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default CourseLandingMiddlePanel;
