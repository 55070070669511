import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NotificatioBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="notication_bx white_shadow_bx">
          <div className="contact_header">
            <div className="contact_hdng_row d-flex align-items-center justify-content-between">
              <div className="post_hdng">
                <h4>Notifications</h4>
              </div>
              <div className="contacts_srch_right">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">search</i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">more_vert</i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="notification_inner">
            <div className="notify_innrbx">
              <h3>Today</h3>
              <div className="notify_outer">
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>5min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">file_upload</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has uploaded Document.pdf</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>12min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">checklist</i>
                    </div>
                    <div className="notify_text">
                      <p>Jhon assigned you a new task</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>18min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>5min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>5min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Indra CMS needs your attention</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>30min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>5min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">file_upload</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has uploaded Document.pdf</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>12min ago</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">checklist</i>
                    </div>
                    <div className="notify_text">
                      <p>Jhon assigned you a new task</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>18min ago</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="notify_innrbx">
              <h3>Yesterday</h3>
              <div className="notify_outer">
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>11.30 AM</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">file_upload</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has uploaded Document.pdf</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>11.10 AM</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">checklist</i>
                    </div>
                    <div className="notify_text">
                      <p>Jhon assigned you a new task</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>10.30 AM</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>11.30 MM</h6>
                  </div>
                </div>
                <div className="notify_row">
                  <div className="notify_left">
                    <div className="notify_icon">
                      <i className="material-icons-outlined">done</i>
                    </div>
                    <div className="notify_text">
                      <p>Richard has completed ATS System</p>
                    </div>
                  </div>
                  <div className="notify_status">
                    <h6>9.30 AM</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotificatioBody;
