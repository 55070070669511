/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import AboutTab from "./CourseDetailsBottomTabs/AboutTab/AboutTab";
import LessonsTab from "./CourseDetailsBottomTabs/LessonsTab/LessonsTab";
import DocsTab from "./CourseDetailsBottomTabs/DocsTab/DocsTab";
import TeamTab from "./CourseDetailsBottomTabs/TeamTab/TeamTab";
import ForumTab from "./CourseDetailsBottomTabs/ForumTab/ForumTab";
import EventsTab from "./CourseDetailsBottomTabs/EventsTab/EventsTab";
import ReviewTab from "./CourseDetailsBottomTabs/ReviewTab/ReviewTab";
import ScoreTab from "./CourseDetailsBottomTabs/ScoreTab/ScoreTab";

const CourseDetailsBottomPanel = ({
  courseDetails = {},
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const [showFirstLessonInitital, setshowFirstLessonInitital] = useState(false);

  const initialShowFirstLessonHandler = () => {
    setshowFirstLessonInitital(true);
  };

  return (
    <div className="coursl_lesson_total">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#members">
                Team
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={initialShowFirstLessonHandler}
                className="nav-link"
                data-bs-toggle="tab"
                to="#lessons"
              >
                Lessons
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#events">
                Events
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#forum">
                Forum
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#reviews">
                Reviews
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#docs">
                Docs
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#scores">
                Certificate
              </Link>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="overview">
            <AboutTab courseDetails={courseDetails} />
          </div>

          <div className="tab-pane fade" id="members">
            <TeamTab courseDetails={courseDetails} />
          </div>

          <div className="tab-pane fade" id="lessons">
            <LessonsTab
              courseDetails={courseDetails}
              showFirstLessonInitital={showFirstLessonInitital}
              setshowFirstLessonInitital={setshowFirstLessonInitital}
            />
          </div>

          <div className="tab-pane fade" id="docs">
            <DocsTab />
          </div>

          <div className="tab-pane fade" id="forum">
            <ForumTab />
          </div>

          <div className="tab-pane fade" id="events">
            <EventsTab />
          </div>

          <div className="tab-pane fade" id="reviews">
            <ReviewTab
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>

          <div className="tab-pane fade" id="scores">
            <ScoreTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsBottomPanel;
