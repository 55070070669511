import React from 'react';
import { Link } from 'react-router-dom';

const ESGReportPopup = () => {
  return (
    <div className="esg_canvas">
      <div className="offcanvas offcanvas-end" id="offcanvasRight">
        <div className="offcanvas-header">
          <div className="container">
            <div className="back_btn">
              <Link to="#" data-bs-dismiss="offcanvas">
                <i className="material-icons-outlined">arrow_back</i>
                <span>Back</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="esg_report_goals">
            <div className="container">
              <div className="top_logo_info mb-2">
                <ul className="list_stye_none d-flex align-items-center gap-2">
                  <li>
                    <img src="/images/micro-logo.svg" alt="" />
                  </li>
                  <li className="d-flex align-items-center gap-1">
                    <i className="material-icons-outlined">calendar_month</i>
                    <span>Year: 2023</span>
                  </li>
                </ul>
              </div>
              <div className="esg_hdng mb-5">
                <h2>Annual ESG report with sustainable goals</h2>
                <p>
                  The Sustainable Development Goals (SDGs) are the most
                  ambitious programme in history to address the major social and
                  environmental challenges facing humanity.
                </p>
              </div>
              <div className="goals_outer">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="goals_leftpnl listing_circle">
                      <p>
                        The Internet and digital solutions are key to achieving
                        more than half the SDG targets. At Telefónica we are
                        taking up the baton and committing ourselves to the 2030
                        Agenda:
                      </p>
                      <ul className="list_stye_none">
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            <strong>Build a greener future:</strong> taking
                            advantage of the power of digitalisation to curb
                            climate change.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            <strong>Help society thrive:</strong> promoting
                            economic and social progress based on
                            digitalisation, leaving no one behind.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            <strong>Lead by example:</strong> maintaining strict
                            levels of governance oversight in order to build
                            trust.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            <strong>Save the future:</strong> maintaining strict
                            levels of governance oversight in order to build
                            trust.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="goals_rightpnl">
                      <img src="/images/esg-goals.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ceo_row">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ceo_pic">
                      <img src="/images/esg-ceo.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ceo_textpnl">
                      <div className="esg_sub_heading">
                        <h3>Letter from the CEO</h3>
                        <p>
                          <span>Mr. San Anderson</span> explains our progress to
                          build a greener future, help society thrive and lead
                          by example.
                        </p>
                      </div>
                      <Link to="#" className="btn outline">
                        Open CEO Letter
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sustainability_row">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="goals_leftpnl listing_circle">
                      <div className="esg_sub_heading">
                        <h3>
                          Our Commitment to <br /> Sustainability
                        </h3>
                      </div>
                      <ul className="list_stye_none">
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            Balancing present needs with future well-being
                            through mindful consideration of environmental,
                            social, and economic factors.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            We have achieved 3 citification in Hunger index,
                            water index and social developments
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            Sustainability policies are incorporated into daily
                            operations by aligning business practices with
                            eco-friendly and socially responsible principles to
                            ensure long-term viability and minimal environmental
                            impact.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="goals_rightpnl sustainability_pic">
                      <img src="/images/esg-susantibility.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="acchivement_sec">
            <div className="container">
              <div className="achievements_row">
                <div className="esg_sub_heading">
                  <h3>Achievements of the Year</h3>
                </div>
                <div className="tab_links esg_tablinks mb-4">
                  <ul className="nav nav-tabs list_stye_none d-flex gap-2">
                    <li className="nav-item">
                      <Link
                        className="nav-link active show"
                        data-bs-toggle="tab"
                        to="#environmental"
                      >
                        Environmental
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#social"
                      >
                        Social
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#governance"
                      >
                        Governance
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="environmental">
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="impact_row d-flex align-items-center justify-content-between">
                              <div className="left_impact_info">
                                <h3>Impact on Environment</h3>
                                <ul className="d-flex align-items-center list_stye_none">
                                  <li className="green">
                                    <i className="material-icons-outlined">
                                      payments
                                    </i>
                                    <span>15,000 Euro</span>
                                  </li>
                                  <li className="">
                                    <i className="material-icons-outlined">
                                      clock
                                    </i>
                                    <span>360 hr/year</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="right_impact_icons d-flex align-items-center gap-2 justify-flex-end">
                                <span>
                                  <img
                                    src="/images/envirmental-icon1.svg"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  <img
                                    src="/images/envirmental-icon2.svg"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  <img
                                    src="/images/envirmental-icon3.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>3000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/acchivement-pic.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="social">
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="impact_row d-flex align-items-center justify-content-between">
                              <div className="left_impact_info">
                                <h3>Impact on Environment</h3>
                                <ul className="d-flex align-items-center list_stye_none">
                                  <li className="green">
                                    <i className="material-icons-outlined">
                                      payments
                                    </i>
                                    <span>15,000 Euro</span>
                                  </li>
                                  <li className="">
                                    <i className="material-icons-outlined">
                                      clock
                                    </i>
                                    <span>360 hr/year</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="right_impact_icons d-flex align-items-center gap-2 justify-flex-end">
                                <span>
                                  <img src="/images/social1.svg" alt="" />
                                </span>
                                <span>
                                  <img src="/images/social2.svg" alt="" />
                                </span>
                                <span>
                                  <img src="/images/social3.svg" alt="" />
                                </span>
                              </div>
                            </div>
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>3000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/acchivement-pic.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="governance">
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="impact_row d-flex align-items-center justify-content-between">
                              <div className="left_impact_info">
                                <h3>Impact on Environment</h3>
                                <ul className="d-flex align-items-center list_stye_none">
                                  <li className="green">
                                    <i className="material-icons-outlined">
                                      payments
                                    </i>
                                    <span>15,000 Euro</span>
                                  </li>
                                  <li className="">
                                    <i className="material-icons-outlined">
                                      clock
                                    </i>
                                    <span>360 hr/year</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="right_impact_icons d-flex align-items-center gap-2 justify-flex-end">
                                <span>
                                  <img
                                    src="/images/governance-icon1.svg"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  <img
                                    src="/images/governance-icon2.svg"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  <img
                                    src="/images/governance-icon3.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>5000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                    <ul className="d-flex align-items-center list_stye_none">
                                      <li className="green">
                                        <i className="material-icons-outlined">
                                          payments
                                        </i>
                                        <span>3000 Euro</span>
                                      </li>
                                      <li className="">
                                        <i className="material-icons-outlined">
                                          clock
                                        </i>
                                        <span>120 hr/year</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="points_bx d-inline-flex align-items-center">
                                    <span>Points 68/100</span>
                                    <span>Improvement 42%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/acchivement-pic.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="challenges_learning_bx">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="esg_sub_heading">
                      <h3>
                        Challenges <br /> and Learnings
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="listing_circle">
                      <ul className="list_stye_none">
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            A transparent and honest review of faced obstacles.
                            economic factors.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            How these challenges are informing the company's
                            future efforts and decisions.
                          </span>
                        </li>
                        <li>
                          <i className="material-icons-outlined">
                            check_circle
                          </i>
                          <span>
                            Lessons learned and how they'll be applied moving
                            forward.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="achievements_row upcoming_row">
                <div className="esg_sub_heading">
                  <h3>Goals and Objectives for the Upcoming Year</h3>
                </div>
                <div className="tab_links esg_tablinks mb-4">
                  <ul className="nav nav-tabs list_stye_none d-flex gap-2">
                    <li className="nav-item">
                      <Link
                        className="nav-link active show"
                        data-bs-toggle="tab"
                        to="#environmental2"
                      >
                        Environmental
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#social2"
                      >
                        Social
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#governance2"
                      >
                        Governance
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="environmental2"
                  >
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/upcoming.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="social2">
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/upcoming.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="governance2">
                    <div className="esg_tabs_row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="achievements_lftpnl">
                            <div className="icons_info_pnl">
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon1.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>Reduction in water consumption.</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex align-items-center">
                                <div className="info_icon">
                                  <img src="/images/tab-icon2.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Minimization of waste and recycling
                                      programs.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="icons_info_row d-flex">
                                <div className="info_icon">
                                  <img src="/images/tab-icon3.svg" alt="" />
                                </div>
                                <div className="info_icon_rightpnl">
                                  <div className="left_impact_info">
                                    <h3>
                                      Implemented renewable energy projects.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="achievements_rightpnl">
                            <img src="/images/upcoming.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="impact_stories_sec">
            <div className="container">
              <div className="esg_sub_heading text-center">
                <h3>Impact Stories</h3>
              </div>
              <div className="impact_slide_outer">
                <div className="impact_slider">
                  <div className="impact_slide_item">
                    <div className="impct_slide_innr d-flex align-items-center">
                      <div className="impact_pic">
                        <img src="/images/impact1.jpg" alt="" />
                      </div>
                      <div className="impact_quatetion">
                        <p>
                          “Our contribution to achieving the SDGs is one of the
                          components that will allow society to see that we are
                          part of the solution to the energy, economic, and
                          social challenges we are facing.”
                        </p>
                        <h4>
                          Mariano Marzo <span>Chairman of the Lobees</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="impact_slide_item">
                    <div className="impct_slide_innr d-flex align-items-center">
                      <div className="impact_pic">
                        <img src="/images/impact2.jpg" alt="" />
                      </div>
                      <div className="impact_quatetion">
                        <p>
                          “Our contribution to achieving the SDGs is one of the
                          components that will allow society to see that we are
                          part of the solution to the energy, economic, and
                          social challenges we are facing.”
                        </p>
                        <h4>
                          Mariano Marzo <span>Chairman of the Lobees</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="impact_slide_item">
                    <div className="impct_slide_innr d-flex align-items-center">
                      <div className="impact_pic">
                        <img src="/images/impact1.jpg" alt="" />
                      </div>
                      <div className="impact_quatetion">
                        <p>
                          “Our contribution to achieving the SDGs is one of the
                          components that will allow society to see that we are
                          part of the solution to the energy, economic, and
                          social challenges we are facing.”
                        </p>
                        <h4>
                          Mariano Marzo <span>Chairman of the Lobees</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="impact_arws"></div>
              </div>
            </div>
          </div>

          <div className="microsoft_summery_sec">
            <div className="container">
              <div className="summery_outer row">
                <div className="col-lg-6">
                  <div className="summery_pic">
                    <figure>
                      <img src="/images/microsoft-bg.jpg" alt="" />
                    </figure>
                    <span className="microsoft_logo">
                      <img src="/images/microsoft.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="goals_leftpnl listing_circle">
                    <div className="esg_sub_heading ">
                      <h3>
                        {' '}
                        Summary of the importance of sustainability for
                        Microsoft.
                      </h3>
                    </div>

                    <p>
                      TThe Internet and digital solutions are key to achieving
                      more than half the SDG targets. At Telefónica we are
                      taking up the baton and committing ourselves to the 2030
                      Agenda:
                    </p>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>
                          <strong>Build a greener future:</strong> taking
                          advantage of the power of digitalisation to curb
                          climate change.
                        </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>
                          <strong>Help society thrive:</strong> promoting
                          economic and social progress based on digitalisation,
                          leaving no one behind.
                        </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>
                          <strong>Lead by example:</strong> maintaining strict
                          levels of governance oversight in order to build
                          trust.
                        </span>
                      </li>
                    </ul>
                    <Link to="#" className="btn outline mt-4">
                      Join Our Sustainability Journey
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="report_area">
            <div className="container">
              <div className="esg_sub_heading text-center">
                <h3>See reports from previous years</h3>
              </div>
              <div className="report_outer">
                <div className="report_row d-flex align-items-center justify-content-between">
                  <h4>2023 SDG Report</h4>
                  <div className="report_doc d-flex align-items-center">
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i className="material-icons-outlined">link</i>
                      <span>Open Pdf</span>
                    </Link>
                    <h5>2.3mb</h5>
                  </div>
                </div>
                <div className="report_row d-flex align-items-center justify-content-between">
                  <h4>2022 SDG Report</h4>
                  <div className="report_doc d-flex align-items-center">
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i className="material-icons-outlined">link</i>
                      <span>Open Pdf</span>
                    </Link>
                    <h5>2.3mb</h5>
                  </div>
                </div>
                <div className="report_row d-flex align-items-center justify-content-between">
                  <h4>2021 SDG Report</h4>
                  <div className="report_doc d-flex align-items-center">
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i className="material-icons-outlined">link</i>
                      <span>Open Pdf</span>
                    </Link>
                    <h5>2.3mb</h5>
                  </div>
                </div>
                <div className="report_row d-flex align-items-center justify-content-between">
                  <h4>2020 SDG Report</h4>
                  <div className="report_doc d-flex align-items-center">
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i className="material-icons-outlined">link</i>
                      <span>Open Pdf</span>
                    </Link>
                    <h5>2.3mb</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="esg_contact_area">
            <div className="container">
              <div className="esgcontact_dots esgcontact_dots1">
                <img src="/images/esg-contact-dots1.png" alt="" />
              </div>
              <div className="esgcontact_dots esgcontact_dots2">
                <img src="/images/esg-contact-dots2.png" alt="" />
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <div className="contact_esg_lft">
                    <div className="esg_sub_heading">
                      <h3>
                        Contact information <br /> of Lobees
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="contact_esg_right">
                    <div className="esg_contact_info mb-4">
                      <ul className="list_stye_none">
                        <li>
                          <Link to="mailto:hello@lobees.com">
                            <i className="material-icons-outlined">email</i>
                            <span>hello@lobees.com</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="tel:+189564 85265">
                            <i className="material-icons-outlined">phone</i>
                            <span>+1 89564 85265</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="doc_link d-flex align-items-center gap-2 mb-4">
                      <Link
                        to="#"
                        className="btn d-flex align-items-center gap-1"
                      >
                        <i className="material-icons-outlined">link</i>
                        <span>Policies</span>
                      </Link>
                      <Link
                        to="#"
                        className="btn d-flex align-items-center gap-1"
                      >
                        <i className="material-icons-outlined">link</i>
                        <span>Previous reports</span>
                      </Link>
                      <Link
                        to="#"
                        className="btn d-flex align-items-center gap-1"
                      >
                        <i className="material-icons-outlined">link</i>
                        <span>Resources</span>
                      </Link>
                    </div>
                    <div className="contact_icons d-flex align-items-center gap-2">
                      <span>
                        <img src="/images/tab-icon1.svg" alt="" />
                      </span>
                      <span>
                        <img src="/images/tab-icon2.svg" alt="" />
                      </span>
                      <span>
                        <img src="/images/tab-icon3.svg" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESGReportPopup;
