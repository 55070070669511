import React from 'react';
import { Link } from 'react-router-dom';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const SustainabilityJoin = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="brand_company_sec">
      <div className="container">
        <div className="page_hdng text-center">
          <h2>{t('Why Join LOBEES')}?</h2>
          <p>
            {t(
              'Become a market leader by showcasing your ESG initiatives and gain visibility, credibility, and business opportunities.'
            )}
          </p>
        </div>
        <div className="brand_logo_inner">
          <div className="brand_logo_outer">
            <div className="brand_logoItem">
              <Link to="#">
                <img src="/images/brand-logo1.svg" alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src="/images/brand-logo2.svg" alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src="/images/brand-logo3.svg" alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src="/images/brand-logo4.svg" alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src="/images/brand-logo5.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityJoin;
