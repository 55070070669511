import React from 'react';
import AuthHeader from 'components/common/AuthHeader';
import ExperienceBody from 'components/ExperienceComponents/ExperienceBody/ExperienceBody';

const Experience = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <ExperienceBody />
    </>
  );
};

export default Experience;
