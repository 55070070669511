/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// import Select from "react-select";

const EducationModal = ({
  educationIndex,
  educationData,
  setEducationData,
  educationListBlock,
  setEducationListBlock,
  setEducationIndex,
}) => {
  //function for add edcuation block
  const addEducationHandler = () => {
    setEducationListBlock([...educationListBlock, educationData]);
    closeModalHandler();
  };

  //function for edit education block
  const editEducationBlockHandler = () => {
    const updatedEducationList = [...educationListBlock];
    updatedEducationList[educationIndex] = educationData;
    setEducationListBlock(updatedEducationList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setEducationData({
      degree: "",
      year: "",
      grade: "",
      institute: "",
    });
    setEducationIndex(null);
  };

  const saveInfoHandler = () => {
    if (educationIndex != null) {
      editEducationBlockHandler();
    } else {
      addEducationHandler();
    }

    let loginModal = document.querySelector("#education_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="education_modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Education</h3>
                <h5>Total List of Education</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Degree</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Degree Name"
                      value={educationData.degree}
                      onChange={(e) => {
                        setEducationData((prevData) => ({
                          ...prevData,
                          degree: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Name of Institution </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Institution Name"
                      value={educationData.institute}
                      onChange={(e) => {
                        setEducationData((prevData) => ({
                          ...prevData,
                          institute: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <div className="name_row d-flex gap-2">
                      <div className="w-50">
                        <label>year</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Year"
                          value={educationData.year}
                          onChange={(e) => {
                            setEducationData((prevData) => ({
                              ...prevData,
                              year: e.target.value,
                            }));
                          }}
                        />
                      </div>
                      <div className="w-50">
                        <label>Grade</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Grade"
                          value={educationData.grade}
                          onChange={(e) => {
                            setEducationData((prevData) => ({
                              ...prevData,
                              grade: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={closeModalHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationModal;
