import React from "react";

const QuestionSuccessModal = () => {
  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="question_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              <div className="question_success_hdng">
                <h2>Your query has reached us successfully</h2>
                <h5>Soon we will answer your question. Be patience</h5>
              </div>
              <div className="question_closeBtn">
                <button data-bs-dismiss="modal" className="btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionSuccessModal;
