/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const ConversationProfileDetailsPopup = ({
  conversationName,
  selectedChatLogo,
  selectedChatEmail,
  selectedChatPhone,
  selectedChatAddress,
  selectedChatCompany,
}) => {
  return (
    <div className="profile_rightpopup">
      <div className="offcanvas offcanvas-end" id="profile_pnl">
        <div className="offcanvas-body">
          <div className="profile_rightpnl">
            <div className="profile_hdngTop">
              <h3>Profile</h3>
              <span className="profile_crossbtn" data-bs-dismiss="offcanvas">
                <i className="material-icons-round">close</i>
              </span>
            </div>
            <div className="profile_innr_body">
              <div className="profile_innrScroll">
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    <div className="profile_img grp_profileImg">
                      {/* <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/user1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/user2.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/user3.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div> */}
                      <div className="chat_usrPic">
                        <img
                          src={
                            selectedChatLogo == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + selectedChatLogo
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="profile_textPnl">
                      <h4>{conversationName}</h4>
                      {/* <h5>Online 5 min ago</h5> */}
                    </div>
                  </div>
                </div>
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">email </i>
                      <span>Mail</span>
                    </div>
                    <p>
                      <Link to="#">{selectedChatEmail}</Link>
                    </p>
                  </div>
                </div>
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">phone_in_talk </i>
                      <span>Phone</span>
                    </div>
                    <p>
                      <Link to="#">{selectedChatPhone}</Link>
                    </p>
                  </div>
                </div>
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">place </i>
                      <span>Address</span>
                    </div>
                    <p>{selectedChatAddress}</p>
                  </div>
                </div>
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">location_city </i>
                      <span>Work</span>
                    </div>
                    <p>{selectedChatCompany}</p>
                  </div>
                </div>

                {/* <div className="profile_info_row">
                  <div className="profile_details_row">
                    <Link to="#" className="info_icon w-100">
                      <i className="material-icons-round">delete </i>
                      <span>Delete Chat</span>
                    </Link>
                  </div>
                </div> */}

                {/* <div className="profile_info_row">
                  <div className="profile_details_row mute_row">
                    <Link to="#" className="info_icon w-100">
                      <i className="material-icons-round">
                        notifications_active{" "}
                      </i>
                      <span>Mute Notification</span>
                    </Link>
                    <div className="toggle_switch">
                      <div className="switch_box box_1">
                        <input type="checkbox" className="switch_1" />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">collections </i>
                      <span>Attachments</span>
                    </div>
                    <div className="profile_attachedment_outer">
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached1.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached2.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached4.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached5.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached6.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/fancy-big.jpg" data-fancybox="images">
                          <img src="images/attached2.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/attached4.jpg" data-fancybox="images">
                          <img src="images/attached4.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/attached6.jpg" data-fancybox="images">
                          <img src="images/attached6.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/attached1.jpg" data-fancybox="images">
                          <img src="images/attached1.jpg" alt="" />
                        </Link>
                      </div>

                      <div className="attachedment_item">
                        <Link to="images/attached3.jpg" data-fancybox="images">
                          <img src="images/attached3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link to="images/attached5.jpg" data-fancybox="images">
                          <img src="images/attached5.jpg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="profile_info_row">
                  <div className="profile_details_row signout_fill">
                    <Link to="#" className="info_icon w-100">
                      <i className="material-icons-round">account_circle </i>
                      <span>Sign Out</span>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationProfileDetailsPopup;
