/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import EntrepreneursBanner from "../../components/EntrepreneursComponents/EntrepreneursBanner/EntrepreneursBanner";
import Footer from "../../components/common/Footer";
import Brands from "../../components/common/Brands";
import EntrepreneursCollaborate from "../../components/EntrepreneursComponents/EntrepreneursCollaborate/EntrepreneursCollaborate";
import EntrepreneursHowWeDo from "../../components/EntrepreneursComponents/EntrepreneursHowWeDo/EntrepreneursHowWeDo";
import Conversation from "../../components/common/Conversation";
import CustomerFeedback from "../../components/common/CustomerFeedback";
import ContactNow from "../../components/common/ContactNow";

const Entrepreneurs = () => {
  // Create a ref to the ContactNow component's DOM element
  const contactNowRef = useRef(null);

  const [sourceName, setSourceName] = useState("");

  // Function to auto-scroll to the ContactNow component
  const autoScollContactNow = () => {
    // Scroll to the ContactNow component's DOM element with smooth behavior
    contactNowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Entrepreneurs"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* ---- Header section ----- */}
      <Header />

      {/* --- banner section --- */}
      <EntrepreneursBanner />

      {/* ---- brands section ---- */}
      <Brands />

      {/* --- connect us section --- */}
      <EntrepreneursCollaborate
        source="Entrepreneurs"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* --- how we work section --- */}
      <EntrepreneursHowWeDo />

      {/* ---- let's talk section --- */}
      <Conversation
        source="Entrepreneurs"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* --- customer's feednack --- */}
      <CustomerFeedback />

      {/* --- contact now section --- */}
      <div ref={contactNowRef} />
      <ContactNow sourceName={sourceName} setSourceName={setSourceName} />

      {/* --- footer section --- */}
      <Footer />
    </>
  );
};

export default Entrepreneurs;
