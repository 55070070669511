import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { assetImages } from "constants";

const SuggestCourse = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic">
                <img src="images/company_logo1.svg" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>Call for drone controllers</h3>
                <div className="d-flex align-items-center gap-1">
                    <p>Harry McLaren</p>
                    <div className="chat_rating d-flex align-items-center gap-1 mt-1">
                        <i className="material-icons-outlined ornge_icon">star </i>
                        <h5>4.8 <span>(3,940)</span></h5>
                    </div>
                </div>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Meeting</li>
                        <li>Business</li>
                        <li>Tech</li>
                    </ul>
                </div>
            </div>
        </Link>

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic">
                <img src="images/company_logo1.svg" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>Call for drone controllers</h3>
                <div className="d-flex align-items-center gap-1">
                    <p>Harry McLaren</p>
                    <div className="chat_rating d-flex align-items-center gap-1 mt-1">
                        <i className="material-icons-outlined ornge_icon">star </i>
                        <h5>4.8 <span>(3,940)</span></h5>
                    </div>
                </div>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Meeting</li>
                        <li>Business</li>
                        <li>Tech</li>
                    </ul>
                </div>
            </div>
        </Link>

    
        <div><Link to="#" className="btn outline" >View more courses?</Link></div>

    </div>

    
  );
};

export default SuggestCourse;
