/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

const SuccessStoriesLandingList = ({ storyList }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <section className="success_stories">
      <div className="container">
        <div className="page_hdng">
          <h5>{t("Clients")}</h5>
          <h2>{t("success stories")}</h2>
        </div>

        {/* --- story list section start --- */}
        <div className="sucess_outer">
          <div className="row">
            {storyList.map((storyData, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div className="success_item">
                    {/* ----- project name ----- */}
                    <h5>{storyData.projectname}</h5>

                    {/* ----- company logo section start ----- */}
                    <span className="success_logo">
                      <img
                        src={
                          storyData.companylogo == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + storyData.companylogo
                        }
                        alt="companylogo"
                      />
                    </span>
                    {/* ----- company logo section end ----- */}

                    {/* ----- summary ----- */}
                    <p>{storyData.summary.slice(0, 50)} ...</p>
                    <Link
                      to={`/successstories/details/${storyData._id}`}
                      className="btn w-100"
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* --- story list section end --- */}
      </div>
    </section>
  );
};

export default SuccessStoriesLandingList;
