import React from "react";
import JobLandingLeftPanel from "../JobLandingLeftPanel/JobLandingLeftPanel";
import JobLandingMiddlePanel from "../JobLandingMiddlePanel/JobLandingMiddlePanel";
import AllFilterModal from "components/common/Modals/AllFilterModal";

const JobLandingBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer">
          {/* --- left panel --- */}
          <JobLandingLeftPanel />

          {/* --- Middle panel --- */}
          <JobLandingMiddlePanel />
        </div>
      </div>

      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default JobLandingBody;
