import React from "react";

const ChatMessageContent = ({ message = "" }) => {
  const modifyMessage = (msg = "") => {
    const urlPattern =
      /(?:(https?:\/\/|www\.)?([a-zA-Z0-9.-]+)(\.[a-z]{2,})(\/\S*)?)/g;

    //add tld here for validation
    const validTlds = [
      "com",
      "ai",
      "net",
      "org",
      "io",
      "gov",
      "edu",
      "us",
      "co",
      "dev",
    ]; // Add more valid TLDs as needed

    return msg.replace(urlPattern, (match, protocol, domain, tld, path) => {
      const tldWithoutDot = tld.slice(1).toLowerCase(); // Remove the dot from the TLD and convert to lowercase
      if (!validTlds.includes(tldWithoutDot)) {
        return match; // If TLD is not valid, return the original match
      }

      const hyperlink = `${protocol ? protocol : "https://"}${domain}${tld}${
        path ? path : ""
      }`;

      // Return the link
      return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${domain}${tld}</a>`;
    });
  };

  return <p dangerouslySetInnerHTML={{ __html: modifyMessage(message) }}></p>;
};

export default ChatMessageContent;
