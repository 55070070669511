import React, { useEffect, useState } from 'react';
import Footer from '../../../components/common/Footer';
import MoreNews from '../../../components/BlogsComponents/BlogDetailsComponents/MoreNews/MoreNews';
import BlogDetailsBody from 'components/BlogsComponents/BlogDetailsBody/BlogDetailsBody';
import Header from 'components/common/Header';
import ContactNow from 'components/common/ContactNow';

const BlogDetails = () => {
  const [blogCategory, setBlogCategory] = useState([]);

  useEffect(() => {
    document.title = 'BlogDetails'; // Update the title when Register component mounts
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      {/* ---- Header section ----- */}
      <Header isBlog={true} />

      {/* --- blog details body --- */}
      <BlogDetailsBody
        blogCategory={blogCategory}
        setBlogCategory={setBlogCategory}
      />

      {/* --- more news section --- */}
      <MoreNews blogCategory={blogCategory} />

      {/* ---- connect now section ---- */}
      <ContactNow />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default BlogDetails;
