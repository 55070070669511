/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { assetImages } from "constants";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const LoginSuccessModal = () => {
  const afterModalClose = () => {
    window.location.reload();
  };
  const { t } = useTranslation(); // translation marker
  return (
    <div className="digital_success_modal">
      <div
        className="modal fade"
        id="login_success_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="kit_success">
                <div className="kit_rewards_icon">
                  <img src={assetImages.rewardLogo} alt="" />
                </div>
                <h2>{t("Login Successfull")}</h2>
                {/* <p>
                  We have received your quarry our team will connect you soon
                  and help you to solve your problem
                </p> */}
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn"
                  onClick={afterModalClose}
                >
                  {t("Close")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSuccessModal;
