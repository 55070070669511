import React from 'react';

const MostImportantPanel = () => {
  return (
    <div className="inner_rightpnl">
      <div className="white_shadow_bx">
        <div className="post_hdng">
          <h4>Most Importants</h4>
        </div>
        <div className="course_puterpnl">
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">fact_check</i>
            </div>
            <div className="course_text">
              <h3>Shareable Certificate</h3>
              <p>Earn a Certificate upon completion</p>
            </div>
          </div>
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">language</i>
            </div>
            <div className="course_text">
              <h3>100% Online Courses</h3>
              <p>Start instantly and learn at your own schedule.</p>
            </div>
          </div>
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">event_note</i>
            </div>
            <div className="course_text">
              <h3>Flexible Schedule</h3>
              <p>Set and maintain flexible deadlines.</p>
            </div>
          </div>
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">school</i>
            </div>
            <div className="course_text">
              <h3>Beginner Level</h3>
              <p>No degree or prior experience required</p>
            </div>
          </div>
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">schedule</i>
            </div>
            <div className="course_text">
              <h3>Approximately 6 months to Complete</h3>
              <p>Suggested pace of 10 hours/week</p>
            </div>
          </div>
          <div className="course_row">
            <div className="course_icon">
              <i className="material-icons-outlined">history_edu</i>
            </div>
            <div className="course_text">
              <h3>English</h3>
              <p>Subtitles: English</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostImportantPanel;
