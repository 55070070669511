/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

const TagsModal = ({ moduleName, selectedId }) => {
  const token = localStorage.getItem("token");

  const [tagName, setTagName] = useState("");

  const [tagList, setTagList] = useState([]);

  const [tagWarning, setTagWarning] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  //function for create new tag
  const createNewTagHandler = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_CREATE_NEW_TAG + `?token=${token}`;

      let tagData = {
        title: tagName,
        slug: tagName,
      };

      const response = await postData(requestUrl, tagData);

      console.log(response);

      if (response.status) {
        setTagName("");
        getAllTags();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = selectedTags;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }

    setSelectedTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for close modal
  const closeModalhandler = () => {
    setTagName("");
    setSelectedTags([]);
    removeActiveClass();
    resetSelectTags();
    setTagWarning(false);
  };

  //function for save tag to user
  const addRemoveTagHandler = async (action) => {
    try {
      let requestUrl = url.API_BASE_URL;

      // select request url based on which module this label component is working on
      if (moduleName === "appuser") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_APP_USER;
      } else if (moduleName === "companydetails") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_COMPANY;
      } else if (moduleName === "eventdetails") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_EVENT;
      } else if (moduleName == "groupdetails") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_GROUP;
      } else if (moduleName == "activity") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_FORUM;
      } else if (moduleName == "coursedetails") {
        requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_COURSE;
      } else if (moduleName == "jobdetails") {
        requestUrl = requestUrl + url.API_ADD_LABEL_CHALLENGE;
      } else {
        alert("error in module selection. please refresh once");
      }

      setIsUpdating(true);

      let tagUpdateUrl =
        requestUrl + `/${selectedId}` + `?token=${token}` + `&action=${action}`;
      // console.log("tagUpdateUrl --------->", tagUpdateUrl);

      const response = await putData(tagUpdateUrl, selectedTags);
      console.log("response in add remove tag-------->", response);

      setIsUpdating(false);
      closeModalhandler();

      // close the modal of addLabel
      let tagModal = document.querySelector("#tags_modal");
      let modal = bootstrap.Modal.getInstance(tagModal);
      modal.hide();

      //display thanks modal
      let thanksModal = new bootstrap.Modal(
        document.getElementById("success_mdl")
      );
      thanksModal.show();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div className="process_modal esg_modal  tags_modalbx">
      <div
        className="modal fade show"
        id="tags_modal"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header text-center">
              <div className="process_hdng w-100 mb-0">
                <h3>Tags Added</h3>                
              </div>
            </div> */}
            <div className="modal-header p-4 pb-0 border-0">
              <div className="process_hdng w-100 mb-0 d-flex align-items-center justify-content-between">
                <h3 id="addLabelModalLabel">Tags</h3>
                <button
                  type="button"
                  className="close mt-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModalhandler}
                >
                  <i className="material-icons-outlined">close</i>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="tags_search mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter to create new tag"
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                />
                <button className="plus_add" onClick={createNewTagHandler}>
                  <span className="material-icons-outlined">add_circle</span>
                </button>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((tag, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagselectedHandler(tag)}
                              id={"tagdata-" + tag.value}
                              className="tagblock d-flex align-items-center justify-content-center"
                            >
                              <span>{tag.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    className="btn"
                    onClick={() => {
                      addRemoveTagHandler("1");
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="btn gray"
                    onClick={() => {
                      addRemoveTagHandler("0");
                    }}
                  >
                    Remove
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsModal;
