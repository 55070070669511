/* eslint-disable */
import React from "react";
import { Link, useParams } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const JobDetailsLeftPanel = ({
  title,
  companyName,
  companyLogoPath,
  jobType,
  minSalary,
  maxSalary,
  cityName,
  country,
  tagList,
  isApplied,
  applicationCount,
}) => {
  const { t } = useTranslation(); // translation marker
  const params = useParams();
  return (
    <div className="pnl_leftpnl">
      <div className="white_shadow_bx people_leftusr">
        <div className="groups_info jobs_info_left">
          <div className="company_logotxt">
            <span>
              <img
                src={
                  companyLogoPath == ""
                    ? assetImages.defaultUser
                    : url.SERVER_URL + companyLogoPath
                }
                alt=""
                height={35}
                className="rounded-circle"
              />
            </span>
            <span className="ms-2">{companyName}</span>
          </div>
          <div className="experts_holdertext">
            <h3>{title}</h3>
          </div>
          <div className="job_info">
            <ul className="list_stye_none">
              <li>{jobType}</li>
              <li>
                {applicationCount} applied
                {/* <span>(40%)</span> */}
              </li>
            </ul>
          </div>
          <div className="">
            <div className="price_fill">
              <span>
                €{minSalary} - {maxSalary}
              </span>
            </div>
            <div className="event_tags">
              <ul className="list_stye_none">
                <li>
                  <i className="material-icons-outlined"> place </i>
                  <span className="">
                    {cityName}, {country}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="feedback_jointbtnOuter">
            <div className="details_apply">
              <Link to={`/cvbuilder/${params.id}`} className="btn outline">
                Apply Now
              </Link>
            </div>

            <div className="tag_btn">
              <Link
                data-bs-target="#tags_modal"
                data-bs-toggle="modal"
                to="#"
                className="btn gray_btn"
              >
                <i className="material-icons-outlined">more</i>
              </Link>
            </div>
            {/* <div className="job_sharebtn">
              <Link to="#">
                <span className="material-icons">share</span>
              </Link>
            </div> */}
          </div>
          <div className="feedback_jointbtnOuter">
            <div className="tags_links mt-4 mb-2">
              <ul className="list_stye_none d-flex flex-wrap gap-2 ">
                {tagList.map((tag, index) => {
                  return (
                    <li key={index}>
                      <Link to="#" className="d-flex align-items-center gap-2">
                        <span>{tag}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsLeftPanel;
