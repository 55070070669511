/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";
//import context
import { GlobalProvider } from "context/GlobalContext";
import ConversationBody from "components/ConversationComponents/ConversationBody/ConversationBody";

const Conversation = () => {
  useEffect(() => {
    document.title = "Conversation";
    document.body.classList.remove("chatmsg_open");
  }, []);

  return (
    <>
      {/* ------- header section ---------- */}
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ---- body section ---- */}
      <ConversationBody />
    </>
  );
};

export default Conversation;
