/* eslint-disable */
import React, { useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

import contactOptions from "Data/ContactOptions.json";
import relationOptions from "Data/RelationOptions.json";

const ConnectModal = ({ afterModalClose }) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  /* ---- state to select option values ---- */
  const [selectContactOption, setSelectContactOption] = useState(null);
  const [selectKnowingOption, setSelectKnowingOption] = useState(null);
  const [connectMessage, setConnectMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  /* ---- state to handle validation flags ---- */
  const [contactOptionError, setContactOptionError] = useState(false);
  const [knowingOptionError, setKnowingOptionError] = useState(false);
  const [connectMessageError, setConnectMessageError] = useState(false);

  /* ---- validation function ---- */
  const validateFields = () => {
    const contactError = !selectContactOption;
    const knowingError = !selectKnowingOption;
    const messageError = !connectMessage.trim();

    setContactOptionError(contactError);
    setKnowingOptionError(knowingError);
    setConnectMessageError(messageError);

    return !(contactError || knowingError || messageError);
  };

  //function for send request
  const sendConnectHandler = async () => {
    // Validate fields
    const isValid = validateFields();

    if (isValid) {
      setIsSending(true);
      try {
        let connectData = {
          member: params.id,
          reason: selectContactOption?.value ? selectContactOption?.value : "",
          source: selectKnowingOption?.value ? selectKnowingOption?.value : "",
          message: connectMessage,
        };

        let requestURL =
          url.API_BASE_URL + url.API_SEND_REQUEST_USER + `?token=${token}`;

        // console.log(requestURL);
        const response = await postData(requestURL, connectData);

        setIsSending(false);
        // console.log(response);

        if (response.status) {
          // close the modal of addLabel
          resetHandler();
          let myModal = document.querySelector("#send_rqst_mdl");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();

          //show register modal
          let greetingModal = new bootstrap.Modal(
            document.getElementById("gretings_mdl")
          );
          greetingModal.show();
        }
      } catch (error) {
        error.message;
      }
    }
  };

  //reset function
  const resetHandler = () => {
    setSelectContactOption(null);
    setSelectKnowingOption(null);
    setConnectMessage("");
    setContactOptionError(false);
    setKnowingOptionError(false);
    setConnectMessageError(false);
  };

  return (
    <div className="post_modal request_modal">
      <div
        className="modal fade"
        id="send_rqst_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Send Request</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_description">
                <div className="model_selectBx">
                  <Select
                    placeholder="Select Contact Type"
                    options={contactOptions}
                    value={selectContactOption}
                    onChange={(val) => setSelectContactOption(val)}
                  />
                  {contactOptionError && (
                    <p className="text-danger mb-0 mt-1">
                      * Please select a contact type.
                    </p>
                  )}
                </div>
                <div className="model_selectBx mt-3">
                  <Select
                    placeholder="Select Relation"
                    options={relationOptions}
                    value={selectKnowingOption}
                    onChange={(val) => setSelectKnowingOption(val)}
                  />
                  {knowingOptionError && (
                    <p className="text-danger mb-0 mt-1">
                      * Please select a reason.
                    </p>
                  )}
                </div>
                <textarea
                  className="form-control mt-3"
                  placeholder="Message of contact"
                  value={connectMessage}
                  onChange={(e) => setConnectMessage(e.target.value)}
                ></textarea>
                {connectMessageError && (
                  <p className="text-danger mb-0 mt-1">
                    * Please enter a message.
                  </p>
                )}
              </div>

              <div className="post_btn">
                <button
                  className="btn"
                  disabled={isSending ? true : false}
                  style={{
                    cursor: isSending ? "not-allowed" : "pointer",
                  }}
                  onClick={sendConnectHandler}
                >
                  Send Request
                  {isSending && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
