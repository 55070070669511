/* eslint-disable */
import CourseDetailsBody from "components/CoursesComponents/CourseDetailsComponents/CourseDetailsBody/CourseDetailsBody";
import AuthHeader from "components/common/AuthHeader";

const CourseDetails = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------- body section ------------ */}
      <CourseDetailsBody />
    </>
  );
};

export default CourseDetails;
