/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
//import images
import { assetImages } from "constants";
import OverviewTab from "./Tabs/OverviewTab/OverviewTab";
import PortfolioTab from "./Tabs/PortfolioTab/PortfolioTab";
import ServicesTab from "./Tabs/ServicesTab/ServicesTab";
import SurveyTab from "./Tabs/SurveyTab/SurveyTab";
import ReviewsTab from "./Tabs/ReviewsTab/ReviewsTab";

const AppUserMiddlePanel = ({
  expertDetails,
  expertSkills,
  expertEmail,
  expertLanguage,
  expertEducations,
  expertExperiences,
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");

  const { t } = useTranslation(); // translation marker

  return (
    <div className="pnl_mdlpnl">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                {t("Overview")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#portfolio">
                {t("Portfolio")}
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#services">
                {t("Services")}
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#survey">
                {t("Survey")}
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#schedule">
                {t("Schedule")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#reviews">
                {t("Reviews")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          {/* ----- overview tab ------- */}
          <div className="tab-pane fade show active" id="overview">
            <OverviewTab
              expertDetails={expertDetails}
              expertSkills={expertSkills}
              expertEmail={expertEmail}
              expertLanguage={expertLanguage}
              expertEducations={expertEducations}
              expertExperiences={expertExperiences}
            />
          </div>

          {/* ------- profile info tab ---------- */}
          <div className="tab-pane fade" id="portfolio">
            <PortfolioTab />
          </div>

          {/* -------- services tab ---------- */}
          <div className="tab-pane fade" id="services">
            <ServicesTab />
          </div>

          {/* -------- survey tab ----------- */}
          <div className="tab-pane fade" id="survey">
            <SurveyTab />
          </div>

          {/* -------- schedule tab ---------- */}
          <div className="tab-pane fade" id="schedule"></div>

          {/* ------- reviews tab ------------- */}
          <div className="tab-pane fade" id="reviews">
            <ReviewsTab
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserMiddlePanel;
