/* eslint-disable */
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//custom arrow button for react carousel outside
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="inBtns news_arws">
      <button className={previous ? "" : "disable"} onClick={previous} />
      <button onClick={previous} className="slick-prev slick-arrow" />
      <button onClick={next} className="slick-next slick-arrow" />
    </div>
  );
};

const SuccessStoriesNewsSlider = ({ reviewsList }) => {
  const starCount = [1, 2, 3, 4, 5];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="news_slider_sec">
      <div className="container">
        <div className="page_hdng">
          <h5>Testimonials</h5>
          <h2>What OTHER Say’s </h2>
        </div>
        <div className="news_slide_outer testimonial_slideouter">
          <div className="news_slider">
            {/* <div className="news_slideItem">
              <div className="review_slideInner">
                <div className="koma">
                  <img src="/images/koma.png" alt="" />
                </div>
                <div className="rvw_para">
                  <p>
                    Wise has changed the game in terms of simplicity, and
                    certainly been a lifesaver for expat living. ”
                  </p>
                </div>
                <div className="rvw_rating">
                  <ul className="list_stye_none d-flex align-items-center">
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="">
                      <i className="material-icons-outlined">star</i>
                    </li>
                  </ul>
                </div>
                <div className="rvw_users">
                  <div className="user_rvwpic">
                    <img src="/images/user.png" alt="" />
                  </div>
                  <div className="user_name">
                    <h4>Monie Disusa</h4>
                    <h6>Project Manager, Indra</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="news_slideItem">
              <div className="review_slideInner">
                <div className="koma">
                  <img src="/images/koma.png" alt="" />
                </div>
                <div className="rvw_para">
                  <p>
                    Wise has changed the game in terms of simplicity, and
                    certainly been a lifesaver for expat living. ”
                  </p>
                </div>
                <div className="rvw_rating">
                  <ul className="list_stye_none d-flex align-items-center">
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="">
                      <i className="material-icons-outlined">star</i>
                    </li>
                  </ul>
                </div>
                <div className="rvw_users">
                  <div className="user_rvwpic">
                    <img src="/images/user.png" alt="" />
                  </div>
                  <div className="user_name">
                    <h4>Monie Disusa</h4>
                    <h6>Project Manager, Indra</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="news_slideItem">
              <div className="review_slideInner">
                <div className="koma">
                  <img src="/images/koma.png" alt="" />
                </div>
                <div className="rvw_para">
                  <p>
                    Wise has changed the game in terms of simplicity, and
                    certainly been a lifesaver for expat living. ”
                  </p>
                </div>
                <div className="rvw_rating">
                  <ul className="list_stye_none  d-flex align-items-center">
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="">
                      <i className="material-icons-outlined">star</i>
                    </li>
                  </ul>
                </div>
                <div className="rvw_users">
                  <div className="user_rvwpic">
                    <img src="/images/user.png" alt="" />
                  </div>
                  <div className="user_name">
                    <h4>Monie Disusa</h4>
                    <h6>Project Manager, Indra</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="news_slideItem">
              <div className="review_slideInner">
                <div className="koma">
                  <img src="/images/koma.png" alt="" />
                </div>
                <div className="rvw_para">
                  <p>
                    Wise has changed the game in terms of simplicity, and
                    certainly been a lifesaver for expat living. ”
                  </p>
                </div>
                <div className="rvw_rating">
                  <ul className="list_stye_none d-flex align-items-center">
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="active">
                      <i className="material-icons-outlined">star</i>
                    </li>
                    <li className="">
                      <i className="material-icons-outlined">star</i>
                    </li>
                  </ul>
                </div>
                <div className="rvw_users">
                  <div className="user_rvwpic">
                    <img src="/images/user.png" alt="" />
                  </div>
                  <div className="user_name">
                    <h4>Monie Disusa</h4>
                    <h6>Project Manager, Indra</h6>
                  </div>
                </div>
              </div>
            </div> */}
            <Carousel
              responsive={responsive}
              infinite
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {reviewsList.map((reviewData, index) => {
                return (
                  <div className="news_slideItem" key={index}>
                    <div className="review_slideInner">
                      <div className="koma">
                        <img src="/images/koma.png" alt="" />
                      </div>
                      <div className="rvw_para">
                        <p>{reviewData.comment}</p>
                      </div>
                      <div className="rvw_rating">
                        <ul className="list_stye_none d-flex align-items-center">
                          {starCount.map((count, index) => {
                            return (
                              <li
                                className={
                                  count <= parseInt(reviewData.rating)
                                    ? "active"
                                    : ""
                                }
                                key={index}
                              >
                                <i className="material-icons-outlined">star</i>
                              </li>
                            );
                          })}
                          {/* <li className="active">
                            <i className="material-icons-outlined">star</i>
                          </li>
                          <li className="active">
                            <i className="material-icons-outlined">star</i>
                          </li>
                          <li className="active">
                            <i className="material-icons-outlined">star</i>
                          </li>
                          <li className="">
                            <i className="material-icons-outlined">star</i>
                          </li> */}
                        </ul>
                      </div>
                      <div className="rvw_users">
                        <div className="user_rvwpic">
                          <img src={reviewData.userimage} alt="" />
                        </div>
                        <div className="user_name">
                          <h4>{reviewData.username}</h4>
                          <h6>{reviewData.userposition}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news_slideItem">
                <div className="review_slideInner">
                  <div className="koma">
                    <img src="/images/koma.png" alt="" />
                  </div>
                  <div className="rvw_para">
                    <p>
                      Wise has changed the game in terms of simplicity, and
                      certainly been a lifesaver for expat living. ”
                    </p>
                  </div>
                  <div className="rvw_rating">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="active">
                        <i className="material-icons-outlined">star</i>
                      </li>
                      <li className="">
                        <i className="material-icons-outlined">star</i>
                      </li>
                    </ul>
                  </div>
                  <div className="rvw_users">
                    <div className="user_rvwpic">
                      <img src="/images/user.png" alt="" />
                    </div>
                    <div className="user_name">
                      <h4>Monie Disusa</h4>
                      <h6>Project Manager, Indra</h6>
                    </div>
                  </div>
                </div>
              </div> */}
            </Carousel>
          </div>
          {/* <div className="inBtns news_arws"></div> */}
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesNewsSlider;
