/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Currency from "Data/Currency.json";
import Country from "Data/Country.json";

const SalaryModal = ({
  setuserCurrency,
  setuserCurrCtc,
  setuserExpctCtc,
  setuserWorkingHours,
  setuserStartTime,
  setuserEndTime,
  setuserCountry,
  userCurrency,
  userCurrCtc,
  userExpctCtc,
  userWorkingHours,
  userStartTime,
  userEndTime,
  userCountry,
}) => {
  const [currencyValue, setcurrencyValue] = useState(null);
  const [currCtc, setcurrCtc] = useState("");
  const [expCtc, setexpCtc] = useState("");
  const [workingHrs, setworkingHrs] = useState("");
  const [countryValue, setcountryValue] = useState(null);
  const [start, setstart] = useState("");
  const [end, setend] = useState("");

  const saveInfoHandler = () => {
    if (currencyValue) {
      setuserCurrency(currencyValue.label);
    }

    if (currCtc !== "") {
      setuserCurrCtc(currCtc);
    }

    if (expCtc !== "") {
      setuserExpctCtc(expCtc);
    }

    if (workingHrs !== "") {
      setuserWorkingHours(workingHrs);
    }

    if (countryValue) {
      setuserCountry(countryValue.label);
    }

    if (start !== "") {
      setuserStartTime(start);
    }

    if (end !== "") {
      setuserEndTime(end);
    }

    // resetHandler();

    let loginModal = document.querySelector("#salary_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setcurrencyValue(null);
    setcurrCtc("");
    setexpCtc("");
    setworkingHrs("");
    setcountryValue(null);
    setstart("");
    setend("");
  };

  useEffect(() => {
    if (userCurrency && userCurrency != "") {
      for (let curr of Currency) {
        if (userCurrency.toString() == curr.label.toString()) {
          setcurrencyValue(curr);
        }
      }
    }

    if (userCurrCtc && userCurrCtc !== "") {
      setcurrCtc(userCurrCtc);
    }

    if (userExpctCtc && userExpctCtc !== "") {
      setexpCtc(userExpctCtc);
    }

    if (userWorkingHours && userWorkingHours !== "") {
      setworkingHrs(userWorkingHours);
    }

    if (userCountry && userCountry !== "") {
      for (let countr of Country) {
        if (userCountry.toString() == countr.label.toString()) {
          setcountryValue(countr);
        }
      }
    }

    if (userStartTime && userStartTime !== "") {
      setstart(userStartTime);
    }

    if (userEndTime && userEndTime !== "") {
      setend(userEndTime);
    }
  }, [
    userCurrency,
    userCurrCtc,
    userExpctCtc,
    userWorkingHours,
    userStartTime,
    userEndTime,
    userCountry,
  ]);

  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="salary_modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Salary and Timings</h3>
                <h5>My Salary expectation and Work Timings</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Select Currency</label>
                    <Select
                      options={Currency}
                      value={currencyValue}
                      onChange={(val) => {
                        setcurrencyValue(val);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Current CTC</label>
                    <div className="phone_filed_outer d-flex gap-2">
                      <div className="w-100">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={currCtc}
                          onChange={(e) => {
                            setcurrCtc(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Expected CTC</label>
                    <div className="phone_filed_outer d-flex gap-2">
                      <div className="w-100">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={expCtc}
                          onChange={(e) => {
                            setexpCtc(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Monthly Working</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Hours"
                      value={workingHrs}
                      onChange={(e) => {
                        setworkingHrs(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Select Country</label>
                    <Select
                      options={Country}
                      value={countryValue}
                      onChange={(val) => {
                        setcountryValue(val);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Timings</label>
                    <div className="experince_date_row d-flex gap-2">
                      <div className="w-100">
                        <input
                          type="time"
                          className="form-control"
                          placeholder="Start Timings"
                          value={start}
                          onChange={(e) => {
                            setstart(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <input
                          type="time"
                          className="form-control"
                          placeholder="End Timings"
                          value={end}
                          onChange={(e) => {
                            setend(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={resetHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryModal;
