/* eslint-disable */
import { useState, useEffect } from "react";
import LessonLeftPanel from "./Panels/LessonLeftPanel";
import LessonRighPanel from "./Panels/LessonRighPanel";
import { assetImages } from "constants";

const LessonsTab = ({
  courseDetails = {},
  showFirstLessonInitital = false,
  setshowFirstLessonInitital,
}) => {
  const [selectedLessonId, setSelectedLessonId] = useState(null);

  const nextLessonHandler = () => {
    const lessonExist = courseDetails.hasOwnProperty("courselessons");

    if (lessonExist) {
      const getCurrentIndexOfLesson = courseDetails?.courselessons.findIndex(
        (item) => item._id === selectedLessonId
      );

      if (getCurrentIndexOfLesson + 1 === courseDetails.courselessons.length) {
        setSelectedLessonId(courseDetails?.courselessons[0]?._id);
      } else {
        setSelectedLessonId(
          courseDetails?.courselessons[getCurrentIndexOfLesson + 1]?._id
        );
      }
    }
  };

  useEffect(() => {
    const lessonExist = courseDetails.hasOwnProperty("courselessons");

    if (lessonExist) {
      setSelectedLessonId(courseDetails?.courselessons[0]?._id);

      if (showFirstLessonInitital) {
        setshowFirstLessonInitital(false);
      }
    }
  }, [courseDetails?.courselessons, showFirstLessonInitital]);

  if (
    courseDetails?.usercourserole !== "" ||
    (courseDetails?.issubscribed && courseDetails?.isapproved)
  ) {
    if (courseDetails?.courselessons?.length > 0) {
      return (
        <div className="lessons_part_outer">
          <LessonLeftPanel
            courseDetails={courseDetails}
            selectedLessonId={selectedLessonId}
            setSelectedLessonId={setSelectedLessonId}
          />
          <LessonRighPanel
            selectedLessonId={selectedLessonId}
            nextLessonHandler={nextLessonHandler}
            userCourseRole={courseDetails?.usercourserole}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="pnl_mdlpnl">
            <div className="empty_access text-center">
              <div className="empty_pic mb-4">
                {" "}
                <img src={assetImages.emptyVector} alt="" />
              </div>
              <div className="empty_text">
                <p className="fs-lg text-gray fw-semibold mb-4">
                  Sorry....! Lessons are not available for this course
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="pnl_mdlpnl">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default LessonsTab;
