/* eslint-disable */
import { useMemo } from "react";

// Material UI table
import { MaterialReactTable } from "material-react-table";

import { useTranslation } from "react-i18next";
import { formatNumber } from "helper/Common/CommonHelper";

const OpportunityTab = ({ groupOpportunityList = [] }) => {
  const { t } = useTranslation(); // for translations

  const columns = useMemo(
    () => [
      {
        accessorKey: "membername",
        header: t("User"),
        size: 200,
      },
      {
        accessorKey: "opportunitysent",
        header: t("Recommended to"),
      },
      {
        accessorKey: "totalamountofsent",
        header: t("Amount"),
        Cell: ({ renderedCellValue, row }) => (
          <p>{formatNumber(row.original.totalamountofsent)} €</p>
        ),
      },
      {
        accessorKey: "totallostsentcount",
        header: t("Lost"),
      },
      {
        accessorKey: "totalownsentcount",
        header: t("Own"),
      },
      {
        accessorKey: "totalpendingsentcount",
        header: t("Pending"),
      },
      {
        accessorKey: "opportunityrecived",
        header: t("Recommended by"),
      },
      {
        accessorKey: "totalamountofrecived",
        header: t("Amount"),
        Cell: ({ renderedCellValue, row }) => (
          <p>{formatNumber(row.original.totalamountofrecived)} €</p>
        ),
      },
      {
        accessorKey: "totallostrecivedcount",
        header: t("Lost"),
      },
      {
        accessorKey: "totalownrecivedcount",
        header: t("Own"),
      },
      {
        accessorKey: "totalpendingrecivedcount",
        header: t("Pending"),
      },
    ],
    []
  );

  return (
    <div className="opportunities_table">
      <MaterialReactTable
        columns={columns} // map columns to be displayed with api data,
        data={groupOpportunityList} // data from api to be displayed
        enableGrouping
        // enableRowSelection // enable showing checkbox
        enableColumnOrdering={true}
        getRowId={(row) => row._id} // map which value to select with row checkbox
        defaultColumn={{
          minSize: 20,
          maxSize: 300,
          size: 50, //make columns wider by default
        }}
      />
    </div>
  );
};

export default OpportunityTab;
