import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="info_hdng">
              <h4>Privacy Settings</h4>
            </div>
            <div className="profile_info_pnl">
              <div className="profile_info_row privacy_row">
                <label>Who can see what you are looking for</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Nobody</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>My Contacts</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Everyone</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile_info_row privacy_row">
                <label>Who can see your mail</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Nobody</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>My Contacts</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Everyone</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile_info_row privacy_row">
                <label>Who can see your phone</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Nobody</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>My Contacts</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Everyone</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile_info_row privacy_row">
                <label>Who can see your contacts</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Nobody</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>My Contacts</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Everyone</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile_info_row privacy_row">
                <label>Subscribe to commercial emails</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <ul className="list_stye_none d-flex align-items-center">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>No</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span></span>
                          <p>Yes</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="password_btnOuter">
              <div className="password_btn">
                <Link to="#" className="btn outline">
                  Save Settings
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyRightPanel;
