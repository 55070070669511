import React from "react";

const JobListPlaceHolder = () => {
  return (
    <div className="groups_item jobs_item">
      <div className="top_company_row">
        <div className="company_logotxt">
          <span className="placeholder" style={{ width: "20px" }}></span>
          <span className="placeholder" style={{ width: "75px" }}></span>
        </div>
        <div className="rating_tags d-flex align-items-center">
          <span className="placeholder" style={{ width: "85px" }}></span>
          <span className="placeholder" style={{ width: "30px" }}></span>
        </div>
      </div>
      <div className="event_hdng">
        <h3>
          <span className="placeholder" style={{ width: "80%" }}></span>
        </h3>
        <h4>
          <span
            className="placeholder"
            style={{ width: "20%", color: "#AAB3BD" }}
          ></span>
        </h4>
        <h5>
          <span className="placeholder" style={{ width: "80%" }}></span>
        </h5>
      </div>
      <div className="event_tags">
        <ul className="list_stye_none">
          <span className="placeholder" style={{ width: "60px" }}></span>
          <span className="placeholder" style={{ width: "60px" }}></span>
          <span className="placeholder" style={{ width: "60px" }}></span>
        </ul>
      </div>
      <div className="event_para">
        <p>
          <span className="placeholder" style={{ width: "100%" }}></span>
          <span className="placeholder" style={{ width: "100%" }}></span>
          <span className="placeholder" style={{ width: "60%" }}></span>
        </p>
      </div>
    </div>
  );
};

export default JobListPlaceHolder;
