import React from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

const CourseDetailsBodyTopPanel = ({
  courseDetails = {},
  isJoining = false,
  joinCourseHandler,
}) => {
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const moduleAccess = localStorage.getItem("moduleaccess");

  return (
    <div className="course_toppnl">
      <div className="course_bluebx white_shadow_bx">
        <div className="course_topbx">
          {/* <h5>UI / UX Design Specialization</h5> */}
          <h3>{courseDetails?.title}</h3>
          <div className="event_tags course_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#" className="intermidate_btn">
                  <span>{courseDetails?.categorynames}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="rating_bx">
                  <i className="material-icons-outlined ornge_icon"> star</i>
                  <span className="rating">
                    {courseDetails?.totalratings}{" "}
                    <em>({courseDetails?.totalreviews})</em>
                  </span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="userexprt_pnltxt">
            <div className="expert_usr">
              <img
                src={
                  courseDetails?.moderatorimage &&
                  courseDetails?.moderatorimage !== ""
                    ? url.SERVER_URL + courseDetails?.moderatorimage
                    : assetImages.defaultUser
                }
                alt=""
              />
            </div>
            <div className="exprt_text">
              <h5>{courseDetails?.moderatorname} </h5>
            </div>
          </div>
        </div>
        <div className="course_bttmbx course_rightbx">
          <div className="duration_outer">
            <div className="course_duartion">
              <ul className="list_stye_none">
                <li>5 chapters</li>
                <li>
                  <span>{courseDetails?.lessoncount} lessons</span>
                </li>
              </ul>
            </div>
            {/* <div className="subscribe_text">
              <label>Created Date :</label>
              <span className="ms-1">{courseDetails?.coursedate}</span>
            </div> */}
          </div>
          <div className="experts_skill course_skill justify-content-between">
            {courseDetails?.tags ? (
              <ul className="list_stye_none">
                {courseDetails?.tags?.map((tag, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">{tag.title}</Link>
                    </li>
                  );
                })}

                {/* <li>
                <Link to="#" className="add_btn">
                  Add
                </Link>
              </li> */}
              </ul>
            ) : null}
            {/* <div className="course_add_btn">
              <Link to="#">Add</Link>
            </div> */}
            <div className="job_sharebtn tag_btns">
              <Link to="#" data-bs-target="#tags_modal" data-bs-toggle="modal">
                <span className="material-icons">more</span>
              </Link>
            </div>
          </div>
          <div className="feedback_jointbtnOuter">
            {/* <div className="job_sharebtn">
              <Link to="#">
                <span className="material-icons">share</span>
              </Link>
            </div> */}
            {/* <div className="job_sharebtn tag_btns">
              <Link to="#" data-bs-target="#tags_modal" data-bs-toggle="modal">
                <span className="material-icons">more</span>
              </Link>
            </div> */}
            {courseDetails?.usercourserole !== "" ? (
              <div className="details_apply">
                <Link to="#" className="btn">
                  {courseDetails?.usercourserole}
                </Link>
              </div>
            ) : courseDetails?.isapproved ? (
              <div className="subscribe_text">
                <label>Joining Date :</label>
                <span className="ms-1">{courseDetails?.joiningdate}</span>
              </div>
            ) : courseDetails?.issubscribed ? (
              <div className="subscribe_text">
                <label>Subsrcibe Date :</label>
                <span className="ms-1">{courseDetails?.subscribedate}</span>
              </div>
            ) : (
              <div className="details_apply">
                <button
                  className="btn"
                  onClick={joinCourseHandler}
                  disabled={isJoining ? true : false}
                  style={{
                    cursor: isJoining ? "not-allowed" : "pointer",
                  }}
                >
                  Enroll
                  {isJoining && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsBodyTopPanel;
