/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const AppUserCard = ({ userData }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="white_shadow_bx people_bx">
      <div className="people_row">
        <div className="left_people">
          <div className="people_profile">
            <img
              src={
                userData.userimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + userData.userimage
              }
              alt=""
            />
          </div>
          <div className="people_profile_info">
            <h3>{userData.username}</h3>
            <h5>{userData.position}</h5>
            <div className="event_tags">
              <ul className="list_stye_none mb-0">
                <li>
                  <i className="material-icons-outlined ornge_icon">star</i>
                  <span className="rating">
                    {userData.totalratings} <em>({userData.totalreviews})</em>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right_people_btn">
          <Link to={`/peoples/details/${userData._id}`} className="btn outline">
            {t("View Details")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppUserCard;
