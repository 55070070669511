import React from "react";
import { Link } from "react-router-dom";

const LicenseModal = () => {
  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="licenses_modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Licenses</h3>
                <h5>My Leagal Documents</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Spanish Passport</label>
                    <ul className="list_stye_none d-flex align-items-center builder-radio-list">
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo1" />
                          <span></span>
                          <p>Yes</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo1" />
                          <span></span>
                          <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <label>Spanish ID</label>
                    <ul className="list_stye_none d-flex align-items-center builder-radio-list">
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo2" />
                          <span></span>
                          <p>Yes</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo2" />
                          <span></span>
                          <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <label>Driving License</label>
                    <ul className="list_stye_none d-flex align-items-center builder-radio-list">
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo3" />
                          <span></span>
                          <p>Yes</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo3" />
                          <span></span>
                          <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <label>Home at Madrid</label>
                    <ul className="list_stye_none d-flex align-items-center builder-radio-list">
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo4" />
                          <span></span>
                          <p>Yes</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check mb-0">
                          <input type="radio" name="rdo4" />
                          <span></span>
                          <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <Link to="#" data-bs-dismiss="modal" className="btn gray">
                    Cancel
                  </Link>
                  <Link to="#" data-bs-dismiss="modal" className="btn">
                    Save
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseModal;
