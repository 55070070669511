/* eslint-disable */
import React, { useEffect, useState } from "react";
import CompanyDetailsLeftPanel from "../CompanyDetailsLeftPanel/CompanyDetailsLeftPanel";
import CompanyDetailsRightPanel from "../CompanyDetailsRightPanel/CompanyDetailsRightPanel";
import ProfessionalBackgroundModal from "../Modals/ProfessionalBackgroundModal";
import ESGReportPopup from "../Popup/ESGReportPopup";
import { Link, useParams } from "react-router-dom";
import { assetImages } from "constants";

import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";
import TagsModal from "components/common/Modals/TagsModal";
import GiveReviewModal from "components/common/Modals/GiveReviewModal";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import CompanyConnectModal from "../Modals/CompanyConnectModal";
import ReviewAddedSuccessModal from "components/common/Modals/ReviewAddedSuccessModal";

const CompanyDetailsBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyTags, setCompanyTags] = useState([]);
  const [companyDetails, setCompanyDetails] = useState("");
  const [companyServices, setCompanyServices] = useState([]);
  const [companySearchings, setCompanySearchings] = useState([]);
  const [companyInterests, setCompanyInterests] = useState([]);
  const [companyDocs, setCompanyDocs] = useState([]);
  const [moderatorId, setModeratorId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [companyMemberList, setCompanyMemberList] = useState([]);

  const [ratingCount, setRatingCount] = useState("0.0");
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [isReviewLoading, setIsReviewLoading] = useState(false);

  //function for company details
  const getCompanyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COMPANY_DETAILS +
        `/${params.id}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        setModeratorId(response.data.moderator);
        setCompanyLogo(
          response.data.logo?.path ? response.data.logo?.path : ""
        );
        setCompanyName(response.data.name);
        setCompanyDetails(response.data.about);
        setCompanyServices(response.data.companyservices);
        setCompanySearchings(response.data.companysearching);
        setCompanyInterests(response.data.companyinterest);
        setCompanyState(response.data.state);
        setCompanyCity(response.data.city);
        setCompanyCountry(response.data.country);
        setCompanyDocs(
          response.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        setCompanyTags(response.data?.companytags);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyMemberList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_MEMBERS +
        `?token=${token}&company=${params.id}&isallparticipants=${true}`;

      // console.log("url of company member list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company member list------>", response);

      if (response.status) {
        setCompanyMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    setIsReviewLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=company&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log("review list response", response);

      setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount("");
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterModalClose = () => {
    getCompanyDetails();
    getAllCompanyMemberList();
    getReviewsLists();
  };

  useEffect(() => {
    if (params.id) {
      getCompanyDetails();
      getAllCompanyMemberList();
      getReviewsLists();
    }
  }, [params.id]);

  // useEffect(() => {
  //   const modalTarget = document.querySelector('#esg_modal');
  //   const modal = new bootstrap.Modal(modalTarget);
  //   modal.show();
  // }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COMPANY")
  ) {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="back_btn">
            <Link to="/company">
              <i className="material-icons-outlined">arrow_back</i>
              <span>Back</span>
            </Link>
          </div>
          <div className="pnl_outer company_details_outer">
            {/* -------- left panel -------- */}
            <CompanyDetailsLeftPanel
              companyLogo={companyLogo}
              companyName={companyName}
              companyCity={companyCity}
              companyState={companyState}
              companyCountry={companyCountry}
              companyTags={companyTags}
              ratingCount={ratingCount}
              reviewsList={reviewsList}
            />

            {/* ------ right panel ------- */}
            <CompanyDetailsRightPanel
              companyDetails={companyDetails}
              companyServices={companyServices}
              companySearchings={companySearchings}
              companyInterests={companyInterests}
              companyDocs={companyDocs}
              companyMemberList={companyMemberList}
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>
        </div>

        {/* ---------- professional background modal ------------- */}
        <ProfessionalBackgroundModal />

        {/* --------- EGS report modal ------------ */}
        <ESGReportPopup />

        {/* --------- tags modal ----------- */}
        <TagsModal moduleName="companydetails" selectedId={params.id} />

        {/* --------- tags added success / greetings modal ------------- */}
        <TagsAddedSuccessModal afterTagModalClose={afterModalClose} />

        {/* ----------- company details feedback modal -------------- */}
        <GiveReviewModal moduleName="company" />

        <ReviewAddedSuccessModal afterModalClose={afterModalClose} />

        {/* ---- company connect modal ----- */}
        <CompanyConnectModal
          moderatorId={moderatorId}
          companyName={companyName}
          companyid={params.id}
        />
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default CompanyDetailsBody;
