/* eslint-disable */
import { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./Route";

const Authmiddleware = () => {
  // Get the previous URL (referrer)
  const previousUrl = document.referrer;

  // Log the previous URL to the console
  // console.log("Previous URL:", previousUrl);

  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);

  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get("ref");
  const secretParam = searchParams.get("secret");

  let redirectUrl = "";
  let pathWithoutDomain = parsedURL.pathname;

  if (refParam && secretParam) {
    redirectUrl = new URL(refParam);
    pathWithoutDomain = redirectUrl.pathname;
  }

  let token = null;

  token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      if (
        pathWithoutDomain.startsWith("/") &&
        !pathWithoutDomain.startsWith("/logout") &&
        pathWithoutDomain.length > 1
      ) {
        // The url starts with "/" and has more characters after it
        // console.log("The url starts with '/' and has content after it.");
        localStorage.setItem("redirectToUrl", pathWithoutDomain);
      } else if (pathWithoutDomain === "/") {
        // The url is exactly "/"
        // console.log("The url is exactly '/'");
        localStorage.setItem("redirectToUrl", "");
      } else {
        // console.log(
        //   "The url does not start with '/' or does not have content after it."
        // );
      }
    }
  }, []);

  return (
    <Switch>
      {/* ====== mapping of non authenticated routes start ====== */}

      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of non authenticated routes end ====== */}

      {/* ====== mapping of authenticated route start ====== */}

      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of authenticated route end ====== */}

      {/* ====== default redirect to dashboard ======= */}
      {/* {token ? <Redirect to="/feed" /> : <Redirect to="/" />} */}
      {token ? (
        refParam && secretParam ? (
          <Redirect to={`${pathWithoutDomain}?secret=${secretParam}`} />
        ) : (
          <Redirect to="/feed" />
        )
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  );
};

export default Authmiddleware;
