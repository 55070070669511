/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

const CartDetails = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const invoiceId = localStorage.getItem("invoiceid"); // mongodb invoice id

  const [orderId, setOrderId] = useState(""); // invoiceid to show from API
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [currency, setCurrency] = useState("€");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);

  const [cartItems, setCartItems] = useState([]);
  const [stripeProductItems, setStripeProductItems] = useState([]);

  //function for company details
  const getInvoiceDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_DETAILS +
        `/${invoiceId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response.data);

      if (response.data) {
        setOrderId(response.data.invoiceid);
        setInvoiceDate(response.data.invoicedate);
        setSubscriptionPlan(response.data.plan);

        setTotalPrice(response.data.totalprice);
        setTotalTax(response.data.totaltax);
        setTotalDiscount(response.data.totaldiscount);
        setTotalPayable(response.data.totalpayable);

        setCartItems(response.data.invoiceitems);

        setStripeProductItems(response.data.stripecartitems);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // update the quantity
  const updateQuantity = async () => {};

  // reset all data
  const resetCartData = () => {
    setOrderId("");
    setTotalPrice(0);
    setTotalTax(0);
    setTotalDiscount(0);
    setTotalPayable(0);
    setCurrency("€");
    setSubscriptionPlan(null);
    setClientDetails(null);
    setCartItems([]);
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  // payment gateway through Stripe
  const makePayment = async () => {
    console.log("make payment");

    let successUrl = url.FRONTEND_BASE_URL + "/payment/success";
    let failureUrl = url.FRONTEND_BASE_URL + "/payment/failure";

    if (invoiceId) {
      successUrl = successUrl + `?ref=${invoiceId}`;
    }

    console.log("userinfo._id", userInfo._id);

    const stripe = await loadStripe(
      "pk_test_51IJm7YL6PquBzXKuvGXoEkT32UbYTvpafT4Wf6naaoNezsrD9Lt1juEzQd4SJzJWscPci5jr2LRbUcUxu5wUu2Pn00kTokCrRA"
    );

    let requestUrl =
      url.API_BASE_URL + url.API_CART_CHECKOUT_SESSION + `?token=${token}`;

    const paymentInfo = {
      cartItems: stripeProductItems,
      userId: userInfo._id,
      invoiceid: invoiceId,
      successurl: successUrl,
      failureurl: failureUrl,
    };

    try {
      const response = await postData(requestUrl, paymentInfo);

      console.log("response", response);

      if (response.id) {
        const result = stripe.redirectToCheckout({
          sessionId: response.id,
        });
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="py-4 px-3 cart_sec">
          <div className="row">
            <div className="col-lg-8">
              <div className="cart_leftpnl px-4 rounded-10 overflow-hidden">
                {cartItems.length > 0 ? (
                  cartItems.map((cartItem, index) => {
                    return (
                      <div className="cart_item py-4 bg-white" key={index}>
                        <div className="row">
                          <div className="col-lg-9 col-md-8">
                            <div className="left_cart_info">
                              {/* <h5 className="mb-2 fs-sm text-gray">#ODR22012024</h5> */}
                              <h3 className="mb-2 fs-md text-black fw-semibold">
                                {cartItem.description}
                              </h3>

                              {subscriptionPlan ? (
                                <ul className="list_stye_none d-flex align-items-center gap-4 mb-2">
                                  <li className="d-flex align-items-center gap-2">
                                    <label className="mb-0 text-gray fs-sm d-inline-block">
                                      Date :
                                    </label>
                                    <span className="text-black fs-sm d-inline-block fw-medium">
                                      {invoiceDate}
                                    </span>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="list_stye_none d-flex align-items-center gap-4 mb-2">
                                  <li className="d-flex align-items-center gap-2">
                                    <label className="mb-0 text-gray fs-sm d-inline-block">
                                      Size:
                                    </label>
                                    <span className="text-black fs-sm d-inline-block fw-medium">
                                      Large
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center gap-2">
                                    <label className="mb-0 text-gray fs-sm d-inline-block">
                                      Color:
                                    </label>
                                    <span className="text-black fs-sm d-inline-block fw-medium">
                                      White
                                    </span>
                                  </li>
                                </ul>
                              )}

                              <div className="discount_tags_outer d-flex align-items-center gap-2 mb-4">
                                {subscriptionPlan ? (
                                  <div className="shoppings_tags d-inline-flex align-items-center gap-2 text-green light-green-bg py-1 px-2 rounded-8">
                                    <span className="fs-sm text-green">
                                      Subscription
                                    </span>
                                    {subscriptionPlan.duration} days
                                  </div>
                                ) : (
                                  <div className="shoppings_tags d-inline-flex align-items-center gap-2 text-green light-green-bg py-1 px-2 rounded-8">
                                    <i className="material-icons text-green fs-md">
                                      local_shipping
                                    </i>
                                    <span className="fs-sm text-green">
                                      Free Shipping
                                    </span>
                                  </div>
                                )}

                                <div className="discount_tags d-inline-flex align-items-center gap-2 text-ornge light-ornge-bg py-1 px-2 rounded-8">
                                  <span className="fs-sm text-ornge">
                                    {cartItem.discountpercent}% Discount
                                  </span>
                                </div>
                              </div>

                              <div className="product_count">
                                <div className="number d-flex align-items-center gap-2">
                                  {subscriptionPlan ? (
                                    "Unit"
                                  ) : (
                                    <span className="minus rounded-circle d-flex align-items-center justify-content-center bg-light text-gray">
                                      .
                                      <i className="material-icons-outlined fs-lg">
                                        remove_circle_outline
                                      </i>
                                    </span>
                                  )}

                                  <input
                                    type="number"
                                    className="form-control text-gray fw-medium text-center bg-white rounded-8"
                                    value="1"
                                    onChange={() => {
                                      updateQuantity();
                                    }}
                                  />

                                  {subscriptionPlan ? (
                                    ""
                                  ) : (
                                    <span className="plus rounded-circle d-flex align-items-center justify-content-center bg-light text-gray">
                                      <i className="material-icons-outlined fs-lg">
                                        {" "}
                                        add_circle_outline
                                      </i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="right_cart_info">
                              <div className="selectbox mb-5 d-flex justify-content-end">
                                <select className="form-select text-gray rounded-8">
                                  <option>VAT 0%</option>
                                  <option>VAT 21%</option>
                                </select>
                              </div>
                              <div className="payment_info">
                                <h4 className="fs-sm fw-medium mb-3 text-black">
                                  {cartItem.price}{" "}
                                  <span className="d-inline-block text-gray">
                                    x {cartItem.unit}
                                  </span>
                                </h4>
                                <h4 className="fs-sm fw-medium mb-3 text-gray">
                                  -{cartItem.taxpercent}%
                                </h4>
                                <h3 className="d-flex gap-2 align-items-center justify-content-end text-gray fs-sm">
                                  Amount
                                  <span className="fs-md text-black d-inline-block fw-medium">
                                    {cartItem.totalprice}€
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h3>Your cart is empty</h3>
                )}
              </div>
            </div>

            <div className="col-lg-4">
              {cartItems.length > 0 ? (
                <div className="cart_rightPnl p-4 bg-white rounded-10 overflow-hidden">
                  <div className="oredr-hdng mb-3">
                    <h4 className="text-gray text-uppercase fs-sm">
                      Order ID : {orderId}
                    </h4>
                  </div>
                  <div className="order_infopnl">
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Total Price</h5>
                      <h5 className="fs-sm text-black mb-0">
                        {totalPrice}
                        {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">VAT</h5>
                      <h5 className="fs-sm text-gray mb-0">
                        {totalTax}
                        {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Discount</h5>
                      <h5 className="fs-sm  mb-0 text-gray">
                        - {totalDiscount}
                        {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 pt-3">
                      <h5 className="fs-sm text-black mb-0">Total Payable</h5>
                      <h3 className="fs-xl  mb-0 text-black fw-semibold">
                        {totalPayable}
                        {currency}
                      </h3>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Link to="#" className="btn w-100" onClick={makePayment}>
                      Pay Now
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartDetails;
