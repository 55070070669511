/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";

//import placeholder component from placeholder f
import BlogDetailsPlaceHolder from "../BlogPlaceHolder/BlogDetailsPlaceHolder";

const BlogDetailsBody = ({ blogCategory, setBlogCategory }) => {
  const { t } = useTranslation(); // translation marker
  const history = useHistory();
  const params = useParams();

  //use states for blog details
  const [isLoading, setIsLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogAuthorImage, setblogAuthorImage] = useState("");
  const [blogAuthorName, setblogAuthorName] = useState("");
  const [blogCreatedDate, setblogCreatedDate] = useState("");
  const [blogImage, setblogImage] = useState("");
  const [blogSummary, setblogSummary] = useState("");
  const [blogDetails, setblogDetails] = useState("");

  //function for get blog details
  const getBlogDetails = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_BLOG_DETAILS + `/${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setIsLoading(false);
        setBlogTitle(response.data?.name);
        setBlogCategory(response.data?.category); //set category
        setblogAuthorImage(response.data?.authorimage); //set author image
        setblogAuthorName(response.data.authorname); //set author name
        setblogCreatedDate(response.data.createddate); //set created date
        setblogImage(response.data.coverimageurl); //set blog image
        setblogSummary(response.data.summary); //set blog summary
        setblogDetails(response.data.description); //set blog details
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBlogDetails();
  }, [params.id]);

  return (
    <section
      className={`blog_landing_total ${isLoading ? "placeholder-glow" : ""}`}
    >
      <div className="container">
        <div className="landing_outer">
          {isLoading ? (
            <BlogDetailsPlaceHolder />
          ) : (
            <div className="landing_row blog_details_row">
              {/* ----- back arrow button section start ------ */}
              <div className="details_arw green_back mb-4">
                <Link
                  to="#"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
              </div>
              {/* ----- back arrow button section end ------ */}

              {/* ----- blog title section start ------ */}
              <div className="landing_hdng_row">
                <div className="page_hdng">
                  <h2>{blogTitle}</h2>
                </div>
              </div>
              {/* ----- blog title section end ------ */}

              {/* ----- blog category,author image,author name and created date section start ------ */}
              <div className="news_date_info">
                {/* ----- category name ----- */}
                {blogCategory.map((category, index) => {
                  return (
                    <label key={index}>{category.label.toUpperCase()}</label>
                  );
                })}

                {/* ---- author image, author name and creted date section start -----*/}
                <div className="bttm_step_cloude">
                  {/* ----- author image section start ------ */}
                  <div className="cloud_icon">
                    <span>
                      <img
                        className="rounded-circle"
                        src={
                          blogAuthorImage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + blogAuthorImage
                        }
                        alt="author"
                      />
                    </span>
                  </div>
                  {/* ----- author image section end ------ */}

                  {/* ----- author name and created date section start ------ */}
                  <div className="cloud_icon_text">
                    <h4>{blogAuthorName}</h4>
                    <h6>{blogCreatedDate}</h6>
                  </div>
                  {/* ----- author name and created date section end ------ */}
                </div>
                {/* ---- author name and creted date section end -----*/}
              </div>
              {/* ----- blog category,author image,author name and created date section end ------ */}

              {/* ----- blog image section start ------ */}

              <div className="blog_pic details_pic">
                {blogImage == "" ? (
                  <span
                    className="placeholder"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "20px",
                      marginBottom: "16px",
                    }}
                  ></span>
                ) : (
                  <img
                    className="rounded"
                    src={url.SERVER_URL + blogImage}
                    alt=""
                  />
                )}
              </div>

              {/* ----- blog image section end ------ */}

              {/* ----- blog summary and blog details section start ------ */}
              <div className="details_content_innr">
                {/* ----- blog summary section start ------ */}
                <div className="content_item">
                  <h4>{t("Summary")}</h4>
                  <div className="details_para">
                    <p>{blogSummary}</p>
                  </div>
                </div>
                {/* ----- blog summary section end ------ */}

                {/* ----- blog details section start ------ */}
                <div className="content_item">
                  <h4>{t("Details")}</h4>
                  <div className="details_para">
                    <p dangerouslySetInnerHTML={{ __html: blogDetails }}></p>
                  </div>
                </div>
                {/* ----- blog details section end ------ */}
              </div>
              {/* ----- blog summary and blog details section end ------ */}

              {/* ----- back button section start ------ */}
              <div className="details_back">
                <Link
                  to="#"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t("Back")}
                </Link>
              </div>
              {/* ----- back button section end ------ */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsBody;
