/* eslint-disable */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// importing translation files here
import translationEN from '../locales/eng/translation.json';
import translationES from '../locales/es/translation.json';
import translationCA from '../locales/ca/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: translationEN,
      },
      es: {
        translations: translationES,
      },
      ca: {
        translations: translationCA,
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });
