import React from 'react';
import { Link } from 'react-router-dom';

const ImprovementAreaRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="info_hdng info_mainhdng">
          <h2>Improvement Area</h2>
          <Link to="#" className="add_anotherBtn">
            <span>Add Another</span>
            <i className="material-icons-outlined">add_circle_outline</i>
          </Link>
        </div>
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="info_hdng">
              <h4>Point 1</h4>
            </div>
            <div className="profile_info_pnl">
              <div className="profile_info_row">
                <label>Subject</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value="Need to improve with new suppliers or collaborators"
                      className="fill_input"
                      placeholder="Enter Subject"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Area to Improve</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <div className="select_bx">
                      <select className="nosearch_select">
                        <option>Select One</option>
                        <option>Improve</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_info_row description_row">
                <label>Discription</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <textarea
                      className="fill_input"
                      placeholder="Enter Discription"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprovementAreaRightPanel;
