import React from 'react';
import { Link } from 'react-router-dom';

const AllFilterModal = () => {
  return (
    
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Sort by</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>Recommended</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>Latest</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Date Posted</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past 24 hours</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past week</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past month</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none filter_rating">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        4 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        3 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        2 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        1 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Posted by</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Connections</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Follows</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Companies</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Groups</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Range Box</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                </div>
              </div>
            </div>
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Slider Range</h4>
              </div>
              <div className="filter_information">
                <div className="slider_range">
                  <img src="images/range.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <Link to="#" className="btn gray">
              Reset
            </Link>
            <Link to="#" className="btn">
              Show Result
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFilterModal;
