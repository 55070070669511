export const formatTimeAgo = (timestamp) => {
  const now = new Date();
  const messageTime = new Date(timestamp);
  const difference = (now - messageTime) / 1000; // Difference in seconds

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Start of today
  const yesterday = new Date(today); // Start of yesterday
  yesterday.setDate(yesterday.getDate() - 1);

  if (difference < 60) {
    return Math.round(difference) + "s ago";
  } else if (difference >= 60 && difference < 3600) {
    const minutes = Math.floor(difference / 60);
    const seconds = Math.round(difference % 60);
    if (seconds === 0) {
      return minutes + "m ago";
    } else {
      return minutes + "m " + seconds + "s ago";
    }
  } else if (difference >= 3600 && difference < 86400 && messageTime >= today) {
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);
    return hours + "h " + minutes + "m ago";
  } else {
    const formattedDate = messageTime
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false, // 24-hour format
      })
      .replace(",", ""); // Removing the comma

    const [datePart, timePart] = formattedDate.split(" ");
    const [month, day, year] = datePart.split("/");
    const formattedDateTime = `${day}/${month}/${year} ${timePart}`;
    return formattedDateTime.toLowerCase(); // Converting to lower case (pm)
  }
};
