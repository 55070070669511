import React from 'react';
import { Link } from 'react-router-dom';

const PostPhotosModal = () => {
  return (
    <div className="post_modal">
      <div className="modal fade" id="create_post_img_mdl">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Create Post
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_left">
                <div className="pofile_pic">
                  <img src="/images/profile-pic5.png" alt="" />
                </div>
                <div className="pofile_info">
                  <h3>Don Bradman</h3>
                  <Link to="#">
                    <i className="material-icons">public</i>
                    <span>Public</span>
                  </Link>
                </div>
              </div>
              <div className="commnts_description">
                <textarea
                  className="form-control"
                  placeholder="Enter comments"
                ></textarea>
              </div>
              <div className="upload_imgbx">
                <input type="file" className="file_btn" />
                <div className="post_upload_innr">
                  <div className="post_icon">
                    <span className="material-icons-outlined">
                      {' '}
                      collections{' '}
                    </span>
                  </div>
                  <h4>Add photos</h4>
                  <h6>or drag and drop</h6>
                </div>
              </div>
              <div className="post_list">
                <div className="post_bttm_menu">
                  <ul className="list_stye_none">
                    <li className="active">
                      <Link to="#">
                        <i className="material-icons blue_icon">image</i>
                        <span>Photo </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons grn_icon">movie</i>
                        <span>Video </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons ylw_icon">
                          event_available
                        </i>
                        <span>Event </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons ornge_icon">work</i>
                        <span>Jobs </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="post_btn">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  data-bs-target="#gretings_mdl"
                >
                  Post Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPhotosModal;
