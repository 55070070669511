/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData, postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

import GroupDetailsRightPanel from "../GroupDetailsRightPanel/GroupDetailsRightPanel";
import GroupDetailsMiddlePanel from "../GroupDetailsMiddlePanel/GroupDetailsMiddlePanel";
import GroupDetailsLeftPanel from "../GroupDetailsLeftPanel/GroupDetailsLeftPanel";

import IndustryInterestedModal from "../Modals/IndustryInterestedModal";
import InterestAddedSuccessModal from "../Modals/InterestAddedSuccessModal";

import GroupDetailsSuccessModal from "../Modals/GroupDetailsSuccessModal";
import TagsModal from "components/common/Modals/TagsModal";
import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";
import GiveReviewModal from "components/common/Modals/GiveReviewModal";
import JoinSuccessModal from "../Modals/JoinSuccessModal";
import ReviewAddedSuccessModal from "components/common/Modals/ReviewAddedSuccessModal";

import { generateRandomString } from "utils/GetRandomString";
import ShareModal from "components/common/Modals/ShareModal";
import { assetImages } from "constants";

const GroupDetailsBody = () => {
  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const secretParam = searchParams.get("secret");

  const { t } = useTranslation(); // translation marker

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [secretCode, setSecretCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [groupMembers, setgroupMembers] = useState([]);
  const [groupTitle, setgroupTitle] = useState("");
  const [isModerator, setIsModerator] = useState(false);
  const [isJoined, setisJoined] = useState(false);
  const [isApproved, setisApproved] = useState(false);
  const [requestDate, setrequestDate] = useState("");
  const [approveDate, setApproveDate] = useState("");

  const [groupSummary, setgroupSummary] = useState("");
  const [groupDetails, setgroupDetails] = useState("");
  const [groupObjective, setgroupObjective] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [categoryData, setcategoryData] = useState([]);
  const [searchingData, setsearchingData] = useState([]);
  const [interestData, setinterestData] = useState([]);
  const [isPrivate, setisPrivate] = useState(false);
  const [tagList, settagList] = useState([]);

  const [isJoining, setIsJoining] = useState(false);

  const [memberListLoading, setMemberListLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const [isReviewLoading, setIsReviewLoading] = useState(false);

  const [ratingCount, setRatingCount] = useState("0.0");
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [galleryImages, setGalleryImages] = useState([]);

  const [similarList, setsimilarList] = useState([]);

  const [copyMessage, setCopyMessage] = useState("");

  const [totalGroupUser, settotalGroupUser] = useState("");
  const [totalGroupOpportunitySent, settotalGroupOpportunitySent] =
    useState("");
  const [groupTotalAmountOfSent, setgroupTotalAmountOfSent] = useState("");
  const [totalGroupopportunityRecived, settotalGroupopportunityRecived] =
    useState("");
  const [groupTotalAmountOfRecived, setgroupTotalAmountOfRecived] =
    useState("");
  const [groupOpportunityList, setgroupOpportunityList] = useState([]);

  //function for get group details
  const getgroupDetails = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of group details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of group details------>", response);

      setIsLoading(false);
      if (response.status) {
        if (
          userInfo?.role?.name == "admin" ||
          userInfo._id.toString() == response.data?.moderator._id.toString()
        ) {
          setIsModerator(true);
        } else {
          setIsModerator(false);
        }

        setSecretCode(response.data.secret);

        //assign title
        response.data?.title && setgroupTitle(response.data?.title);

        //assing summary
        setgroupSummary(response.data?.summary);

        //assign details
        setgroupDetails(response.data?.description);

        //assign objective
        setgroupObjective(response.data?.objective);

        //assign city
        setcityName(response.data?.city);

        //assign state
        setstateName(response.data?.state);

        //assign country
        setcountry(response.data?.country);

        //assign category
        if (response.data?.categories && response.data?.categories.length > 0) {
          setcategoryData(response.data?.categories);
        }

        //assign searchings
        if (response.data?.searchings && response.data?.searchings.length > 0) {
          setsearchingData(response.data?.searchings);
        }

        //assign interests
        if (response.data?.interests && response.data?.interests.length > 0) {
          setinterestData(response.data?.interests);
        }

        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );

        //assign private
        setisPrivate(response?.data?.isprivate);

        //assign tags
        settagList(response.data.taglist);

        //assign is joined
        setisJoined(response.data?.isjoined);

        //assign is approved
        setisApproved(response.data?.isapproved);

        //assign request date
        setrequestDate(response.data?.requestdate);

        //assign approve date
        setApproveDate(response.data?.approvedate);

        //assign members
        setgroupMembers(response.data.groupmembers);

        //gallery images
        setGalleryImages(
          response.data?.gallery.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for join event
  const joinGroupHandler = async () => {
    try {
      setIsJoining(true);
      let groupData = {
        group: params.id,
        useremail: userInfo.email,
        secret: secretParam ? secretParam : secretCode,
      };

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_GROUP + `?token=${token}`;

      const response = await postData(requestURL, groupData);

      setIsJoining(false);
      console.log(response);
      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("join_success_mdl")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    setIsReviewLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=group&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log("review list response", response);

      setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount("");
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllGroupMembers = async () => {
    try {
      setMemberListLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_GROUP_MEMBER +
        `?token=${token}&group=${params.id}&isallparticipants=${true}`;

      const response = await getData(requestUrl);

      console.log(response);
      setMemberListLoading(false);
      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all lead records
  const getAllGroups = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_GROUPS + `?token=${token}`;

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in group list------->", response);

      setIsLoading(false);
      if (response.status) {
        const filteredResults = response.data.filter(
          (group) => group._id.toString() !== params.id.toString()
        );

        setsimilarList(filteredResults.slice(0, 5));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all group opportunties
  const getAllGroupOpportunities = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GROUP_OPPORTUNITIES +
        `?token=${token}&group=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settotalGroupUser(response.data.totalgroupuser);
        settotalGroupOpportunitySent(response.data.totalgroupopportunitysent);
        setgroupTotalAmountOfSent(response.data.grouptotalamountofsent);
        settotalGroupopportunityRecived(
          response.data.totalgroupopportunityrecived
        );
        setgroupTotalAmountOfRecived(response.data.grouptotalamountofrecived);
        setgroupOpportunityList(response.data.opportunitiesofgroup);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //share function
  const shareHandler = async () => {
    const baseURL = `${parsedURL.protocol}//${parsedURL.host}`;

    const secretString = generateRandomString(4); // Change 10 to the desired length

    try {
      let eventData = {
        secret: secretString,
      };
      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_GROUP +
        `/${params.id}` +
        `?token=${token}`;

      const response = await putData(requestUrl, eventData);

      if (response.status) {
        const redirectUrl = baseURL + `?ref=${fullURL}&secret=${secretString}`;

        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("share_mdl")
        );
        bootstrapModal.show();

        setCopyMessage(redirectUrl);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterApiCall = () => {
    getgroupDetails();
    getAllGroupMembers();
    getReviewsLists();
    getAllGroups();
  };

  useEffect(() => {
    if (params.id) {
      getgroupDetails();
      getAllGroupMembers();
      getReviewsLists();
      getAllGroups();
      getAllGroupOpportunities();
    }
  }, [params.id]);

  // useEffect(() => {
  //   const modalTarget = document.querySelector('#esg_modal');
  //   const modal = new bootstrap.Modal(modalTarget);
  //   modal.show();
  // }, []);

  useEffect(() => {
    if (secretParam) {
      // console.log(secretParam);
      joinGroupHandler();
    }
  }, [secretParam]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <section className="feed_pnltotal">
        {isLoading ? null : (
          <div className="container-fluid">
            <div className="back_btn">
              <Link to="/groups">
                <i className="material-icons-outlined">arrow_back</i>
                <span>{t("Back")}</span>
              </Link>
            </div>
            <div className="pnl_outer">
              {/* -------- left panel -------- */}
              <GroupDetailsLeftPanel
                groupTitle={groupTitle}
                isPrivate={isPrivate}
                cityName={cityName}
                stateName={stateName}
                country={country}
                tagList={tagList}
                groupMembers={groupMembers}
                isModerator={isModerator}
                isJoined={isJoined}
                isApproved={isApproved}
                requestDate={requestDate}
                approveDate={approveDate}
                joinGroupHandler={joinGroupHandler}
                isJoining={isJoining}
                shareHandler={shareHandler}
              />

              {/* ------ mdddle panel ------- */}
              <GroupDetailsMiddlePanel
                groupSummary={groupSummary}
                groupDetails={groupDetails}
                groupObjective={groupObjective}
                categoryData={categoryData}
                interestData={interestData}
                searchingData={searchingData}
                uploadedDocFiles={uploadedDocFiles}
                memberList={memberList}
                ratingCount={ratingCount}
                reviewsList={reviewsList}
                ratingPercentArr={ratingPercentArr}
                galleryImages={galleryImages}
                groupTotalAmountOfSent={groupTotalAmountOfSent}
                groupTotalAmountOfRecived={groupTotalAmountOfRecived}
                totalGroupUser={totalGroupUser}
                totalGroupOpportunitySent={totalGroupOpportunitySent}
                totalGroupopportunityRecived={totalGroupopportunityRecived}
                groupOpportunityList={groupOpportunityList}
              />

              {/* ------ right panel ------- */}
              <GroupDetailsRightPanel similarList={similarList} />
            </div>
          </div>
        )}

        {/* -------- Industry interested modal -------- */}
        <IndustryInterestedModal />

        {/* ----------- Industry added success modal  ----------- */}
        <InterestAddedSuccessModal />

        {/* ------- feedback modal -------- */}
        <GiveReviewModal moduleName="group" />

        <ReviewAddedSuccessModal afterModalClose={afterApiCall} />

        {/* ------ success modal -------- */}
        {/* <GroupDetailsSuccessModal /> */}
        <JoinSuccessModal afterModalClose={afterApiCall} />

        <ShareModal copyMessage={copyMessage} />

        {/* --------- tags modal ----------- */}
        <TagsModal moduleName="groupdetails" selectedId={params.id} />

        {/* --------- tags added success / greetings modal ------------- */}
        <TagsAddedSuccessModal afterTagModalClose={getgroupDetails} />
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default GroupDetailsBody;
