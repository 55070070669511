import React from 'react';
import { Link } from 'react-router-dom';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const SurveyTab = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="service_outerbx">
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>CRM Expertise</h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined"> schedule </i>
            <span>2min 30sec</span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="survey_btn">
          <Link to="#" className="cmplete_btn">
            <i className="material-icons">check_circle</i>
            <span>{t('Completed')}</span>
          </Link>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>CRM Expertise</h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined"> schedule </i>
            <span>2min 30sec</span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="survey_btn">
          <Link to="#" className="start_outline_btn">
            <i className="material-icons">add</i>
            <span>{t('Start Now')}</span>
          </Link>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>CRM Expertise</h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined"> schedule </i>
            <span>2min 30sec</span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="survey_btn">
          <Link to="#" className="start_outline_btn">
            <i className="material-icons">add</i>
            <span>{t('Start Now')}</span>
          </Link>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>CRM Expertise</h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined"> schedule </i>
            <span>2min 30sec</span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
        <div className="survey_btn">
          <Link to="#" className="start_outline_btn">
            <i className="material-icons">add</i>
            <span>{t('Start Now')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SurveyTab;
