/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import AboutTab from "./Tabs/AboutTab";
import MemberTab from "./Tabs/MemberTab";
import ForumTab from "./Tabs/ForumTab";
import EventsTab from "./Tabs/EventTab";
import GalleryTab from "./Tabs/GalleryTab";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import ReviewsTab from "./Tabs/ReviewsTab";
import OpportunityTab from "./Tabs/OpportunityTab";
import { formatNumber } from "helper/Common/CommonHelper";

const GroupDetailsMiddlePanel = ({
  groupSummary,
  groupDetails,
  groupObjective,
  categoryData,
  interestData,
  searchingData,
  uploadedDocFiles,
  memberList,
  ratingCount,
  reviewsList,
  ratingPercentArr,
  galleryImages,
  groupTotalAmountOfSent,
  groupTotalAmountOfRecived,
  totalGroupUser,
  totalGroupOpportunitySent = 0,
  totalGroupopportunityRecived,
  groupOpportunityList,
}) => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="pnl_mdlpnl">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                {t("About")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#members">
                {t("Members")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#events">
                {t("Events")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#portfolio">
                {t("Gallery")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#forum">
                {t("Forum")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-link "
                data-bs-toggle="tab"
                to="#opportunities"
              >
                {t("Opportunities")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#reviews">
                {t("Review")}
              </Link>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="overview">
            <AboutTab
              groupSummary={groupSummary}
              groupDetails={groupDetails}
              groupObjective={groupObjective}
              categoryData={categoryData}
              interestData={interestData}
              searchingData={searchingData}
              uploadedDocFiles={uploadedDocFiles}
            />
          </div>

          <div className="tab-pane fade" id="portfolio">
            <GalleryTab galleryImages={galleryImages} />
          </div>

          <div className="tab-pane fade" id="members">
            <MemberTab memberList={memberList} />
          </div>

          <div className="tab-pane fade" id="forum">
            <ForumTab />
          </div>

          {/* <div className="tab-pane fade" id="survey"></div> */}

          <div className="tab-pane fade" id="events">
            <EventsTab />
          </div>

          <div className="tab-pane fade" id="opportunities">
            <div className="row gap-2 my-4">
              <div className="col p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h6 className="fw-semibold">Total User</h6>
                <p>{totalGroupUser}</p>
              </div>
              <div className="col p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h6 className="fw-semibold">Total Recomended</h6>
                <p>{totalGroupOpportunitySent}</p>
              </div>
              <div className="col p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h6 className="fw-semibold">Total Amount</h6>
                <p>{formatNumber(groupTotalAmountOfSent)} €</p>
              </div>
              {/* <div className="col p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h6 className="fw-semibold">Recomended By</h6>
                <p>{totalGroupopportunityRecived}</p>
              </div>
              <div className="col p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h6 className="fw-semibold">Recomended Amount By</h6>
                <p>{groupTotalAmountOfRecived}</p>
              </div> */}
            </div>
            <OpportunityTab groupOpportunityList={groupOpportunityList} />
          </div>

          <div className="tab-pane fade" id="reviews">
            <ReviewsTab
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsMiddlePanel;
