import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const SustainabilityBenifits = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="benefits_area pd_130">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t('Our benefits')}</h5>
          <h2>
            {t('Things you Will')} <br /> {t('get from Us')}
          </h2>
        </div>
        <div className="help_outer benefits_outer">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">
                    space_dashboard
                  </span>
                </div>
                <div className="help_text">
                  <h3>{t('Exclusive Directory')}</h3>
                  <p>
                    {t(
                      'Showcase your business in our premium ESG directory and gain visibility among committed companies and customers'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">
                    calendar_month
                  </span>
                </div>
                <div className="help_text">
                  <h3>{t('Networking Events')}</h3>
                  <p>
                    {t(
                      'Participate in our exclusive virtual networking events. Connect with suppliers, clients, and partners aligned with your values'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">campaign</span>
                </div>
                <div className="help_text">
                  <h3>{t('Social Media Exposure')}</h3>
                  <p>
                    {t(
                      'Amplify your successes with our powerful social media marketing strategy. Let your success story echo throughout the community'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">badge</span>
                </div>
                <div className="help_text">
                  <h3>{t('Radio Show Invitation')}</h3>
                  <p>
                    {t(
                      'Share your story, challenges, and accomplishments on our radio show dedicated to ESG leaders like you'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">widgets</span>
                </div>
                <div className="help_text">
                  <h3>{t('Access to Digital Tools')}</h3>
                  <p>
                    {t(
                      'Use exclusive tools and apps designed to assist businesses in assessing and enhancing their ESG practices'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">school</span>
                </div>
                <div className="help_text">
                  <h3>{t('Continuous Training')}</h3>
                  <p>
                    {t(
                      'Access webinars, workshops, and courses on ESG trends, sustainability tools, and best practices'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">query_stats</span>
                </div>
                <div className="help_text">
                  <h3>{t('Analysis and Reports')}</h3>
                  <p>
                    {t(
                      'Receive personalized reports on your ESG performance compared to market trends and industry best practices'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">
                    confirmation_number
                  </span>
                </div>
                <div className="help_text">
                  <h3>{t('Promotions and Discounts')}</h3>
                  <p>
                    {t(
                      'Benefit from exclusive deals from other ecosystem members, thus strengthening the circular economy and mutual support amongst committed businesses'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">menu_book</span>
                </div>
                <div className="help_text">
                  <h3>{t('Op-Ed Space')}</h3>
                  <p>
                    {t(
                      'Have the opportunity to publish articles on our blog, positioning your company as an ESG opinion leader.'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">hub</span>
                </div>
                <div className="help_text">
                  <h3>{t('Integration with Other Platforms')}</h3>
                  <p>
                    {t(
                      'We facilitate connections with other digital platforms and tools to broaden the reach and integration of your ESG initiatives.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityBenifits;
