import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const SustainabilityBanner = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section
      className="banner_area sucess_share_bnr"
      style={{ backgroundImage: `url(${assetImages.storiesShareImg})` }}
    >
      <div className="container">
        <div className="banner_outer">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h1>
                  {t("LOBEES: Where")} <br />
                  {t("Success Meet")} <br />
                  {t("Sustainability")}{" "}
                </h1>
                <p>
                  {t(
                    "Join our platform and showcase your commitment to ESG (Environmental, Social, and Governance) goals."
                  )}
                </p>
                <div className="bnr_btns">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#register_mdl"
                  >
                    {t("Get Started")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityBanner;
