import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BlogPlaceHolderCard from "./BlogPlaceHolderCard";

//custom arrow button for react carousel outside
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="inBtns news_arws">
      <span
        className="placeholder rounded-circle"
        style={{ width: "60px", height: "60px" }}
      ></span>
      <span
        className="placeholder rounded-circle"
        style={{ width: "60px", height: "60px" }}
      ></span>
    </div>
  );
};

const MoreNewsPlaceHolder = () => {
  const cardGlowCount = [1, 2, 3];
  //custom property for responsive
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container">
      <div className="page_hdng" style={{ width: "50%" }}>
        <h2 style={{ width: "100%" }}>
          <span
            className="placeholder"
            style={{ width: "40%", height: "20px" }}
          ></span>
        </h2>
      </div>

      <div className="news_slide_outer position-relative">
        <div className="news_slider">
          <Carousel
            responsive={responsive}
            infinite
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {cardGlowCount.map((blog, index) => {
              return (
                <div className="news_slideItem" key={index}>
                  <BlogPlaceHolderCard />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default MoreNewsPlaceHolder;
