/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import * as url from "helper/UrlHelper";

const GroupDetailsLeftPanel = ({
  groupTitle,
  isPrivate,
  cityName,
  stateName,
  country,
  tagList,
  groupMembers,
  isModerator,
  isJoined,
  isApproved,
  requestDate,
  approveDate,
  joinGroupHandler,
  isJoining,
  shareHandler,
}) => {
  const { t } = useTranslation(); // translation marker

  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="pnl_leftpnl">
      <span className="cross_btn material-icons-outlined">close</span>
      <div className="white_shadow_bx people_leftusr">
        <div className="groups_info">
          <div className="top_grp_info">
            <div className="experts_holdertext">
              <h3>{groupTitle}</h3>
              <h5>
                {isPrivate ? t("Private") : t("Public")} Group
                {/* <span>2nd</span> */}
              </h5>
            </div>
            <div className="share_btn">
              <Link to="#" onClick={shareHandler}>
                <span className="material-icons">share</span>
              </Link>
            </div>
          </div>
          <div className="address">
            <i className="material-icons-outlined">place</i>
            <span>
              {cityName},{stateName} {country}
            </span>
          </div>
          {/* <div className="expert_social">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <img src="/images/fb.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src="/images/gmail.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src="/images/linkedin.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src="/images/pinterest.svg" alt="" />
                </Link>
              </li>
            </ul>
          </div> */}

          {/* event member start */}
          <div className="event_user grps_user">
            <ul className="list_stye_none">
              {groupMembers.slice(0, memberLimit).map((member, index) => {
                return (
                  <li key={index}>
                    <Link to="#">
                      <img
                        className="rounded-circle"
                        height={40}
                        width={40}
                        src={
                          member.userimage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + member.userimage
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                );
              })}
              <li>
                {groupMembers.length > memberLimit ? (
                  <Link to="#">+{groupMembers.length - memberLimit} more</Link>
                ) : null}
              </li>
            </ul>
          </div>
          {/* event member end */}

          {/* join and other button */}
          <div className="feedback_jointbtnOuter">
            {isModerator ? null : (
              <div className="details_apply">
                {isApproved ? (
                  <Link to="#" className="btn gray_btn accepted_btn">
                    <i className="material-icons-outlined"> check_circle </i>
                    <span>{t("Joined")}</span>
                  </Link>
                ) : isJoined && !isApproved ? (
                  <Link to="#" className="btn gray_btn requested_btn disabled">
                    <i className="material-icons-outlined"> pending </i>
                    <span>{t("Requested")}</span>
                  </Link>
                ) : (
                  <button
                    className="btn outline"
                    onClick={joinGroupHandler}
                    disabled={isJoining ? true : false}
                    style={{
                      cursor: isJoining ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Join Now")}
                  </button>
                )}
              </div>
            )}

            {isApproved || isModerator ? (
              <div className="feed_btn">
                <Link
                  to="#"
                  className="btn gray_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  {t("Give Review")}
                </Link>
              </div>
            ) : null}

            <div className="tag_btn">
              <Link
                to="#"
                className="btn gray_btn"
                data-bs-target="#tags_modal"
                data-bs-toggle="modal"
              >
                <i className="material-icons-outlined">more</i>
              </Link>
            </div>
          </div>

          {isModerator ? null : (
            <div className="connected_text">
              {isApproved ? (
                <p className="d-flex align-items-center justify-content-start">
                  <i className="material-icons">check_circle</i>
                  <span>
                    {t("Joined on")} {approveDate}
                  </span>
                </p>
              ) : isJoined ? (
                <p className="d-flex align-items-center justify-content-start">
                  <i className="material-icons text-danger">error</i>
                  <span>
                    {t("Request on")} {requestDate}
                  </span>
                </p>
              ) : null}
            </div>
          )}

          <div className="tags_links mt-4 mb-2">
            <ul className="list_stye_none d-flex flex-wrap gap-2 ">
              {tagList.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <span>{item.title}</span>
                      {/* <i className="material-icons">close</i> */}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsLeftPanel;
