/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// import use translation ------------------------------
//import { useTranslation } from "react-i18next";

import Select from "react-select";

/*import url and gateway methods */
import { getData, postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import CustomerFeedbackOptions from "Data/CustomerCvFeedbackOptions.json";


import { ecosystemSlug } from "Config/Config";

const CustomerFeedbackRightPanel = () => {

        const params = useParams();
        const respId = params.id;

        const token = localStorage.getItem("token");


        const [customerFeedbacks, setCustomerFeedbacks] = useState([]);

        const [customerEmail, setCustomerEmail] = useState("");
        const [feedbackDate, setFeedbackDate] = useState(new Date().toISOString().split('T')[0]);
        const [feedbackValue, setFeedbackValue] = useState("");   
        const [feedbackOptionSelected, setFeedbackOptionSelected] = useState("");
        const [feedbackComments, setFeedbackComments] = useState("");

        // validation 
        const [isValid, setIsValid] = useState(true);

    
        //function for select subscribe
        const selectionHandler = (val) => {

            console.log('selection val',val);
            if (val) {
                setFeedbackOptionSelected(val);
                setFeedbackValue(val.value);
            } else {
                setFeedbackOptionSelected(null);
                setFeedbackValue("");
            }
        };

        // save feedback of customer 
        const sendFeedback = async() =>{

            if(customerEmail.length < 4 ||
                feedbackDate.length < 4 ||
                feedbackComments.length < 2 ||
                feedbackValue == ""
            ){
                setIsValid(false);
            } else {

                setIsValid(true);
                try {
                    let feedbackData = {
                        email: customerEmail,
                        feedbackdate: feedbackDate,
                        feedback: feedbackValue,
                        comments: feedbackComments
                    };

                    let requestUrl =
                        url.API_BASE_URL +
                        url.API_ADD_CHALLENGE_RESPONSE_CUSTOMER_FEEDBACK +
                        `/${respId}?token=${token}`;

                    const response = await postData(requestUrl, feedbackData);

                    console.log(response);

                    if (response.status) {
                        const bootstrapModal = new bootstrap.Modal(
                            document.getElementById("success_modal")
                        );
                        bootstrapModal.show();
                        resetHandler();
                        getCustomerFeedbacks();
                    }
                } catch (error) {
                    console.log(error.message);
                }
            }

        }


        // get feedbacks of customer
        const getCustomerFeedbacks = async () => {
            try {
            let requestUrl =
                url.API_BASE_URL +
                url.API_GET_CHALLENGE_RESPONSE_CUSTOMER_FEEDBACK +
                `/${respId}?token=${token}`;

                console.log('req url', requestUrl);

                const response = await getData(requestUrl);

                console.log("response of customer feedback", response);

                if (response.status) {

                    const customerFeedbacks = response.data.map((feedback) => {
                        const option = CustomerFeedbackOptions.find(
                        (option) => option.value === feedback.rating
                        );
                        return {
                        ...feedback,
                        feedbackLabel: option? option.label : "Unknown",
                        };
                    });
                    setCustomerFeedbacks(customerFeedbacks);
                }

            } catch (error) {
            console.log(error.message);

            }
        };

        const resetHandler = () => {
            setCustomerEmail('');
            setFeedbackDate('');
            setFeedbackOptionSelected('');
            setFeedbackComments('');
            setFeedbackValue('');
            setIsValid(true);
        }

        useEffect(()=>{
            getCustomerFeedbacks();
        }, [])

        return (

     
            <div className="col-lg-4">
                <div className="builder_lrightpnl">

                    {/* Feedback form */}
                    <div className="px-3 py-4 white_shadow_bx mb-4">
                        <h3 className="fs-lg text-black fw-semibold mb-4">Response Feedback</h3>
                     
                            <div className="form_innr">

                                <div className="form-group">
                                    <label>* Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={customerEmail}
                                        onChange={(e) => setCustomerEmail(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>* Date</label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        placeholder="Date of feedback"
                                        value={feedbackDate}
                                        onChange={(e) => setFeedbackDate(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label> * Select a Feedback Options</label>
                                    <Select
                                        placeholder="select a option"
                                        isClearable
                                        options={CustomerFeedbackOptions}
                                        value={feedbackOptionSelected}
                                        onChange={(val) => {
                                            selectionHandler(val);
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>* Add Comments</label>
                                    <textarea 
                                        className="form-control" 
                                        placeholder="Enter Comments"
                                        value={feedbackComments}
                                        onChange={(e) => setFeedbackComments(e.target.value)}
                                    />
                                </div>
                                <p className={`text-danger ${isValid? 'd-none' : ''}`}> * Please fill all required fields. </p>
                                <div className="form-group">
                                    <button className="btn btn-primary w-100"
                                        onClick={sendFeedback}
                                        >Send</button>
                                </div>

                            </div>
                       
                    </div>

                    {/* Previous Feedback responses */}
                    <div className="white_shadow_bx previous_cvbx py-4 px-3">
                       
                        <div className="feed_history pt-3 mt-3">
                                <h3 className="fs-lg text-black fw-semibold mb-4">Feedback History</h3>
                                
                                {customerFeedbacks && customerFeedbacks.map((feedback, index)=>{
                                    return(
                                        <div key={index} className="mb-3">        
                                            <div className="bg-red rounded-90 px-3 py-2 fs-md text-white mb-3 d-inline-block">
                                                Sent for feedback {feedback.customdate}
                                            </div>
                                            <div className="validate_info d-flex align-items-center gap-2 mb-3">

                                                <i className="material-icons text-green">check_circle</i>
                                                <span className="d-inline-block fs-md text-black">{feedback.feedbackLabel}</span>
                                            </div>
                                            <div className="status d-inline-block bg-light rounded-90 fs-md px-3 py-2 text-black-gray">
                                                {feedback.details}

                                                
                                            </div>
                                            <div className="ms-3 mt-1">
                                                By:  <i>{feedback.email}</i>
                                            </div>
                                        </div>
                                    )                                   

                                })}
                                
                        </div>
                    </div>


                </div>
            </div>
                        
            
        );

};

export default CustomerFeedbackRightPanel;
