import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const Conversation = ({ source, autoScollContactNow, setSourceName }) => {
  const { t } = useTranslation(); // translation marker

  const contactNowHandler = () => {
    autoScollContactNow();
    setSourceName(`${source} : Lets talk`);
  };

  return (
    <section
      className="blue_ecosystem_sec pd_130"
      style={{ backgroundImage: `url(${assetImages.dotBlueBg})` }}
    >
      <div className="container">
        <div className="page_hdng white_hdng text-center">
          <h2>{t("You're in the right place")}</h2>
          <h5>
            {t(
              "If you are interested in working with good IT professionals and participating in a collaborative GUARANTEE Ecosystem."
            )}
          </h5>
        </div>
        <div className="system_btn text-center">
          <Link to="#" className="btn" onClick={contactNowHandler}>
            {t("Lets Talk")}
          </Link>
        </div>
        <div className="connect_link">
          <p>
            {" "}
            {t("or send an email to")} <Link to="#">info@lobees.com</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Conversation;
