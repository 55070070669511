import React from "react";
import { Link } from "react-router-dom";

const BlogPlaceHolderCard = () => {
  return (
    <div className="blogs_item">
      <div className="blog_pic">
        <span
          className="placeholder"
          style={{
            width: "100%",
            height: "300px",
            borderRadius: "20px",
            marginBottom: "16px",
          }}
        ></span>
      </div>
      <div className="blog_content">
        <h5>
          {" "}
          <span className="placeholder" style={{ width: "20%" }}></span>
        </h5>
        <Link to="#" style={{ display: "block" }}>
          <span className="placeholder" style={{ width: "100%" }}></span>
          <span className="placeholder" style={{ width: "60%" }}></span>
        </Link>
        <p>
          <span className="placeholder" style={{ width: "100%" }}></span>
        </p>
        <div className="bttm_step_cloude">
          <span
            className="placeholder rounded-circle"
            style={{
              height: "50px",
              width: " 50px",
              minWidth: "50px",
            }}
          ></span>
          <div className="cloud_icon_text" style={{ width: "100%" }}>
            <h4>
              <i className="placeholder" style={{ width: "20%" }}></i>
            </h4>
            <h6>
              <i className="placeholder" style={{ width: "40%" }}></i>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPlaceHolderCard;
