import React from "react";
import { Link } from "react-router-dom";

const PostQuestionModal = () => {
  return (
    <div className="post_modal">
      <div className="modal fade" id="job_question_mdl">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Create Post
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_left">
                <div className="pofile_pic">
                  <img src="/images/profile-pic5.png" alt="" />
                </div>
                <div className="pofile_info">
                  <h3>Don Bradman</h3>
                  <Link to="#">
                    <i className="material-icons">public</i>
                    <span>Public</span>
                  </Link>
                </div>
              </div>
              <div className="job_modaldil_total">
                <div className="input_fill jobinput_fill">
                  <label>Question</label>
                  <input
                    type="text"
                    // value=""
                    className="form-control"
                    placeholder="What do you want to ask"
                  />
                </div>
                <div className="add_question">
                  <Link to="#">
                    <i className="material-icons-outlined">add</i>
                    <span>Add Question</span>
                  </Link>
                </div>
              </div>

              <div className="post_btn">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  data-bs-target="#gretings_mdl"
                >
                  Post Job
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostQuestionModal;
