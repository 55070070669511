import React from "react";
import { Link } from "react-router-dom";

import { fileDownloadHandler } from "utils/DownloadFile";

const OverviewTab = ({
  companyDetails,
  companyServices,
  companySearchings,
  companyInterests,
  companyDocs,
}) => {
  return (
    <div className="people_detailspnl">
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>Details</h4>
        </div>
        <div className="results_para">
          <p dangerouslySetInnerHTML={{ __html: companyDetails }}></p>
        </div>
      </div>
      <div className="people_details_bx white_shadow_bx">
        <div className="accordian_area">
          <div className="accordion accordion-flush" id="faq-accordian">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                >
                  <span>Services</span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {companyServices.map((service, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{service.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                >
                  <span>Searchings</span>
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {companySearchings.map((searching, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{searching.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                >
                  <span>Interest</span>
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {companyInterests.map((interest, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{interest.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>Docs</h4>
        </div>
        {/* <div className="upload_area">
          {companyDocs.map((doc, index) => {
            return (
              <div className="upload_item" key={index}>
                <div className="uploaded_innrBx">
                  <div className="upload_icon">
                    <img src="/images/pdf.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>{doc.name}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

        <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
          {companyDocs.map((file, index) => {
            return (
              <li
                className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                key={index}
              >
                <p className="fw-medium">{file.name}</p>
                <Link to="#" onClick={() => fileDownloadHandler(file)}>
                  <span className="d-block material-icons-outlined">
                    download
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OverviewTab;
