import React from 'react';

const ProductTab = () => {
  return (
    <div className="product_innr">
      <div className="product_row white_shadow_bx">
        <div className="product_pic">
          <img src="/images/car.png" alt="" />
        </div>
        <div className="product_info">
          <h3>Polo Collar T-Shirt for Plus Size Men</h3>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  4.5 <em>(32)</em>
                </span>
              </li>
            </ul>
          </div>
          <div className="product_list">
            <ul className="list_stye_none">
              <li>
                <span>Rank</span>
                <b>1000</b>
              </li>
              <li>
                <span>Comentarios</span>
                <b>0</b>
              </li>
              <li>
                <span>Diesel</span>
              </li>
              <li>
                <span>Automatico</span>
              </li>
              <li>
                <span>258 CV</span>
              </li>
            </ul>
          </div>
          <div className="product_price">
            <h3>€135,990</h3>
          </div>
        </div>
      </div>
      <div className="product_row white_shadow_bx">
        <div className="product_pic">
          <img src="/images/car.png" alt="" />
        </div>
        <div className="product_info">
          <h3>Polo Collar T-Shirt for Plus Size Men</h3>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  4.5 <em>(32)</em>
                </span>
              </li>
            </ul>
          </div>
          <div className="product_list">
            <ul className="list_stye_none">
              <li>
                <span>Rank</span>
                <b>1000</b>
              </li>
              <li>
                <span>Comentarios</span>
                <b>0</b>
              </li>
              <li>
                <span>Diesel</span>
              </li>
              <li>
                <span>Automatico</span>
              </li>
              <li>
                <span>258 CV</span>
              </li>
            </ul>
          </div>
          <div className="product_price">
            <h3>€135,990</h3>
          </div>
        </div>
      </div>
      <div className="product_row white_shadow_bx">
        <div className="product_pic">
          <img src="/images/car.png" alt="" />
        </div>
        <div className="product_info">
          <h3>Polo Collar T-Shirt for Plus Size Men</h3>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  4.5 <em>(32)</em>
                </span>
              </li>
            </ul>
          </div>
          <div className="product_list">
            <ul className="list_stye_none">
              <li>
                <span>Rank</span>
                <b>1000</b>
              </li>
              <li>
                <span>Comentarios</span>
                <b>0</b>
              </li>
              <li>
                <span>Diesel</span>
              </li>
              <li>
                <span>Automatico</span>
              </li>
              <li>
                <span>258 CV</span>
              </li>
            </ul>
          </div>
          <div className="product_price">
            <h3>€135,990</h3>
          </div>
        </div>
      </div>
      <div className="product_row white_shadow_bx">
        <div className="product_pic">
          <img src="/images/car.png" alt="" />
        </div>
        <div className="product_info">
          <h3>Polo Collar T-Shirt for Plus Size Men</h3>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  4.5 <em>(32)</em>
                </span>
              </li>
            </ul>
          </div>
          <div className="product_list">
            <ul className="list_stye_none">
              <li>
                <span>Rank</span>
                <b>1000</b>
              </li>
              <li>
                <span>Comentarios</span>
                <b>0</b>
              </li>
              <li>
                <span>Diesel</span>
              </li>
              <li>
                <span>Automatico</span>
              </li>
              <li>
                <span>258 CV</span>
              </li>
            </ul>
          </div>
          <div className="product_price">
            <h3>€135,990</h3>
          </div>
        </div>
      </div>
      <div className="product_row white_shadow_bx">
        <div className="product_pic">
          <img src="/images/car.png" alt="" />
        </div>
        <div className="product_info">
          <h3>Polo Collar T-Shirt for Plus Size Men</h3>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  4.5 <em>(32)</em>
                </span>
              </li>
            </ul>
          </div>
          <div className="product_list">
            <ul className="list_stye_none">
              <li>
                <span>Rank</span>
                <b>1000</b>
              </li>
              <li>
                <span>Comentarios</span>
                <b>0</b>
              </li>
              <li>
                <span>Diesel</span>
              </li>
              <li>
                <span>Automatico</span>
              </li>
              <li>
                <span>258 CV</span>
              </li>
            </ul>
          </div>
          <div className="product_price">
            <h3>€135,990</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTab;
