import React from 'react';
import { Link } from 'react-router-dom';

const ESGReportTab = () => {
  return (
    <div className="esg_outer">
      <div className="esg_items d-flex align-items-center justify-content-between gap-2 ">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2023</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/10.svg" alt="" />
            </span>
            <span>
              <img src="/images/11.svg" alt="" />
            </span>
            <span>
              <img src="/images/14.svg" alt="" />
            </span>
            <span>
              <img src="/images/15.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
      <div className="esg_items d-flex align-items-center justify-content-between gap-2">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2022</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with all that sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/2.svg" alt="" />
            </span>
            <span>
              <img src="/images/3.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
      <div className="esg_items d-flex align-items-center justify-content-between gap-2">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2021</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with all that sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/10.svg" alt="" />
            </span>
            <span>
              <img src="/images/14.svg" alt="" />
            </span>
            <span>
              <img src="/images/15.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
      <div className="esg_items d-flex align-items-center justify-content-between gap-2">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2020</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/2.svg" alt="" />
            </span>
            <span>
              <img src="/images/3.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
      <div className="esg_items d-flex align-items-center justify-content-between gap-2">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2019</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with all that sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/10.svg" alt="" />
            </span>
            <span>
              <img src="/images/11.svg" alt="" />
            </span>
            <span>
              <img src="/images/14.svg" alt="" />
            </span>
            <span>
              <img src="/images/15.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
      <div className="esg_items d-flex align-items-center justify-content-between gap-2">
        <div className="esg_leftpnl">
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>Year: 2018</span>
                </Link>
              </li>
            </ul>
          </div>
          <h3>Annual ESG report with all that sustainable goals</h3>
        </div>
        <div className="esg_rightpnl d-flex align-items-center gap-4">
          <div className="ods_icons d-flex align-items-center gap-1">
            <span>
              <img src="/images/10.svg" alt="" />
            </span>
            <span>
              <img src="/images/11.svg" alt="" />
            </span>
            <span>
              <img src="/images/14.svg" alt="" />
            </span>
            <span>
              <img src="/images/15.svg" alt="" />
            </span>
          </div>
          <div className="esg_btn">
            <Link
              to="#"
              className="btn outline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESGReportTab;
