import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { assetImages } from "constants";

const SuggestProduct = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link href="#" 
                className="ai_product_row d-flex gap-2" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#marketplace">
            <div className="ai_procudt_pic">
                <img src="images/mobile-pic1.png" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>OnePlus Nord 2T 5G</h3>
                <p>8GB, 240GB, OLED display</p>
                <h4>Rs. 28,377</h4>
            </div>
        </Link>

        <Link href="#" 
                className="ai_product_row d-flex gap-2" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#marketplace">
            <div className="ai_procudt_pic">
                <img src="images/mobile-pic1.png" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>OnePlus Nord 2T 5G</h3>
                <p>8GB, 240GB, OLED display</p>
                <h4>Rs. 28,377</h4>
            </div>
        </Link>

        

    
        <div><Link to="#" className="btn outline" >View more items?</Link></div>

    </div>

    
  );
};

export default SuggestProduct;
