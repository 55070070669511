import React from 'react';
import AuthMenuPanel from 'components/common/AuthMenuPanel';
import AuthUserBox from 'components/common/AuthUserBox';

const FeedLandingLeftPanel = () => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <AuthMenuPanel />
    </div>
  );
};

export default FeedLandingLeftPanel;
