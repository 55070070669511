import React from "react";
import { Link } from "react-router-dom";

const PostJobModal = () => {
  return (
    <div className="post_modal">
      <div className="modal fade" id="create_post_job_mdl">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Create Post
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_left">
                <div className="pofile_pic">
                  <img src="/images/profile-pic5.png" alt="" />
                </div>
                <div className="pofile_info">
                  <h3>Don Bradman</h3>
                  <Link to="#">
                    <i className="material-icons">public</i>
                    <span>Public</span>
                  </Link>
                </div>
              </div>
              <div className="job_modaldil_total">
                <div className="input_fill jobinput_fill">
                  <label>Job Title</label>
                  <input
                    type="text"
                    // value=""
                    className="form-control"
                    placeholder="Add the title you are hiring for"
                  />
                </div>
                <div className="input_fill jobinput_fill">
                  <label>Company</label>
                  <input
                    type="text"
                    // value=""
                    className="form-control"
                    placeholder="Enter company name"
                  />
                </div>
                <div className="job_fill_Outer">
                  <div className="input_fill jobinput_fill">
                    <label>Workplace Type</label>
                    <div className="job_item">
                      <select className="nosearch_select">
                        <option>On-site</option>
                        <option>Off-site</option>
                      </select>
                    </div>
                  </div>
                  <div className="input_fill jobinput_fill">
                    <label>Job Type</label>
                    <div className="job_item">
                      <select className="nosearch_select">
                        <option>Full-time</option>
                        <option>Part-time</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input_fill jobinput_fill">
                  <label>Job Location</label>
                  <input
                    type="text"
                    // value=""
                    className="form-control"
                    placeholder="Enter company name"
                  />
                </div>
              </div>

              <div className="post_list">
                <div className="post_bttm_menu">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">
                        <i className="material-icons blue_icon">image</i>
                        <span>Photo </span>
                      </Link>
                    </li>
                    <li className="">
                      <Link to="#">
                        <i className="material-icons grn_icon">movie</i>
                        <span>Video </span>
                      </Link>
                    </li>
                    <li className="">
                      <Link to="#">
                        <i className="material-icons ylw_icon">
                          event_available
                        </i>
                        <span>Event </span>
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="#">
                        <i className="material-icons ornge_icon">work</i>
                        <span>Jobs </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="post_btn">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  data-bs-target="#create_job_commnts_mdl"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostJobModal;
