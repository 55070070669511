import React from 'react';

const PortfolioTab = () => {
  return (
    <div className="portfolio_outer">
      <div className="row">
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio1.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio2.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio3.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio4.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio5.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio6.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio7.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio8.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio9.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio10.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio11.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="portfolio_pic">
            <img src="/images/portfolio12.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTab;
