/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const SentTab = ({ inviteList }) => {
  return (
    <div className="notification_inner">
      <div className="post_item">
        {inviteList.map((inviteData, index) => {
          return (
            <div className="white_shadow_bx people_bx" key={index}>
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img
                      src={
                        inviteData.memberimage == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + inviteData.memberimage
                      }
                      alt=""
                    />
                  </div>
                  <div className="people_profile_info">
                    <h3>{inviteData.membername}</h3>
                    <h5>{inviteData.memberposition}</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            {inviteData.memberratings}{" "}
                            <em>({inviteData.memberreviews})</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  {inviteData.memberstatus == "1" ? (
                    <Link to="#" className="btn gray_btn accepted_btn">
                      <i className="material-icons"> check_circle </i>
                      <span>Accepted</span>
                    </Link>
                  ) : (
                    <Link to="#" className="btn gray_btn rejected_btn">
                      <i className="material-icons">error_outline</i>
                      <span>Pending</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {/* <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people3.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Nasino Yangchang</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn gray_btn rejected_btn">
                <i className="material-icons">error_outline</i>
                <span>Pending</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Pierre Gagnaire</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn gray_btn accepted_btn">
                <i className="material-icons"> check_circle </i>
                <span>Accepted</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn gray_btn accepted_btn">
                <i className="material-icons"> check_circle </i>
                <span>Accepted</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people2.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Clare MacLearn</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn gray_btn rejected_btn">
                <i className="material-icons"> cancel </i>
                <span>Rejected</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="white_shadow_bx people_bx">
          <div className="people_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people3.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Nasino Yangchang</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right_people_btn">
              <Link to="#" className="btn gray_btn accepted_btn">
                <i className="material-icons"> check_circle </i>
                <span>Accepted</span>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SentTab;
