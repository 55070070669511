/* eslint-disable */

import { Link } from "react-router-dom";
// import use translation
import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

const EventDetailsLeftPanel = ({
  eventTitle,
  eventDate,
  eventCity,
  eventCountry,
  eventMembers,
  isModerator,
  isJoined,
  isApproved,
  requestDate,
  approveDate,
  eventTags,
  joinEventHandler,
  ratingCount,
  reviewsList,
  isJoining,
  shareHandler,
}) => {
  const { t } = useTranslation(); // translation marker
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="pnl_leftpnl">
      <div className="white_shadow_bx people_leftusr">
        <div className="groups_info">
          <div className="top_grp_info">
            <div className="experts_holdertext">
              <h3>{eventTitle}</h3>
              <div className="event_hdng">
                <h5>
                  <i className="material-icons-outlined"> event_note </i>
                  <em>{eventDate}</em>
                </h5>
              </div>
            </div>
            <div className="share_btn">
              <Link to="#" onClick={shareHandler}>
                <span className="material-icons">share</span>
              </Link>
            </div>
          </div>

          {/* location and rating start */}
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  {ratingCount} <em>({reviewsList.length})</em>
                </span>
              </li>
              <li>
                <i className="material-icons-outlined">place</i>
                <span>
                  {eventCity}, {eventCountry}
                </span>
              </li>
            </ul>
          </div>
          {/* location and rating end */}

          {/* event member start */}
          <div className="event_user grps_user">
            <ul className="list_stye_none">
              {eventMembers.slice(0, memberLimit).map((member, index) => {
                return (
                  <li key={index}>
                    <Link to="#">
                      <img
                        className="rounded-circle"
                        src={
                          member.userimage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + member.userimage
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                );
              })}
              <li>
                {eventMembers.length > memberLimit ? (
                  <Link to="#">+{eventMembers.length - memberLimit} more</Link>
                ) : null}
              </li>
            </ul>
          </div>
          {/* event member end */}

          <div className="feedback_jointbtnOuter">
            {/* join button start */}
            {isModerator ? null : (
              <div className="feed_btn">
                {isApproved ? (
                  <Link to="#" className="btn gray_btn accepted_btn">
                    <i className="material-icons-outlined"> check_circle </i>
                    <span>{t("Joined")}</span>
                  </Link>
                ) : isJoined ? null : (
                  <button
                    className="btn outline"
                    onClick={joinEventHandler}
                    disabled={isJoining ? true : false}
                    style={{
                      cursor: isJoining ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Join Now")}
                    {isJoining && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
              </div>
            )}
            {/* join button end */}

            {/* review button start */}
            {isModerator || isApproved ? (
              <div className="feed_btn">
                <Link
                  to="#"
                  className="btn gray_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  {t("Give Review")}
                </Link>
              </div>
            ) : null}
            {/* review button end */}

            {/* add tag button start */}
            <div className="tag_btn">
              <Link
                data-bs-target="#tags_modal"
                data-bs-toggle="modal"
                to="#"
                className="btn gray_btn"
              >
                <i className="material-icons-outlined">more</i>
              </Link>
            </div>
            {/* add tag button end */}
          </div>

          {/* <div className="feedback_jointbtnOuter mt-4">
            <div className="details_apply">
              <Link to="#" className="btn gray_btn accepted_btn">
                <i className="material-icons-outlined"> check_circle </i>
                <span>{t("Joined")}</span>
              </Link>
            </div>
          </div> */}

          {isModerator ? null : (
            <div className="connected_text">
              {isApproved ? (
                <p className="d-flex align-items-center justify-content-start">
                  <i className="material-icons">check_circle</i>
                  <span>
                    {t("Joined on")} {approveDate}
                  </span>
                </p>
              ) : isJoined ? (
                <p className="d-flex align-items-center justify-content-start">
                  <i className="material-icons text-danger">error</i>
                  <span>
                    {t("Request on")} {requestDate}
                  </span>
                </p>
              ) : null}
            </div>
          )}

          <div className="tags_links mt-4 mb-2">
            <ul className="list_stye_none d-flex flex-wrap gap-2 ">
              {eventTags.map((tag, index) => {
                return (
                  <li key={index}>
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <span>{tag.title}</span>
                      {/* <i className="material-icons">close</i> */}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsLeftPanel;
