import React from "react";

const CompanyListPlaceHolder = () => {
  return (
    <div className="groups_item">
      <div className="top_company_row company_top">
        <div className="left_company">
          <div className="event_hdng">
            <h3>
              <span className="placeholder" style={{ width: "100px" }}></span>
            </h3>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              <span className="placeholder" style={{ width: "100px" }}></span>
              <span className="placeholder" style={{ width: "100px" }}></span>
            </ul>
          </div>
        </div>
        <div className="company_logo">
          <span className="placeholder" style={{ width: "100px" }}></span>
        </div>
      </div>
      <div className="event_para">
        <p>
          <span className="placeholder" style={{ width: "80%" }}></span>
        </p>
      </div>
      <div className="company_info_outer">
        <div className="company_info_row">
          <label>
            <span
              className="placeholder"
              style={{ width: "80px", marginRight: "15px" }}
            ></span>
          </label>
          <div className="event_tags">
            <ul className="list_stye_none">
              <span className="placeholder" style={{ width: "70px" }}></span>
              <span className="placeholder" style={{ width: "70px" }}></span>
              <span className="placeholder" style={{ width: "70px" }}></span>
            </ul>
          </div>
        </div>
        <div className="company_info_row">
          <label>
            <span
              className="placeholder"
              style={{ width: "80px", marginRight: "15px" }}
            ></span>
          </label>
          <div className="event_tags">
            <ul className="list_stye_none">
              <span className="placeholder" style={{ width: "70px" }}></span>
              <span className="placeholder" style={{ width: "70px" }}></span>
            </ul>
          </div>
        </div>
      </div>
      <div className="details_btn">
        <span
          className="placeholder"
          style={{
            width: "110px",
            height: "40px",
            borderRadius: "30px",
          }}
        ></span>
      </div>
    </div>
  );
};

export default CompanyListPlaceHolder;
