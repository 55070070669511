/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

const EventDetailsRightPanel = ({ similarList }) => {
  const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>Similar Events</h4>
          </div>
        </div>
        <div className="similar_postpnl">
          {similarList.map((eventData, index) => {
            return (
              <div className="similar_post_row" key={index}>
                <div className="groups_info">
                  <div className="top_grp_info">
                    <div className="experts_holdertext">
                      <h3>
                        <Link to={`/events/details/${eventData._id}`}>
                          {eventData.title}
                        </Link>
                      </h3>
                      <h5>
                        <span>{eventData.fromdate}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="address">
                    <i className="material-icons-outlined">place</i>
                    <span>
                      {eventData.city}, {eventData.country}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsRightPanel;
