import React from "react";

const ForumPlaceHolderCard = () => {
  return (
    <div className="postuser_detailsbx white_shadow_bx">
      <div className="user_post_top">
        <div className="post_left">
          <div className="pofile_pic">
            <span
              className="placeholder"
              style={{ width: "36px", height: "36px" }}
            ></span>
          </div>
          <div className="pofile_info">
            <h3>
              <span
                className="placeholder"
                style={{ width: "130px", marginBottom: "10px" }}
              ></span>
            </h3>
            <ul className="list_stye_none">
              <span className="placeholder" style={{ width: "100%" }}></span>
            </ul>
          </div>
        </div>
      </div>
      <div className="user_post_paragraph">
        <p>
          <span className="placeholder" style={{ width: "90%" }}></span>
        </p>
      </div>
      <div className="poster_pic">
        <span
          className="placeholder"
          style={{ width: "100%", height: "500px" }}
        ></span>
      </div>
      <div className="user_post_icons">
        <ul className="list_stye_none">
          <li>
            <span className="placeholder" style={{ width: "100%" }}></span>
          </li>
          <li>
            <span className="placeholder" style={{ width: "100%" }}></span>
          </li>
          <li>
            <span className="placeholder" style={{ width: "100%" }}></span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ForumPlaceHolderCard;
