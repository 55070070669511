/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

const GroupModal = ({ myGroups = [], chatSelectionHandler = () => {} }) => {
  const [searchValue, setSearchValue] = useState("");

  //function for set filter leads
  const filterSearchGroups = () => {
    return myGroups.filter((group) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = group.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="group_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Start New Group Chat</h5>

              {/* <!-- ========== Start modal close button Section ========== --> */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-round">close </span>
              </button>
              {/* <!-- ========== End modal close button Section ========== --> */}
            </div>
            <div className="modal-body">
              {/* <!-- ========== Start search by input Section ========== --> */}
              <div className="group_srchfill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for group"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <!-- ========== End search by input Section ========== --> */}

              {/* <!-- ========== Start group list Section ========== --> */}
              <div className="group_innrScroll">
                {myGroups.map((group, index) => {
                  return (
                    <div
                      className={
                        filterSearchGroups().includes(group)
                          ? "chat_usrRow group_msg_row cursor-pointer"
                          : "d-none"
                      }
                      key={index}
                    >
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          chatSelectionHandler(
                            group.userid,
                            group.contactname,
                            group.contactimage,
                            group.contactemail,
                            "group",
                            group._id,
                            group.contactphone,
                            group.location,
                            group.contactcompany,
                            "",
                            "",
                            group.moderatorname,
                            group.moderatorimage,
                            group.groupmemberlist,
                            group.uploads,
                            group.moderatorid,
                            group.groupmemberstring
                          );
                        }}
                      >
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            {group.contactimage === "" ? (
                              <>
                                <figure className="group_usr">
                                  <img
                                    src={
                                      group.moderatorimage === ""
                                        ? assetImages.defaultUser
                                        : url.SERVER_URL + group.moderatorimage
                                    }
                                    alt=""
                                  />
                                </figure>
                                {group.groupmemberlist
                                  .slice(0, 2)
                                  .map((member, index2) => {
                                    return (
                                      <figure
                                        className="group_usr"
                                        key={index2}
                                      >
                                        <img
                                          src={
                                            member.groupmemberimage === ""
                                              ? assetImages.defaultUser
                                              : url.SERVER_URL +
                                                member.groupmemberimage
                                          }
                                          alt=""
                                        />
                                      </figure>
                                    );
                                  })}
                                {group.groupmemberlist.length + 1 > 3 ? (
                                  <span className="group_usr plus_usr">
                                    {group.groupmemberlist.length + 1 - 3}{" "}
                                    <em>+</em>
                                  </span>
                                ) : null}
                              </>
                            ) : (
                              <figure className="">
                                <img
                                  className="rounded-circle"
                                  height={40}
                                  width={40}
                                  src={url.SERVER_URL + group.contactimage}
                                  alt=""
                                />{" "}
                              </figure>
                            )}
                          </div>
                          <div className="user_name">
                            <h3>{group.contactname}</h3>
                            <p>
                              {group.moderatorname}, {group.groupmemberstring}
                            </p>
                          </div>
                        </div>
                        {/* <div className="members_check">
                          <span></span>
                        </div> */}
                      </Link>
                    </div>
                  );
                })}
                {/* <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>UX Team - DEL</h3>
                        <p>Monalisa, Devid, Harry</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/design-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Design Team 2</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div>
                
                <div className="chat_usrRow group_msg_row">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <figure className="group_usr">
                          <img src="images/gpic1.png" alt="" />
                        </figure>
                        <span className="group_usr plus_usr">
                          5 <em>+</em>
                        </span>
                      </div>
                      <div className="user_name">
                        <h3>XenTec Clients</h3>
                        <p>Monalisa, Devid, Lisa, and 5 more</p>
                      </div>
                    </div>
                    <div className="members_check">
                      <span></span>
                    </div>
                  </Link>
                </div>
                <div className="chat_usrRow">
                  <Link to="#">
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <figure className="">
                          <img src="images/dshbrd-grp.png" alt="" />
                        </figure>
                      </div>
                      <div className="user_name">
                        <h3>Dashboard SportsMoon</h3>
                        <p>Asana, Sankar, Lisa, and 2 more</p>
                      </div>
                    </div>
                    <div className="members_check ornge_bullet">
                      <span></span>
                    </div>
                  </Link>
                </div> */}
              </div>
              {/* <!-- ========== End group list Section ========== --> */}

              {/* <!-- ========== Start create button Section ========== --> */}
              <div className="group_btnOuter">
                <ul>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="blue_btn"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#group_people_mdl"
                    >
                      <i className="material-icons-round">add_circle</i>
                      <span>Create New</span>
                    </Link>
                  </li>
                  {/* <li className="w_50">
                    <Link to="#" className="ornage_btn">
                      <i className="material-icons-round">check_circle</i>
                      <span>Done</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
              {/* <!-- ========== End create button Section ========== --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupModal;
