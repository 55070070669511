/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const AppUserFilterPopup = ({
  ecosystemList,
  skillList,
  serviceList,
  tagList,
  setfilterName,
  setfilterCompany,
  setFilterPosition,
  setFilterRating,
  setfilterSkills,
  setFilterServices,
  setFilterTags,
  setFilterEcosystem,
  resetList,
}) => {
  const [ecosystemValue, setEcosystemValue] = useState(null);
  const [name, setname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [services, setServices] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tags, setTags] = useState([]);

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  //function for select service
  const serviceSelectionHandler = (data) => {
    let prevData = services;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        "userservicefilter-" + data.value
      );
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "userservicefilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setServices(prevData);
  };

  // function for set tags
  const skillSelectionHandler = (data) => {
    let prevData = skills;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("skilldata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("skilldata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setSkills(prevData);
  };

  //function for select tags
  const tagSelectionHandler = (data) => {
    let prevData = tags;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("tagdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setTags(prevData);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for reset
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  const resetSelectServices = () => {
    let resetBlock = document.getElementsByClassName("skillblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  const resetSelectSkills = () => {
    let resetBlock = document.getElementsByClassName("serviceblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    setfilterName(name);
    setfilterCompany(companyName);
    setFilterPosition(position);
    setFilterRating(selectedRating);
    setFilterServices(services);
    setfilterSkills(skills);
    setFilterTags(tags);
    setFilterEcosystem(ecosystemValue ? ecosystemValue.value : null);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#appuser_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    setEcosystemValue(null);
    setname("");
    setCompanyName("");
    setPosition("");
    setSelectedRating("");
    setTags([]);
    setServices([]);
    setSkills([]);
    removeActiveClass();
    resetSelectTags();
    resetSelectServices();
    resetSelectSkills();
  };

  const reloadUser = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#appuser_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="appuser_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* ecosystem */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Select Ecosystem</h4>
              </div>
              <div className="filter_information">
                <Select
                  placeholder="Select Ecosystem"
                  options={ecosystemList}
                  value={ecosystemValue}
                  onChange={(val) => setEcosystemValue(val)}
                />
              </div>
            </div>

            {/* company start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Company Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* company end */}

            {/* user name start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>User Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* user name end */}

            {/* postion */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Position</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Sort by</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>Recommended</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>Latest</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Date Posted</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past 24 hours</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past week</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>Past month</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div> */}

            {/* rating */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                {/* <ul className="list_stye_none filter_rating">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        4 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        3 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        2 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        1 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                </ul> */}
                <ul className="list_stye_none filter_rating">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="rdo3"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <span></span>
                        <p>
                          {rating}{" "}
                          <i className="material-icons-outlined">star</i> &
                          above
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Posted by</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none d-flex flex-align-items-center flex-wrap gap-3">
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Connections</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Follows</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Companies</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Groups</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Range Box</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Slider Range</h4>
              </div>
              <div className="filter_information">
                <div className="slider_range">
                  <img src="images/range.png" alt="" />
                </div>
              </div>
            </div> */}

            {/* service */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Services</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {serviceList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => serviceSelectionHandler(item)}
                              id={"userservicefilter-" + item.value}
                              className="serviceblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* skills */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Skills</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {skillList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => skillSelectionHandler(item)}
                              id={"skilldata-" + item.value}
                              className="skillblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* label */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(item)}
                              id={"tagdata-" + item.value}
                              className="tagblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadUser}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserFilterPopup;
