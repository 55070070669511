import React from "react";
import BlogPlaceHolderCard from "./BlogPlaceHolderCard";

const BlogListByCategoryPlaceholder = () => {
  const cardGlowCount = 3;
  return (
    <div className="landing_outer">
      <div className="landing_row">
        <div className="landing_hdng_row">
          <div className="page_hdng" style={{ width: "50%" }}>
            <div className="details_arw mt-2">
              <span
                className="placeholder rounded-circle"
                style={{ width: "60px", height: "60px" }}
              ></span>
            </div>
            <h2 style={{ width: "100%" }} className="mb-1">
              <span
                className="placeholder"
                style={{ width: "40%", height: "20px" }}
              ></span>
            </h2>
          </div>
        </div>
        <div className="blog_listing">
          <div className="row">
            {Array(cardGlowCount)
              .fill()
              .map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <BlogPlaceHolderCard />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListByCategoryPlaceholder;
