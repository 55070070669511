/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import MoreNewsPlaceHolder from "components/BlogsComponents/BlogPlaceHolder/MoreNewsPlaceHolder";
import BlogCard from "components/BlogsComponents/common/BlogCard";

//custom arrow button for react carousel outside
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="inBtns news_arws">
      <button className={previous ? "" : "disable"} onClick={previous} />
      <button onClick={previous} className="slick-prev slick-arrow" />
      <button onClick={next} className="slick-next slick-arrow" />
    </div>
  );
};

const MoreNews = ({ blogCategory }) => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);

  //custom property for responsive
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //function for get more blog list
  const getMoreBlogList = async () => {
    window.scroll(0, 0);
    try {
      setIsLoading(true);
      const categoryIds = blogCategory.map((category) => category.value);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_MORE_BLOGS + `?category=${categoryIds}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setIsLoading(false);
        const filteredBlog = response.data.filter(
          (blog) => blog._id !== params.id
        );
        setBlogList(filteredBlog);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (blogCategory.length > 0) {
      getMoreBlogList();
    }
  }, [blogCategory, params.id]);

  return (
    <section className="news_slider_sec">
      {isLoading ? (
        <MoreNewsPlaceHolder />
      ) : (
        <div className="container">
          <div className="page_hdng">
            <h2>MORE NEWS</h2>
          </div>

          {/* ----- more blog list section start ----- */}
          {blogList.length > 0 ? (
            <div className="news_slide_outer position-relative">
              <div className="news_slider">
                <Carousel
                  responsive={responsive}
                  infinite
                  arrows={false}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                >
                  {blogList.map((blog, index) => {
                    return (
                      <div className="news_slideItem" key={index}>
                        <BlogCard blog={blog} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              {/* custome arrow button */}
              {/* <div className="inBtns news_arws"></div> */}
            </div>
          ) : null}
          {/* ----- more blog list section end ----- */}
        </div>
      )}
    </section>
  );
};

export default MoreNews;
