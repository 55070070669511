import React from 'react';
import { Link } from 'react-router-dom';

const MyProfileRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_top_row">
        <div className="personel_profile_area">
          <div className="prsnl_profile_pic">
            <figure>
              <img src="images/upload-pic.png" alt="" />
            </figure>
            <div className="upload_camera">
              <Link to="#" className="upload_files">
                <input type="file" className="upload_btn" />
                <span className="material-icons-outlined">photo_camera</span>
              </Link>
              <Link to="#" className="upload_delete">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="personl_profiledetails">
            <div className="profile_owner_outer">
              <div className="profile_owner_text">
                <h2>Ricch Sango</h2>
                <h5>UX Designer</h5>
                <div className="edit_input input_fill">
                  <input
                    type="text"
                    value=""
                    className="form-control"
                    placeholder="Type name"
                  />
                </div>
              </div>
              <div className="profile_owner_editbtn">
                <Link to="#" className="edit_pencil">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <button className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </button>
              </div>
            </div>
            <div className="profile_social">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="images/twotone-facebook.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="images/google-gmail.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="images/linkedin.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="images/pinterest.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="images/link.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="refernel_code">
          <h4>Referer Code</h4>
          <div className="refer_code">MZQ1OTOX</div>
        </div>
      </div>
      <div className="profile_information white_shadow_bx">
        <div className="info_hdng">
          <h4>Basic Info</h4>
        </div>
        <div className="profile_info_pnl">
          <div className="profile_info_row">
            <label>Position</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>Sales Manager</span>
                <input
                  type="text"
                  value="Sales Manager"
                  className="form-control"
                />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Address</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>P.º del Prado, 36, 28014 Madrid, Spain</span>
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="">
                  <span className="material-icons-outlined">navigate_next</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Mentor</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>Mark Har</span>
                <input type="text" value="Mark Har" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="success_icons">
                  <span className="material-icons-outlined">check_circle</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Rate / Hour</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>€ 25</span>
                <input type="text" value="€ 25" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Email</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="blue_text">Pierre.gagnaire@gmail.com</span>
                <input
                  type="email"
                  value="Pierre.gagnaire@gmail.com"
                  className="form-control"
                />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Phone</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">+ 41 78920-89231</span>
                <input
                  type="number"
                  value="4178920-89231"
                  className="form-control"
                />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Website</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">-</span>
                <input type="text" value="-" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Language</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="English, Spanish">-</span>
                <div className="form-control">
                  <select className="nosearch_select ">
                    <option>English</option>
                    <option>Spanish</option>
                  </select>
                </div>
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Services</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">Ui Design, Ux Design</span>
                <div className="form-control">
                  <select className="nosearch_select">
                    <option>Ui Design</option>
                    <option>Ux Design</option>
                  </select>
                </div>
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Rank</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">-</span>
                <input type="text" value="-" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>ODS Points</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">0</span>
                <input type="number" value="0" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profile_bxOuter">
        <div className="profile_bx_item white_shadow_bx">
          <h4>About</h4>
          <div className="user_info_row">
            <div className="left_user_info">
              <p>
                I first met Pierre in 1988. On my first day at Dell Computer he
                jumped on the elevator with me to say he was excited I joined
                the company.
              </p>
            </div>
            <div className="right_user_info">
              <span>
                <img src="images/user-big.svg" alt="" />
              </span>
            </div>
          </div>
          <div className="add_btns">
            <Link to="#" className="btn outline">
              <i className="material-icons-outlined">add</i>
              <span>Add Question</span>
            </Link>
          </div>
        </div>
        <div className="profile_bx_item white_shadow_bx">
          <h4>Communication</h4>
          <div className="user_info_row">
            <div className="left_user_info">
              <h5>Madrid</h5>
              <p>
                P.º del Prado, 36, 28014 Madrid, Spain <br />
                Madrid, Spain ZIP: 28014
              </p>
            </div>
            <div className="right_user_info">
              <span>
                <img src="images/location-big.svg" alt="" />
              </span>
            </div>
          </div>
          <div className="add_btns">
            <Link to="#" className="btn outline">
              <i className="material-icons-outlined">edit</i>
              <span>Edit Details</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="profile_information white_shadow_bx">
        <div className="info_hdng">
          <h4>Mail Configuration</h4>
        </div>
        <div className="profile_info_pnl">
          <div className="profile_info_row">
            <label>Transportation</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>DHDL</span>
                <input type="text" value="DHDL" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Host</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>DHDL-H</span>
                <input type="text" value="DHDL-H" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="profile_info_row">
            <label>User</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span>Ricch Sango</span>
                <input type="text" value="€ 25" className="form-control" />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="profile_info_row">
            <label>Password</label>
            <div className="profile_inner_info">
              <div className="left_profile_info">
                <span className="">****</span>
                <input
                  type="password"
                  value="123456"
                  className="form-control"
                />
              </div>
              <div className="right_edit_icons">
                <Link to="#" className="edit_icons">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <Link to="#" className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profile_information white_shadow_bx">
        <div className="info_hdng">
          <h4>Portfolio</h4>
        </div>
        <div className="portfolio_outer">
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
          <div className="portfolio_pic">
            <figure>
              <img src="images/portfolio1.jpg" alt="" />
            </figure>
            <div className="full_screen">
              <Link to="#">
                <span className="material-icons-outlined">fullscreen</span>
              </Link>
            </div>
            <div className="trash_btn">
              <Link to="#">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="upload_img">
          <Link to="#" className="btn outline">
            <input type="file" className="file_btn" />
            <i className="material-icons-outlined">add</i>
            <span>Upload Photos </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyProfileRightPanel;
