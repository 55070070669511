import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const EntrepreneursCollaborate = ({
  source,
  autoScollContactNow,
  setSourceName,
}) => {
  const { t } = useTranslation(); // translation marker

  const contactNowHandler = () => {
    autoScollContactNow();
    setSourceName(`${source} : Lets talk`);
  };
  return (
    <section className="how_works_area pd_130">
      <div className="container">
        <div className="howwrks_outer talent_outer">
          <div className="row">
            <div className="col-lg-7">
              <div className="tab_leftpnl">
                <div className="tab_pic">
                  <img src={assetImages.collerateImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="tab_rightpnl">
                <div className="page_hdng">
                  <h5>{t("Collaborate")}</h5>
                  <h2>
                    {t("Connect")} <br /> {t("With The")} <br />{" "}
                    {t("Profession")} <br /> {t("Teams")}
                  </h2>
                </div>
                <div className="talent_para">
                  <p>
                    {t(
                      "Hire from professional team - weather you need 1 talent or a full cycle of development team."
                    )}
                  </p>
                </div>
                <div className="talent_btn">
                  <Link
                    to="#"
                    className="btn black_btn"
                    onClick={contactNowHandler}
                  >
                    {t("Connect Us")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntrepreneursCollaborate;
