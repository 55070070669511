/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import CourseDetailsBodyTopPanel from "../CourseDetailsBodyTopPanel/CourseDetailsBodyTopPanel";
import CourseDetailsBottomPanel from "../CourseDetailsBottomPanel/CourseDetailsBottomPanel";
import CourseJoinModal from "../Modals/CourseJoinModal";
import TagsModal from "components/common/Modals/TagsModal";
import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";
import GiveReviewModal from "components/common/Modals/GiveReviewModal";
import ReviewAddedSuccessModal from "components/common/Modals/ReviewAddedSuccessModal";
import JoinSuccessModal from "../Modals/JoinSuccessModal";

const CourseDetailsBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [courseDetails, setCourseDetails] = useState({});

  const [isReviewLoading, setIsReviewLoading] = useState(false);

  const [ratingCount, setRatingCount] = useState("0.0");
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [isJoining, setIsJoining] = useState(false);

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setCourseDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    setIsReviewLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=course&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log("review list response", response);

      setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount("");
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterReviewModalClose = () => {
    getCourseDetails();
    getReviewsLists();
  };

  //function for join course
  const joinCourseHandler = async () => {
    const todayValue = new Date().toISOString().split("T")[0];

    try {
      setIsJoining(true);

      let courseJoinData = {
        user: userInfo._id,
        course: params.id,
        company: null,
        role: null,
        useremail: userInfo.email,
        memberstatus: "1",
        requestdate: todayValue,
      };

      console.log("Join course Data------>", courseJoinData);

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_COURSE + `?token=${token}`;

      const response = await postData(requestURL, courseJoinData);

      setIsJoining(false);

      console.log(response);

      if (response.status) {
        // if (secretParam) {
        //   const baseURL = fullURL.split("?")[0];
        //   window.location.href = baseURL;
        // }
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("course_join_success_mdl")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterApiCall = () => {
    getCourseDetails();
    getReviewsLists();
  };

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getReviewsLists();
    }
  }, [params.id]);

  // useEffect(() => {
  //   const modalTarget = document.querySelector('#esg_modal');
  //   const modal = new bootstrap.Modal(modalTarget);
  //   modal.show();
  // }, []);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="back_btn">
          <Link to="/courses">
            <i className="material-icons-outlined">arrow_back</i>
            <span>Back</span>
          </Link>
        </div>
        <div className="pnl_outer lesson_pnl_outer">
          {/* -------- top panel -------- */}
          <CourseDetailsBodyTopPanel
            courseDetails={courseDetails}
            isJoining={isJoining}
            joinCourseHandler={joinCourseHandler}
          />

          {/* -------- bottom panel ------- */}
          <CourseDetailsBottomPanel
            courseDetails={courseDetails}
            ratingCount={ratingCount}
            reviewsList={reviewsList}
            ratingPercentArr={ratingPercentArr}
          />
        </div>
      </div>

      <CourseJoinModal />

      {/* ------- feedback modal -------- */}
      <GiveReviewModal moduleName="course" />

      <ReviewAddedSuccessModal afterModalClose={afterReviewModalClose} />

      {/* ----------- tags modal --------------- */}
      <TagsModal moduleName="coursedetails" selectedId={params.id} />

      {/* --------- tags added success / greetings modal ------------- */}
      <TagsAddedSuccessModal afterTagModalClose={getCourseDetails} />

      {/* success modal for join  */}
      <JoinSuccessModal afterModalClose={afterApiCall} />
    </section>
  );
};

export default CourseDetailsBody;
