/* eslint-disable */
import React from "react";
import AuthHeader from "components/common/AuthHeader";
import FeedLandingBody from "components/FeedLandingComponents/FeedLandingBody/FeedLandingBody";

const FeedLanding = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      <FeedLandingBody />
    </>
  );
};

export default FeedLanding;
