/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

const SuccessStoriesDescription = ({
  storyName,
  storyDate,
  companyName,
  companyImageUrl,
  successStorySummary,
  successStoryDetails,
  successStorySitutation,
  successStoryObjectives,
  successStoryProcess,
  successStoryResults,
  uploadedFiles,
}) => {
  return (
    <section className="success_stories_details">
      <div className="container">
        <div className="landing_outer">
          <div className="landing_row blog_details_row">
            {/* ----- back arrow button section start ------ */}
            <div className="details_arw green_back mb-4">
              <Link to="/successstories">
                <span className="material-icons-outlined">arrow_back</span>
              </Link>
            </div>
            {/* ----- back arrow button section end ------ */}
            {/* ----- story name section start ------ */}
            <div className="landing_hdng_row">
              <div className="page_hdng">
                <h2>{storyName}</h2>
              </div>
            </div>
            {/* ----- story name section end ------ */}
            {/* ----- company image,company name and date section start ------ */}
            <div className="news_date_info">
              <div className="bttm_step_cloude">
                {/* ----- company image section start ------ */}
                <div className="cloud_icon">
                  <span>
                    <img src={companyImageUrl} alt="" />
                  </span>
                </div>
                {/* ----- company image section end ------ */}

                {/* ----- company name and date section start ------ */}
                <div className="cloud_icon_text">
                  <h4>{companyName}</h4>
                  <h6>{storyDate}</h6>
                </div>
                {/* ----- company name and date section end ------ */}
              </div>
            </div>
            {/* ----- company image,company name and date section end ------ */}

            {/* ------ details section start ------ */}
            <div className="details_content_innr">
              <div className="content_item">
                <div className="details_para">
                  <p
                    dangerouslySetInnerHTML={{ __html: successStoryDetails }}
                  ></p>
                </div>
              </div>
            </div>
            {/* ------ details section end ------ */}

            {/* Previous Situation,Previous Objectives,Process and Results section start */}
            <div className="success_listing_box_outer">
              {/*------ Previous Situation section start ------*/}
              <div className="success_list_bx">
                <h3>Previous Situation</h3>
                <div className="success_list">
                  <h5
                    dangerouslySetInnerHTML={{ __html: successStorySitutation }}
                  ></h5>
                </div>
              </div>
              {/*------ Previous Situation section end ------*/}

              {/*------ Previous Objectives section start ------*/}
              <div className="success_list_bx">
                <h3>Previous Objectives</h3>
                <div className="success_list">
                  <h5
                    dangerouslySetInnerHTML={{ __html: successStoryObjectives }}
                  ></h5>
                </div>
              </div>
              {/*------ Previous Objectives section end ------*/}

              {/*------ Process section start ------*/}
              <div className="success_list_bx">
                <h3>Process</h3>
                <div className="success_list">
                  <h5
                    dangerouslySetInnerHTML={{ __html: successStoryProcess }}
                  ></h5>
                </div>
              </div>
              {/*------ Process section end ------*/}

              {/*------ Results section start ------*/}
              <div className="success_list_bx">
                <h3>Results</h3>
                <div className="success_list">
                  <h5
                    dangerouslySetInnerHTML={{ __html: successStoryResults }}
                  ></h5>
                </div>
              </div>
              {/*------ Results section end ------*/}
            </div>
            {/* Previous Situation,Previous Objectives,Process and Results section end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesDescription;
