import React from 'react';
import AuthHeader from 'components/common/AuthHeader';
import PasswordDetailsBody from 'components/PasswordComponents/PasswordDetailsComponents/PasswordDetailsBody/PasswordDetailsBody';

const PasswordDetails = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <PasswordDetailsBody />
    </>
  );
};

export default PasswordDetails;
