/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import AppUserListBody from "components/AppUserComponents/AppUserListComponents/AppUserListBody/AppUserListBody";

const AppUserList = () => {
  const { t, i18n } = useTranslation(); // translation marker

  return (
    <>
      <AuthHeader moduleName="appuser" />

      <AppUserListBody />

      {/* <div className="header_bottom_menu">
        <span className="cross_btn material-icons-outlined d-none">close</span>
        <div className="container-fluid">
          <div className="bottom_menu_list activity_top_menu">
            <ul className="list_stye_none">
              <li>
                <Link to="#" data-bs-toggle="dropdown" className="active_menu">
                  <span className="">
                    <span>{t("Sort by")} </span>
                    <i className="material-icons-outlined down_arw">
                      expand_more
                    </i>
                  </span>
                </Link>
                <ul className="list_stye_none dropdown-menu">
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        parmsChange("desc");
                      }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined">arrow_upward</i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        parmsChange("asc");
                      }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined">arrow_downward</i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        parmsChange("name");
                      }}
                    >
                      {t("Name")} (A-Z)
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="#appuser_filter_offcanvas"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="appuser_filter_offcanvas"
                >
                  <span className="">
                    <span>{t("Filters")}</span>
                    <i className="material-icons-outlined down_arw">
                      navigate_next
                    </i>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AppUserList;
