/* eslint-disable */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import { ecosystemSlug } from "Config/Config";

const AuthValidate = () => {
  let token = localStorage.getItem("token");

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const validateUserHandler = async () => {
    const userToken = queryParams.get("token");

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_AUTH_VALIDATE +
        `?token=${userToken}&ecosystemslug=${ecosystemSlug}`;

      console.log("request url -->", requestURL);

      const response = await getData(requestURL);

      console.log("response -->", response);

      if (response.status) {
        localStorage.setItem("token", userToken);
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        localStorage.setItem("userID", JSON.stringify(response.data._id));
        localStorage.setItem("ecosystem", response.ecosystemid);
        localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
        localStorage.setItem("moduleaccess", response.data.moduleaccessdata);

        window.location.href = "/feed";
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    try {
      if (token) {
        localStorage.clear();
      }
      validateUserHandler();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  return null;
};

export default AuthValidate;
