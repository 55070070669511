import { assetImages } from "constants";

const ProcessTab = ({ processList = [] }) => {
  return (
    <div className="white_shadow_bx people_bx">
      {processList.length === 0 ? (
        <div className="pnl_mdlpnl w-100">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-md text-gray fw-semibold">
                Sorry....! Processlist not availbale for this job
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="dates_pnlOuter">
          {processList.map((process, index) => {
            return (
              <div className="dates_pnlItem" key={index}>
                <span className="dates_nmbr">{process.order}</span>
                <div className="dates_hdng">
                  <h4>{process.title}</h4>
                </div>
                <div className="event_tags">
                  <ul className="list_stye_none">
                    <li>
                      <i className="material-icons-outlined">place</i>
                      <span>San Deago,CA USA</span>
                    </li>
                    <li>
                      <i className="material-icons-outlined">event_note</i>
                      <span>
                        {process.startdate} - {process.enddate}
                      </span>
                    </li>
                    {/* <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li> */}
                  </ul>
                </div>
                <div className="dates_listing">
                  <h3>KPIs for evaluation</h3>
                  <ul className="list_stye_none">
                    {process.kpisarr.map((kpis, index2) => {
                      return (
                        <li key={index2}>
                          <i className="material-icons-outlined"> done</i>{" "}
                          {kpis.skillname}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProcessTab;
