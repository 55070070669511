/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*import url and gateway methods */
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { fileDownloadHandler } from "utils/DownloadFile";

const SavePostModal = ({ forumId, onModalClose }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Type details here...",
  };
  /*---- CKEditor classic editor configuration end ----- */

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);

  const [isPrivate, setIsPrivate] = useState(false);

  const [isContact, setIsContact] = useState(false);
  const [isEvent, setisEvent] = useState(false);
  const [isGroup, setisGroup] = useState(false);
  const [isChallenges, setIsChallenges] = useState(false);

  const [contactInput, setcontactInput] = useState("");
  const [contactOptions, setcontactOptions] = useState([]);
  const [contactValue, setContactValue] = useState(null);
  const [contactIds, setContactIds] = useState([]);

  const [eventInput, seteventInput] = useState("");
  const [eventOptions, seteventOptions] = useState([]);
  const [eventValue, seteventValue] = useState(null);
  const [eventId, seteventId] = useState(null);

  const [groupInput, setgroupInput] = useState("");
  const [groupOptions, setgroupOptions] = useState([]);
  const [groupValue, setgroupValue] = useState(null);
  const [groupId, setgroupId] = useState(null);

  //function for file upload
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      console.log("req url", requestUrl);

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      console.log(
        `response in file upload mail template------>`,
        response.data
      );

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);

    resetFile();
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get contact list
  const getContactList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${contactInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setcontactOptions(response.data);
        }
        // else {
        //   const emailMatch = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //   let userData = {
        //     label: userInput,
        //     value: userInput,
        //     email: emailMatch.test(userInput) ? userInput : "",
        //     _id: null,
        //   };
        //   setuserOptions([userData]);
        // }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get contact input
  const contactInputHandler = (val) => {
    setcontactInput(val);
  };

  //function contact Selection
  const contactSelectionHandler = (val) => {
    setContactValue(val);
    setContactIds(val.map((item) => item.value));
  };

  //function for event input
  const eventInputHandler = (val) => {
    seteventInput(val);
  };

  //get eventlist
  const getEventList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_LIST_OPTIONS +
        `?token=${token}&eventstring=${eventInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          seteventOptions(response.data);
        } else {
          seteventOptions([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //eventSelectionHandler
  const eventSelectionHandler = (val) => {
    if (val) {
      seteventValue(val);
      seteventId(val.value);
    } else {
      seteventValue(null);
      seteventId(null);
    }
  };

  //function for group input
  const groupInputHandler = (val) => {
    setgroupInput(val);
  };

  //get group list
  const getGroupList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_LIST +
        `?token=${token}&groupstring=${groupInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setgroupOptions(response.data);
        } else {
          setgroupOptions([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //groupSelectionHandler
  const groupSelectionHandler = (val) => {
    if (val) {
      setgroupValue(val);
      setgroupId(val.value);
    } else {
      setgroupValue(null);
      setgroupId(null);
    }
  };

  //get details
  const getPostDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FORUM_DETAILS +
        `/${forumId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
        setdescription(response.data.description);
        setIsPrivate(response.data.isprivate);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data?.uploads.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data?.uploads?.map(({ _id, ...other }) => ({
          _id,
        }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/

        if (response.data.activitycontacts.length > 0) {
          setIsContact(true);
          contactSelectionHandler(response.data.activitycontacts);
        } else {
          setIsContact(false);
          contactSelectionHandler([]);
        }

        if (response.data.activityeventvalue) {
          setisEvent(true);
          eventSelectionHandler(response.data?.activityeventvalue);
        } else {
          setisEvent(false);
        }

        if (response.data.activitygroupvalue) {
          setisGroup(true);
          groupSelectionHandler(response.data?.activitygroupvalue);
        } else {
          setisGroup(false);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save post
  const savePostHandler = async () => {
    try {
      let forumData = {
        title,
        description,
        uploads: uploadedFileIds,
        isprivate: isPrivate,
        contactids: contactIds,
        eventid: eventId,
        groupid: groupId,
      };

      let requestURL = url.API_BASE_URL;

      let response = null;

      if (forumId) {
        requestURL =
          requestURL + url.API_UPDATE_FORUM + `/${forumId}` + `?token=${token}`;

        response = await putData(requestURL, forumData);
      } else {
        requestURL = requestURL + url.API_CREATE_FORUM + `?token=${token}`;
        response = await postData(requestURL, forumData);
      }

      resetHandler();

      console.log(response);

      if (response.status) {
        //hide login modal
        let postModal = document.querySelector("#create_postmdl");
        let modal = bootstrap.Modal.getInstance(postModal);
        modal.hide();

        //show otpconfirm modal
        let myModal = new bootstrap.Modal(
          document.getElementById("gretings_mdl")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset
  const resetHandler = () => {
    settitle("");
    setdescription("");
    setUploadedFileIds([]);
    setUploadedFiles([]);

    setIsPrivate(false);

    setIsContact(false);
    setcontactInput("");
    setcontactOptions([]);
    setContactValue(null);
    setContactIds([]);

    setisEvent(false);
    setisGroup(false);

    onModalClose();
  };

  useEffect(() => {
    if (forumId) {
      getPostDetails();
    }
  }, [forumId]);

  useEffect(() => {
    if (contactInput.length > 3) {
      getContactList();
    }
  }, [contactInput]);

  useEffect(() => {
    if (eventInput.length > 3) {
      getEventList();
    }
  }, [eventInput]);

  useEffect(() => {
    if (groupInput.length > 3) {
      getGroupList();
    }
  }, [groupInput]);

  const contactOptionsToShow = contactOptions.length > 0 ? contactOptions : [];
  const eventOptionsToShow = eventOptions.length > 0 ? eventOptions : [];
  const groupOptionsToShow = groupOptions.length > 0 ? groupOptions : [];

  return (
    <div className="post_modal">
      <div className="modal fade" id="create_postmdl" data-bs-backdrop="static">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Create Post
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post_left">
                <div className="pofile_pic">
                  <img
                    src={
                      userInfo.photoimage
                        ? url.SERVER_URL + userInfo.photoimage?.path
                        : assetImages.defaultUser
                    }
                    alt=""
                  />
                </div>
                <div className="pofile_info">
                  <h3>
                    {userInfo.name} {userInfo.surname}
                  </h3>
                  {isPrivate ? (
                    <Link to="#">
                      <i className="material-icons">settings</i>
                      <span>Private</span>
                    </Link>
                  ) : (
                    <Link to="#">
                      <i className="material-icons">public</i>
                      <span>Public</span>
                    </Link>
                  )}
                </div>
              </div>

              {/* title */}
              <div className="landing_login_fillOuter mt-4">
                {" "}
                <div className="input_fill_landing ">
                  <input
                    className="form-control"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>

              {/* details */}
              <div className="post_description">
                <CKEditor
                  className="form-control"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={description}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editorContent = editor.getData();
                    setdescription(editorContent);
                  }}
                />
              </div>

              <ul className="row upload-post-media d-flex flex-wrap mt-3">
                {/* uploaded file preview */}
                {uploadedFiles.length > 0
                  ? uploadedFiles.map((file, index) => {
                      return (
                        <li className="col-3" key={index}>
                          <div className="upload-container position-relative overflow-hidden">
                            <Link
                              onClick={() => fileDownloadHandler(file)}
                              to="#"
                              className="text-white position-absolute top-0 start-0 mt-2 ms-2"
                            >
                              <span className="d-block material-icons-outlined">
                                download
                              </span>
                            </Link>
                            <div className="ratio ratio-16x9">
                              <img src={file.path} alt="" />
                            </div>
                            <Link
                              onClick={() => closeFileHandler(index)}
                              to="#"
                              className="text-white position-absolute top-0 end-0 mt-2 me-2"
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        </li>
                      );
                    })
                  : null}

                {/* <li className="col-3">
                  <div className="upload-container position-relative overflow-hidden">
                    <div className="ratio ratio-16x9">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/ScMzIvxBSi4?si=k7qLuA5hBam_U3kC"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <Link
                      to="#"
                      className="text-white position-absolute top-0 end-0 mt-2 me-2"
                    >
                      <span className="d-block material-icons-outlined">
                        delete
                      </span>
                    </Link>
                  </div>
                </li> */}

                {/* file upload */}
                <li className="col-2">
                  <label
                    htmlFor="uploadFile"
                    className="upload-container text-center h-100 flex-column p-3 position-relative overflow-hidden"
                  >
                    <input
                      type="file"
                      id="uploadFile"
                      className="d-none"
                      onChange={uploadHandler}
                      multiple
                    />
                    <span className="d-block material-icons-outlined mb-3">
                      upload
                    </span>
                    <h6 className="fw-bold mb-2">Upload</h6>
                    <p>Max 20mb image/doc/mp4</p>
                  </label>
                </li>
              </ul>

              <div className="sharing-option mt-3">
                <label
                  htmlFor="isPrivate"
                  className="d-flex align-items-center gap-2 mb-3"
                >
                  <input
                    type="checkbox"
                    className="w-auto"
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                  <span className="d-block fw-bold">Is Private</span>
                </label>

                {isPrivate && (
                  <div className="post_list">
                    <div className="post_bttm_menu">
                      <ul className="list_stye_none">
                        {/* contact */}
                        <li className={isContact ? "active" : ""}>
                          <Link
                            to="#"
                            onClick={() => {
                              setIsContact(!isContact);
                            }}
                          >
                            <i className="material-icons blue_icon">contacts</i>
                            <span>Contacts</span>
                          </Link>
                        </li>

                        {/* event */}
                        <li className={isEvent ? "active" : ""}>
                          <Link
                            to="#"
                            onClick={() => {
                              setisEvent(!isEvent);
                            }}
                          >
                            <i className="material-icons ylw_icon">
                              event_available
                            </i>
                            <span>Events</span>
                          </Link>
                        </li>

                        {/* group */}
                        <li className={isGroup ? "active" : ""}>
                          <Link
                            to="#"
                            onClick={() => {
                              setisGroup(!isGroup);
                            }}
                          >
                            <i className="material-icons grn_icon">groups</i>
                            <span>Groups </span>
                          </Link>
                        </li>

                        {/* <li>
                          <Link to="#">
                            <i className="material-icons ornge_icon">work</i>
                            <span>Jobs</span>
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="#">
                            <i className="material-icons ornge_icon">
                              library_books
                            </i>
                            <span>Course</span>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                )}

                {/* contact select */}
                {isContact && (
                  <div className="field-container mt-3">
                    <label
                      htmlFor="selectContact"
                      className="d-block fw-bold mb-2"
                    >
                      Select Contact
                    </label>
                    <Select
                      isMulti
                      placeholder="Search by name, email"
                      options={contactOptionsToShow}
                      value={contactValue}
                      onChange={(val) => {
                        contactSelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        contactInputHandler(val);
                      }}
                    />
                  </div>
                )}

                {/* event select */}
                {isEvent && (
                  <div className="field-container mt-3">
                    <label
                      htmlFor="selectContact"
                      className="d-block fw-bold mb-2"
                    >
                      Select Event
                    </label>
                    <Select
                      isClearable
                      placeholder="Search by event name"
                      options={eventOptionsToShow}
                      value={eventValue}
                      onChange={(val) => {
                        eventSelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        eventInputHandler(val);
                      }}
                    />
                  </div>
                )}

                {/* group select */}
                {isGroup && (
                  <div className="field-container mt-3">
                    <label
                      htmlFor="selectContact"
                      className="d-block fw-bold mb-2"
                    >
                      Select Group
                    </label>
                    <Select
                      isClearable
                      placeholder="Search by group name"
                      options={groupOptionsToShow}
                      value={groupValue}
                      onChange={(val) => {
                        groupSelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        groupInputHandler(val);
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="post_btn" onClick={savePostHandler}>
                <button
                  className="btn"
                  // data-bs-toggle="modal"
                  // data-bs-dismiss="modal"
                  // data-bs-target="#gretings_mdl"
                >
                  Post Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavePostModal;
