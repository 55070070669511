/* eslint-disable */
/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
//import images
import { assetImages } from "constants";

const AppUserLeftPanel = ({
  userName,
  userPosition,
  userImage,
  userRating,
  totalNoReviews,
  userCity,
  userState,
  userCountry,
  userRate,
  userTags,
  isConnect,
  connectStatus,
  connectedDate,
  requestDate,
  unfriendStatus,
  resumeContactOwner,
  resumeContactMember,
  isProfileOwner,
  cancelRequestHandler,
  unfriendHandler,
  resumeContactHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div className="pnl_leftpnl">
      <span className="cross_btn material-icons-outlined">close</span>
      <div className="white_shadow_bx people_leftusr">
        <div className="expertholder_outer">
          <div className="experts_holderPic">
            <img
              className="rounded-circle"
              src={
                userImage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + userImage
              }
              alt=""
            />
          </div>
          <div className="experts_holdertext">
            <h3>
              {userName} <span>{userPosition}</span>
            </h3>
            <div className="jb_rating star_color">
              <i className="material-icons-outlined ornge_icon"> star </i>
              <span className="rating">
                {userRating} <em>({totalNoReviews})</em>
              </span>
            </div>
            <div className="details_tags expert_details">
              {userCity !== "" && userState !== "" && userCountry !== "" ? (
                <div className="address">
                  <i className="material-icons-outlined">place</i>
                  <span>
                    {userCity} ,{userState} ,{userCountry}
                  </span>
                </div>
              ) : null}
              {userRate != "" ? (
                <div className="address">
                  <i className="material-icons-outlined">
                    account_balance_wallet
                  </i>
                  <span>${userRate}/Hour</span>
                </div>
              ) : null}
            </div>

            <div className="expert_social">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="/images/fb.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/gmail.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/linkedin.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/pinterest.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="feedback_jointbtnOuter mb-4">
              {connectStatus || isProfileOwner ? (
                <div className="feed_btn">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#recomanded_mdl"
                    className="btn gray_btn"
                  >
                    {t("Recomanded")}
                  </Link>
                </div>
              ) : null}

              {connectStatus ? (
                <div className="feed_btn">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#feedback_mdl"
                    className="btn gray_btn"
                  >
                    {t("Give Review")}
                  </Link>
                </div>
              ) : null}
            </div>

            <div className="feedback_jointbtnOuter mb-4">
              {isProfileOwner ? null : isConnect ? (
                <div className="details_apply dropdown">
                  <Link
                    to="#"
                    className="btn outline"
                    data-bs-toggle={
                      connectStatus
                        ? "dropdown"
                        : unfriendStatus == "0"
                        ? "dropdown"
                        : resumeContactOwner == "1" ||
                          resumeContactMember == "1"
                        ? "dropdown"
                        : "#"
                    }
                  >
                    <i className="material-icons-outlined">group</i>
                  </Link>
                  <ul className="list_stye_none dropdown-menu">
                    {connectStatus ? (
                      <li>
                        <Link to="#" onClick={unfriendHandler}>
                          <i className="material-icons-outlined">
                            person_remove
                          </i>
                          <span>{t("Unfriend")}</span>
                        </Link>
                      </li>
                    ) : null}
                    {/* {connectStatus ? (
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined red_icon">
                            block
                          </i>
                          <span>{t("Block")}</span>
                        </Link>
                      </li>
                    ) : null} */}
                    {connectStatus ? null : unfriendStatus == "0" ? (
                      <li>
                        <Link to="#" onClick={cancelRequestHandler}>
                          <i className="material-icons-outlined red_icon">
                            close
                          </i>
                          <span>{t("Cancel Request")}</span>
                        </Link>
                      </li>
                    ) : resumeContactOwner == "1" ||
                      resumeContactMember == "1" ? (
                      <li>
                        <Link to="#" onClick={resumeContactHandler}>
                          <i className="material-icons-outlined red_icon">
                            question_mark
                          </i>
                          <span>{t("Resume Contact")}</span>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
              ) : (
                <div className="details_apply">
                  <Link
                    to="#"
                    className="btn outline"
                    data-bs-toggle="modal"
                    data-bs-target="#send_rqst_mdl"
                  >
                    {t("Connect")}
                  </Link>
                </div>
              )}

              {/* tag trigger section start */}
              <div className="tag_btn">
                <Link
                  data-bs-target="#tags_modal"
                  data-bs-toggle="modal"
                  to="#"
                  className="btn gray_btn"
                >
                  <i className="material-icons-outlined">more</i>
                </Link>
              </div>
              {/* tag trigger section end */}
            </div>

            {/* {connectStatus ? (
              <div className="feedback_jointbtnOuter">
                <div className="details_apply dropdown">
                  <Link
                    to="#"
                    className="btn outline"
                    data-bs-toggle="dropdown"
                  >
                    <i className="material-icons-outlined">group</i>
                  </Link>
                  <ul className="list_stye_none dropdown-menu">
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">person_remove</i>
                        <span>{t("Unfriend")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined red_icon">
                          block
                        </i>
                        <span>{t("Block")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined red_icon">
                          close
                        </i>
                        <span>{t("Cancel Request")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null} */}

            {/* connectdate and request date section start */}
            {connectedDate != "" ? (
              <div className="connected_text">
                <p className="d-flex align-items-center justify-content-center">
                  <i className="material-icons">check_circle</i>
                  <span>
                    {t("Connected on")} {connectedDate}
                  </span>
                </p>
              </div>
            ) : requestDate != "" ? (
              <div className="connected_text">
                <p className="d-flex align-items-center justify-content-center">
                  <i className="material-icons text-danger">info</i>
                  <span>
                    {t("Request on")} {requestDate}
                  </span>
                </p>
              </div>
            ) : null}
            {/* connectdate and request date section end */}

            {/* tag section start */}
            <div className="tags_links mt-4 mb-2">
              <ul className="list_stye_none d-flex flex-wrap gap-2 justify-content-center">
                {userTags.map((tag, index) => {
                  return (
                    <li key={index}>
                      <Link to="#" className="d-flex align-items-center gap-2">
                        <span>{tag.title}</span>
                        {/* <i className="material-icons">close</i> */}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* tag section end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserLeftPanel;
