import { assetImages } from "constants";
import React from "react";

const DocsTab = ({ uploadedDocFiles = [] }) => {
  return (
    <div className="people_details_bx white_shadow_bx">
      {uploadedDocFiles.length === 0 ? (
        <div className="pnl_mdlpnl w-100">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-md text-gray fw-semibold">
                Sorry....! No files availbale at this moment
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="upload_area">
          {uploadedDocFiles.map((file, index) => {
            return (
              <div className="upload_item" key={index}>
                <div className="uploaded_innrBx">
                  <div className="upload_icon">
                    <img src="/images/pdf.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>{file.name}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocsTab;
