import JobResponseOptions from "Data/JobResponseStatus.json";

export const getPostDateFormat = (dateString) => {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Define an array to map the day of the week
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Extract the day of the week from the Date object
  const dayOfWeekIndex = date.getDay();

  // Get the day of the week string
  const dayOfWeek = daysOfWeek[dayOfWeekIndex];

  // Format the date string as "dayOfWeek, DD Month YYYY"
  const formattedDate = `${dayOfWeek}, ${date.getDate()} ${date.toLocaleString(
    "en-US",
    { month: "long" }
  )} ${date.getFullYear()}`;

  return formattedDate; // Output the formatted date
};

export const getJobResponseStatus = (jobStatus = "") => {
  let statusData = "";

  for (let status of JobResponseOptions) {
    if (status.value === jobStatus) {
      statusData = status.label;
    }
  }

  return statusData;
};
