import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const SustainabilityAskQuestions = ({
  source,
  autoScollContactNow,
  setSourceName,
}) => {
  const { t } = useTranslation(); // translation marker

  const contactNowHandler = () => {
    autoScollContactNow();
    setSourceName(`${source} : Lets talk`);
  };

  return (
    <section className="success_faq pd_130">
      <div className="container">
        <div className="success_faq_outer">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq_leftpnl">
                <div className="page_hdng">
                  <h5>{t("Frequently asked questions")}</h5>
                  <h2>
                    {t("Things")} <br /> {t("You Want")} <br /> {t("to ask")}?
                  </h2>
                </div>
                <div className="talent_btn">
                  <Link to="#" className="btn" onClick={contactNowHandler}>
                    {t("Ask as Now")}!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="success_faq_rightpnl">
                <div className="accordian_area">
                  <div className="accordion accordion-flush" id="faq-accordian">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                        >
                          <span>{t("Is there an associated cost")}</span>
                          <div className="action_btn">
                            <i className="material-icons minus">
                              do_not_disturb_on
                            </i>
                            <i className="material-icons plus">add_circle</i>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="accor_para">
                            <p>
                              {t(
                                "Our freemium model allows companies to join for free, but we offer premium packages with additional benefits."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                        >
                          <span>{t("This is a dummy question")}?</span>
                          <div className="action_btn">
                            <i className="material-icons minus">
                              do_not_disturb_on
                            </i>
                            <i className="material-icons plus">add_circle</i>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="accor_para">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                        >
                          <span>{t("Heading can make it as a sample")}?</span>
                          <div className="action_btn">
                            <i className="material-icons minus">
                              do_not_disturb_on
                            </i>
                            <i className="material-icons plus">add_circle</i>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="accor_para">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapsefour"
                          aria-expanded="false"
                        >
                          <span>{t("do you have more to ask")}?</span>
                          <div className="action_btn">
                            <i className="material-icons minus">
                              do_not_disturb_on
                            </i>
                            <i className="material-icons plus">add_circle</i>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapsefour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="accor_para">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapsefive"
                          aria-expanded="false"
                        >
                          <span>{t("Have a little question to ask")}?</span>
                          <div className="action_btn">
                            <i className="material-icons minus">
                              do_not_disturb_on
                            </i>
                            <i className="material-icons plus">add_circle</i>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapsefive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="accor_para">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityAskQuestions;
