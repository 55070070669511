/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// import use translation ------------------------------
//import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

import { ecosystemSlug } from "Config/Config";

const CustomerFeedbackLeftPanel = () => {

        const params = useParams();
        const respId = params.id;

        const token = localStorage.getItem("token");

        const [jobPost, setjobPost] = useState("");
        const [jobPostCompany, setJobPostCompany] = useState("");
        const [responseCode, setResponseCode] = useState("");

        const [cvTitle, setcvTitle] = useState("");
        const [currCtc, setcurrCtc] = useState("");
        const [expCtc, setexpCtc] = useState("");
        const [country, setcountry] = useState("");
        const [userImagePath, setuserImagePath] = useState("");
        const [candidateName, setcandidateName] = useState("");
        const [candiddateSurname, setcandiddateSurname] = useState("");
        const [candidatePosition, setcandidatePosition] = useState("");
        const [candidateEmail, setcandidateEmail] = useState("");
        const [candidatePhone, setcandidatePhone] = useState("");
        const [userSummary, setuserSummary] = useState("");
        const [userDetails, setuserDetails] = useState("");
        const [skilPoints, setskilPoints] = useState([]);
        const [experiences, setexperiences] = useState([]);
        const [educations, seteducations] = useState([]);
        const [certificates, setcertificates] = useState([]);

        const [userLanguages, setUserLanguages] = useState([]);

        const [userStartTime, setuserStartTime] = useState("");
        const [userEndTime, setuserEndTime] = useState("");
        const [userOptionalSkills, setuserOptionalSkills] = useState([]);

        const [userPortfolioLinks, setuserPortfolioLinks] = useState([]);
        const [userHobbies, setuserHobbies] = useState([]);

        const [roles, setRoles] = useState([]);
        const [carrierHighlights, setCarrierHighlights] = useState([]);
        const [experienceInYears, setExperienceInYears] = useState("");
        const [projects, setProjects] = useState([]);
        const [otherSkills, setOtherSkills] = useState([]);
        const [jobPublishDate, setJobPublishDate] = useState("");
        const [jobResponseDate, setJobResponseDate] = useState("");
        const [customerResponseDate, setCustomerResponseDate] = useState("");

        //function for get project details
        const getJobResponseDetails = async () => {
            try {
            let requestUrl =
                url.API_BASE_URL +
                url.API_GET_CHALLENGE_RESPONSE_DETAILS +
                `/${respId}?token=${token}`;

            console.log('req url', requestUrl);

            const response = await getData(requestUrl);

            console.log("response in details popup", response);

            if (response.status) {
                if (response.data) {
                    const data = response.data;
                    setcvTitle(data.candidatecvtitle);
                    setjobPost(data.candidatejobpost);
                    setJobPostCompany(data.jobcompany);
                    setResponseCode(data.responsecode);
                    setcurrCtc(data.cvcurrentctc);
                    setexpCtc(data.cvexpectedctc);
                    setcountry(data.cvcountry);
                    setuserImagePath(data.providerimage);
                    setcandidateName(data.providername);
                    setcandiddateSurname(data.providersurname);
                    setcandidatePosition(data.providerpostion);
                    setcandidateEmail(data.provideremail);
                    setcandidatePhone(data.providerphone);
                    setuserSummary(data.cvsummary);
                    setuserDetails(data.cvdetails);
                    setskilPoints(data.cvskillpoints);
                    setexperiences(data.cvexperiences);
                    seteducations(data.cveducations);
                    setcertificates(data.cvcertifications);

                    setuserOptionalSkills(data.cvoptionalskills);
                    setuserPortfolioLinks(data.cvportfoliolinks);
                    setuserHobbies(data.cvhobbies);
                    setuserStartTime(data.cvstarttime);
                    setuserEndTime(data.cvendtime);

                    setUserLanguages(data.cvlanguages);
                    setRoles(data.cvroles);
                    setCarrierHighlights(data.cvcarrierhighlights);
                    setExperienceInYears(data.cvexperienceinyears);
                    setProjects(data.cvprojects);
                    setOtherSkills(data.cvotherskills);
                    setJobPublishDate(data.jobpublishdate);
                    setJobResponseDate(data.cvresponsedate)

                }
            }
            } catch (error) {
            console.log(error.message);
            }
        };

        //closeHandler
        const closeHandler = () => {
            console.log("close");
        };

        //approve status
        const approveStatusHandler = (status) => {
            setapprovalStatus(status);
            setSelectedHiringProcessId(null);
        };

        useEffect(() => {
            if (respId) {
            getJobResponseDetails();
            }
        }, [respId]);


        return (

            
            <div className="col-lg-8">
                <div className="builder_leftpnl">
                    {/* Job details ------------------------------- */}
                    <div className="builder_item white_shadow_bx mb-3">
                        <div className="top_company_row mb-3">
                            <div className="company_logotxt">
                                <span></span>
                                <span>{jobPostCompany}</span>
                            </div>
                        </div>
                        <div className="code_price_outer d-flex align-items-center justify-content-between gap-3 flex-wrap">
                            <div className="event_hdng">
                                <h6 className="text-gray fs-sm mb-3">Code: {" "}
                                    <span className="d-inline-block text-blue">{responseCode}</span>
                                </h6>
                                <h3 className="mb-0">{jobPost}</h3>
                            </div>
                            <div className="code_info_list">
                                {/* <ul className="list_stye_none d-flex gap-2 flex-wrap justify-content-end">
                                    <li className="d-flex align-items-center justify-content-center px-3 py-2 rounded-90 bg-light fs-sm text-black-gray">Of The</li>
                                    <li className="d-flex align-items-center justify-content-center px-3 py-2 rounded-90 bg-green fs-sm text-white">Provider Price: $150K</li>
                                    <li className="d-flex align-items-center justify-content-center px-3 py-2 rounded-90 bg-green fs-sm text-white">Agency Price: $150K</li>
                                </ul> */}
                            </div>
                        </div>
                    
                    </div>

                    {/* Candidate basic details ------------------------- */}
                    <div className="builder_item white_shadow_bx px-4 py-0 mb-3">
                        <div className="build_innr_row py-4 overflow-hidden">
                            <div className="builder_usr_row d-flex justify-content-between">
                                <div className="build_usrleft_pnl d-flex gap-3">
                                    <div className="build_usrpic position-relative">
                                        <figure><img src={assetImages.defaultUser} alt=""/></figure>
                                        
                                    </div>
                                    <div className="build_usrinfo">
                                        <h3>{candidateName} {candiddateSurname}
                                            <span className="d-block">{candidatePosition}</span></h3>
                                        <ul className="list_stye_none d-flex align-items-center mt-3 gap-4 mb-3 flex-wrap">
                                            <li className="d-flex align-items-center gap-2">
                                                <i className="material-icons">email</i>
                                                <span>{candidateEmail}</span>
                                            </li>
                                            <li className="d-flex align-items-center gap-2">
                                                <i className="material-icons">phone</i>
                                                <span>{candidatePhone}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="build_innr_row py-4">
                            <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                <div className="build_hdng d-flex align-items-center gap-1">
                                    <h3>Summary</h3>
                                </div>
                            </div>
                            <div className="build_para">
                                <p>
                                    {userSummary}
                                </p>
                            </div>
                        </div>

                        {/* Carrier Highlights */}
                        <div className="build_innr_row py-4">
                            <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                <div className="build_hdng d-flex align-items-center gap-1">
                                    <h3>Carrier Highlights</h3>
                                </div>
                            </div>
                            <div className="event_tags">
                                {carrierHighlights && carrierHighlights.map((carrierhighlight, index) => {
                                    return (
                                    <div
                                        className="cv_row p-3 d-flex align-items-center justify-content-between"
                                        key={index}
                                    >
                                        <div className="cv_left_text">
                                            <h5>{carrierhighlight}</h5>
                                        </div>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>

                        
                        {/* Experience in years*/}
                        <div className="build_innr_row py-4">
                            <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                <div className="build_hdng d-flex align-items-center gap-1">
                                    <h3>Experience : <span className="fw-normal">{experienceInYears} Years</span></h3>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Candidate details ------------------------- */}
                    <div className="builder_item white_shadow_bx mb-3">
                        <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2">
                            <div className="build_hdng d-flex align-items-center gap-1">
                                <h3>Candidate's CV Details </h3>
                            </div>
                            <div className="build_edit_icon">
                                <a href="javascript:void(0);" className="expand_arw d-flex align-items-center justify-content-center rounded-circle" data-bs-toggle="collapse" data-bs-target="#details_collapse"><i className="material-icons">expand_more</i></a>
                            </div>
                        </div>
                        <div className="collapse" id="details_collapse">


                            

                            {/* Roles */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Roles</h3>
                                    </div>
                                </div>
                                <div className="event_tags">
                                    {roles && roles.map((role, index) => {
                                        return (
                                        <div
                                            className="cv_row p-3 d-flex align-items-center justify-content-between"
                                            key={index}
                                        >
                                            <div className="cv_left_text">
                                                <h5>{role}</h5>
                                            </div>
                                        </div>
                                        );
                                    })}
                                </div>
                            </div>

                            {/* Skills */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Skills</h3>
                                    </div>
                                </div>
                                <div className="event_tags">
                                    <ul className="list_stye_none flex-wrap mb-0">
                                       {skilPoints && skilPoints.map((skillpoint, index) => {
                                            return (
                                                <li className="me-3" key={index}>
                                                    <span className="fw-bold">{skillpoint.skillname}</span> {", "} 
                                                    <span className="ms-3">Score: {skillpoint.points}/10</span> {", "}
                                                    <span className="ms-3">Experience: {skillpoint.experienceyear} years</span>
                                                </li>
                                                )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            
                            {/*  Education */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Education</h3>
                                    </div>
                                </div>
                                {educations && educations.map((education, index) => {
                                    return (
                                        <div className="gray_bx_outer d-flex gap-2" key={index}>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Degree</h4>
                                                <h3>{education.degree}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Institute</h4>
                                                <h3>{education.institute}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Year</h4>
                                                <h3>{education.year}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Grade</h4>
                                                <h3>{education.grade}</h3>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/*  Detail Experience */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Work Experience</h3>
                                    </div>
                                </div>
                                {experiences && experiences.map((experience, index) => {
                                    return (
                                        <div className="gray_bx_outer d-flex gap-2" key={index}>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Position</h4>
                                                <h3>{experience.position}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Company</h4>
                                                <h3>{experience.company}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">Start</h4>
                                                <h3>{experience.start}</h3>
                                            </div>
                                            <div className="gray_bx_item">
                                                <h4 className="mb-2">End</h4>
                                                <h3>{experience.end}</h3>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/*  Project Preference */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Project Preference</h3>
                                    </div>
                                </div>
                                {projects && projects.map((project, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="gray_bx_outer d-flex gap-2">
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Role</h4>
                                                    <h3>{project.position}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Company</h4>
                                                    <h3>{project.company}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Start</h4>
                                                    <h3>{project.start}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">End</h4>
                                                    <h3>{project.end}</h3>
                                                </div>                                            
                                            </div>
                                            <p className="ps-1"><span className="fw-bold me-2">Technologies</span>: {project.technologies}</p>
                                            <p className="ps-1"><span className="fw-bold me-2">Responsibilities</span>: {project.responsibilities}</p>
                                     </div>
                                    );
                                })}
                            </div>

                            {/*  Technical Certificate */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Technical Certificate</h3>
                                    </div>
                                </div>
                                {certificates && certificates.map((certificate, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="gray_bx_outer d-flex gap-2">
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Course/Certificate</h4>
                                                    <h3>{certificate.name}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Company</h4>
                                                    <h3>{certificate.companyname}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">Start</h4>
                                                    <h3>{certificate.start}</h3>
                                                </div>
                                                <div className="gray_bx_item">
                                                    <h4 className="mb-2">End</h4>
                                                    <h3>{certificate.end}</h3>
                                                </div>                                            
                                            </div>
                                            
                                     </div>
                                    );
                                })}
                            </div>

                            {/* Language */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Languages</h3>
                                    </div>
                                </div>
                                <div className="event_tags">
                                    <ul className="list_stye_none flex-wrap mb-0">
                                       {userLanguages && userLanguages.map((language, index) => {
                                            return (
                                                <li className="me-3" key={index}>
                                                    {language}
                                                </li>
                                                )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {/* Other Skills */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Other Skills</h3>
                                    </div>
                                </div>
                                <div className="event_tags">
                                    <ul className="list_stye_none flex-wrap mb-0">
                                       {otherSkills && otherSkills.map((otherskill, index) => {
                                            return (
                                                <li className="me-3" key={index}>
                                                    {otherskill}
                                                </li>
                                                )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {/* Hobbies */}
                            <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Hobbies</h3>
                                    </div>
                                </div>
                                <div className="event_tags">
                                    <ul className="list_stye_none flex-wrap mb-0">
                                       {userHobbies && userHobbies.map((hobbie, index) => {
                                            return (
                                                <li className="me-3" key={index}>
                                                    {hobbie}
                                                </li>
                                                )
                                        })}
                                    </ul>
                                </div>
                            </div>


                            {/* <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Salary and Timings</h3>
                                    </div>
                                </div>
                                <div className="gray_bx_outer d-flex gap-2">
                                    <div className="gray_bx_item">
                                        <h4 className="mb-2">Current CTC</h4>
                                        <h3>36,000 USD</h3>
                                    </div>
                                    <div className="gray_bx_item">
                                        <h4 className="mb-2">Expected CTC</h4>
                                        <h3>40,000 USD</h3>
                                    </div>
                                    <div className="gray_bx_item">
                                        <h4 className="mb-2">Monthly Working</h4>
                                        <h3>160 Hours</h3>
                                    </div>
                                    <div className="gray_bx_item">
                                        <h4 className="mb-2">Timings</h4>
                                        <h3>CET 11:00 - CET 16:00</h3>
                                    </div>
                                </div>
                            </div> */}
                            
                            {/* <div className="build_innr_row py-4">
                                <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                    <div className="build_hdng d-flex align-items-center gap-1">
                                        <h3>Attachments</h3>
                                    </div>
                                </div>
                                <div className="attachedment_doc_row d-flex align-items-center gap-2 flex-wrap">
                                    <div className="doc_item bg-light px-4 py-3 d-flex align-items-center justify-content-between gap-3">
                                        <div className="pdf_info d-flex align-items-center gap-2">
                                            <i className="d-inline-block"><img src="images/pdf.svg" alt=""/></i>
                                            <div className="pdf_text">
                                                <h5 className="text-black fs-sm mb-1">CV .pdf</h5>
                                                <h6 className="fs-xs text-black">1.5mb</h6>
                                            </div>
                                        </div>
                                        <div className="pdf_dwnload">
                                            <a href="javascript:void(0);">
                                                <span className="material-icons-outlined text-black">file_download</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="doc_item bg-light px-4 py-3 d-flex align-items-center justify-content-between gap-3">
                                        <div className="pdf_info d-flex align-items-center gap-2">
                                            <i className="d-inline-block"><img src="images/pdf.svg" alt=""/></i>
                                            <div className="pdf_text">
                                                <h5 className="text-black fs-sm mb-1">Application Letter Application Letter.pdf</h5>
                                                <h6 className="fs-xs text-black">1.5mb</h6>
                                            </div>
                                        </div>
                                        <div className="pdf_dwnload">
                                            <a href="javascript:void(0);">
                                                <span className="material-icons-outlined text-black">file_download</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                        </div>
                        
                    </div>


                    {/* <div className="builder_item white_shadow_bx mb-3">
                        <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2">
                            <div className="build_hdng d-flex align-items-center gap-1">
                                <h3>Survey Responses</h3>
                            </div>
                            <div className="build_edit_icon">
                                <a href="javascript:void(0);" className="expand_arw d-flex align-items-center justify-content-center rounded-circle" data-bs-toggle="collapse" data-bs-target="#survey_collapse"><i className="material-icons">expand_more</i></a>
                            </div>
                        </div>
                        <div className="collapse mt-4" id="survey_collapse">
                            <div className="question_row mb-3">
                                <h6 className="fs-sm mb-2 text-black">Question 1</h6>
                                <h3 className="text-black mb-2 fs-md fw-semibold">The ATM system has the ability to use all existing surveillance sources?</h3>
                                <div className="survey_rating mb-3">
                                    <ul className="list_stye_none d-flex align-items-center">
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-gray"><i className="material-icons">star</i></li>
                                    </ul>
                                </div>
                                <div className="rvw_bx bg-light p-3 rounded-10">
                                    <p>
                                        The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another
                                    </p>
                                </div>
                            </div>
                            <div className="question_row mb-3">
                                <h6 className="fs-sm mb-2 text-black">Question 2</h6>
                                <h3 className="text-black mb-2 fs-md fw-semibold">The ATM system has the ability to use all existing surveillance sources?</h3>
                                <div className="survey_rating mb-3">
                                    <ul className="list_stye_none d-flex align-items-center">
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-gray"><i className="material-icons">star</i></li>
                                    </ul>
                                </div>
                                <div className="rvw_bx bg-light p-3 rounded-10">
                                    <p>
                                        The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another
                                    </p>
                                </div>
                            </div>
                            <div className="question_row mb-3">
                                <h6 className="fs-sm mb-2 text-black">Question 3</h6>
                                <h3 className="text-black mb-2 fs-md fw-semibold">The ATM system has the ability to use all existing surveillance sources?</h3>
                                <div className="survey_rating mb-3">
                                    <ul className="list_stye_none d-flex align-items-center">
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-green"><i className="material-icons">star</i></li>
                                        <li className="text-gray"><i className="material-icons">star</i></li>
                                    </ul>
                                </div>
                                <div className="rvw_bx bg-light p-3 rounded-10">
                                    <p>
                                        The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another The ATM system has the ability to use all existing surveillance sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually (monosensor) and combined (multisensor) presenting only one surveillance track that does not jump when switching from one source to another
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="builder_item white_shadow_bx px-4 py-0 mb-3">
                        <div className="build_innr_row py-4">
                            <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
                                <div className="build_hdng d-flex align-items-center gap-1">
                                    <h3>Response times</h3>
                                </div>
                            </div>
                            <div className="gray_bx_outer d-flex gap-2">
                                <div className="gray_bx_item">
                                    <h4 className="mb-2">Customer request</h4>
                                    <h3>{jobPublishDate}</h3>
                                </div>
                                <div className="gray_bx_item">
                                    <h4 className="mb-2">Offer Published</h4>
                                    <h3>1 hr {jobPublishDate}</h3>
                                </div>
                                <div className="gray_bx_item">
                                    <h4 className="mb-2">Provider Response</h4>
                                    <h3>{jobResponseDate}</h3>
                                </div>
                                <div className="gray_bx_item">
                                    <h4 className="mb-2">1st Customer Response</h4>
                                    <h3></h3>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
                            
                        
            
        );

};

export default CustomerFeedbackLeftPanel;
