/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import CourseLandingBody from "components/CoursesComponents/CourseLandingComponents/CourseLandingBody/CourseLandingBody";

const CourseLanding = () => {
  const { t, i18n } = useTranslation(); // translation marker

  return (
    <>
      {/* ------- header section ---------- */}

      <AuthHeader />

      {/* --------- body section ------------ */}
      <CourseLandingBody />
    </>
  );
};

export default CourseLanding;
