/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import MostImportantPanel from "../../MostImportantPanel/MostImportantPanel";
import GiveReviewModal from "components/common/Modals/GiveReviewModal";
import { assetImages } from "constants";

const ReviewTab = ({ ratingCount, reviewsList = [], ratingPercentArr }) => {
  const starCount = [1, 2, 3, 4, 5];
  const floatValue = parseFloat(ratingCount); // Parse the string as a floating-point number
  const intValue = Math.ceil(floatValue); // Round up to the nearest integer

  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="forum_bx white_shadow_bx">
          <div className="rvw_rating_bx">
            <div className="left_rvwpnl">
              <h2>
                {ratingCount} <span>({reviewsList.length})</span>
              </h2>
              <ul className="list_stye_none">
                {starCount.map((count, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">
                        <i
                          className={`material-icons-outlined ${
                            index < intValue ? "ornge_icon" : ""
                          }`}
                        >
                          star
                        </i>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className="add_rvw_btn">
                <Link
                  to="#"
                  className="btn outline"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback_mdl"
                >
                  Add Review
                </Link>
              </div>
            </div>

            <div className="right_rvwpnl">
              <div className="rvw_progress_outer">
                {ratingPercentArr.map((ratingBar, index) => {
                  return (
                    <div className="progress_row" key={index}>
                      <div className="progress_star">
                        <span>{ratingBar.ratingname} Star</span>
                      </div>
                      <div className="progess_outer">
                        <span
                          className="progress_bar"
                          style={{ width: `${ratingBar.ratingpercent}%` }}
                        ></span>
                      </div>
                      <div className="rvw_percent">
                        <span>{ratingBar.ratingpercent}%</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="forum_commnts_pnl review_commnts">
            {reviewsList.map((reviewData, index) => {
              return (
                <div className="forum_row" key={index}>
                  <div className="forum_cmntsRow">
                    {/* <div className="share_btn forum_shreBtn">
                  <Link to="#">
                    <span className="material-icons-outlined">share</span>
                  </Link>
                </div> */}
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img
                          src={
                            reviewData.userimage == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + reviewData.userimage
                          }
                          alt=""
                        />
                      </div>
                      <div className="team_usrTxt">
                        <h3>{reviewData.username}</h3>
                        <h5>
                          {reviewData.reviewdate} {reviewData.reviewtime}
                        </h5>
                        <div className="rating_star">
                          <ul className="list_stye_none">
                            {starCount.map((count, index) => {
                              return (
                                <li key={index}>
                                  <Link to="#">
                                    <i
                                      className={`material-icons-outlined ${
                                        index < parseInt(reviewData.rating)
                                          ? "ornge_icon"
                                          : ""
                                      }`}
                                    >
                                      star
                                    </i>
                                  </Link>
                                </li>
                              );
                            })}
                            {/* {Array.from(
                          { length: reviewData.rating },
                          (_, index) => (
                            <li className="active" key={index}>
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                              </Link>
                            </li>
                          )
                        )} */}
                          </ul>
                        </div>
                        <div className="forum_para">
                          <p>{reviewData.comment}</p>
                        </div>

                        {/* <div className="form_share">
                      <Link to="#">
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>Likes</em>
                        <span>2</span>
                      </Link>
                      <Link to="#" className="commnt_forum">
                        <i className="material-icons-outlined">chat</i>
                        <em>Comment</em>
                        <span>3</span>
                      </Link>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default ReviewTab;
