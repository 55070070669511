/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

import { assetImages } from "constants";

const ConversationGroupDetailsPopup = ({
  conversationName = "",
  selectedChatLogo = "",
  groupModName = "",
  groupModImage = "",
  groupMembers = [],
  groupFiles = [],
  groupModId = null,
  selectedChatRoomId = null,
}) => {
  const { setafterLeaveGroupReload } = useContext(GlobalProvider);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //function for leave group
  const leaveGroupHandler = async () => {
    let chatData = {
      group: selectedChatRoomId,
    };

    let requestURL = url.API_BASE_URL + url.API_LEAVE_GROUP + `?token=${token}`;

    const response = await postData(requestURL, chatData);

    console.log(response);

    if (response.status) {
      let offCanvasPopup = document.querySelector("#profile_pnl");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
      setafterLeaveGroupReload(true);
    }
  };

  return (
    <div className="profile_rightpopup">
      <div className="offcanvas offcanvas-end" id="profile_pnl">
        <div className="offcanvas-body">
          <div className="profile_rightpnl">
            <div className="profile_hdngTop">
              <h3>Group Information</h3>
              <span className="profile_crossbtn" data-bs-dismiss="offcanvas">
                <i className="material-icons-round">close</i>
              </span>
            </div>
            <div className="profile_innr_body">
              <div className="profile_innrScroll">
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    {/* <!-- ========== Start group logo Section ========== --> */}
                    <div className="profile_img grp_profileImg">
                      <div className="chatting_user">
                        {selectedChatLogo === "" ? (
                          <>
                            <figure className="group_usr">
                              <img
                                src={
                                  groupModImage === ""
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL + groupModImage
                                }
                                alt=""
                              />
                            </figure>
                            {groupMembers.slice(0, 2).map((member, index) => {
                              return (
                                <figure className="group_usr" key={index}>
                                  <img
                                    src={
                                      member.groupmemberimage === ""
                                        ? assetImages.defaultUser
                                        : url.SERVER_URL +
                                          member.groupmemberimage
                                    }
                                    alt=""
                                  />
                                </figure>
                              );
                            })}
                            {groupMembers.length + 1 > 3 ? (
                              <span className="group_usr plus_usr">
                                {groupMembers.length + 1 - 3} <em>+</em>
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <img
                            className="rounded-circle"
                            src={url.SERVER_URL + selectedChatLogo}
                            alt="profile"
                          />
                        )}
                      </div>
                    </div>
                    {/* <!-- ========== End group logo Section ========== --> */}

                    {/* <!-- ========== Start group title and member count Section ========== --> */}
                    <div className="profile_textPnl">
                      <h4>{conversationName}</h4>
                      <h5>{groupMembers.length + 1} Participants</h5>
                    </div>
                    {/* <!-- ========== End group title and member count Section ========== --> */}
                  </div>
                </div>

                {/* <!-- ========== Start moderrator block Section ========== --> */}
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">person</i>
                      <span>Moderator</span>
                    </div>
                    <div className="chat_usrRow incoming_msg_row">
                      <Link to="#">
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={
                                groupModImage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + groupModImage
                              }
                              alt="profile"
                            />
                          </div>
                          <div className="user_name">
                            <h3>{groupModName}</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End moderrator block Section ========== --> */}

                {/* <!-- ========== Start member Section ========== --> */}
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">person</i>
                      <span>Members</span>
                    </div>
                    {groupMembers.slice(0, 4).map((member, index) => {
                      return (
                        <div
                          className="chat_usrRow incoming_msg_row"
                          key={index}
                        >
                          <Link to="#">
                            <div className="usr_lftPnl">
                              <div className="chatting_user">
                                <img
                                  src={
                                    member.groupmemberimage === ""
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + member.groupmemberimage
                                  }
                                  alt="profile"
                                />
                              </div>
                              <div className="user_name">
                                <h3>{member.groupmembername}</h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}

                    {groupMembers.length > 4 ? (
                      <div className="chat_usrRow incoming_msg_row mt-2 ms-3">
                        <div className="usr_lftPnl">
                          <div className="user_name">
                            <h3>+ {groupMembers.length - 4} More ...</h3>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <!-- ========== End member Section ========== --> */}

                {/* <!-- ========== Start leave group Section ========== --> */}
                {groupModId ? (
                  groupModId.toString() === userInfo._id.toString() ? null : (
                    <div className="profile_info_row">
                      <div className="profile_details_row">
                        <Link
                          to="#"
                          className="info_icon w-100"
                          onClick={leaveGroupHandler}
                        >
                          <i className="material-icons-round">logout</i>
                          <span>Leave Group</span>
                        </Link>
                      </div>
                    </div>
                  )
                ) : null}
                {/* <!-- ========== End leave group Section ========== --> */}

                {/* mute notification */}
                {/* <div className="profile_info_row">
                  <div className="profile_details_row mute_row">
                    <Link to="#" className="info_icon w-100">
                      <i className="material-icons-round">
                        notifications_active{" "}
                      </i>
                      <span>Mute Notification</span>
                    </Link>
                    <div className="toggle_switch">
                      <div className="switch_box box_1">
                        <input type="checkbox" className="switch_1" />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* group gallery section */}
                {/* <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">collections </i>
                      <span>Attachments</span>
                    </div>
                    <div className="profile_attachedment_outer">
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached1.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached2.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached4.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached5.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached6.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/fancy-big.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached2.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/attached4.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached4.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/attached6.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached6.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/attached1.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached1.jpg" alt="" />
                        </Link>
                      </div>

                      <div className="attachedment_item">
                        <Link
                          href="images/attached3.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="attachedment_item">
                        <Link
                          href="images/attached5.jpg"
                          data-fancybox="images"
                        >
                          <img src="images/attached5.jpg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationGroupDetailsPopup;
