import React from 'react';

const ServicesTab = () => {
  return (
    <div className="service_outerbx">
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>
            Application Developer{' '}
            <span className="material-icons">check_circle</span>
          </h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined ornge_icon"> star </i>
            <span className="rating">
              4.5 <em>(32)</em>
            </span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>
            CMS Developer <span className="material-icons">check_circle</span>
          </h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined ornge_icon"> star </i>
            <span className="rating">
              4.5 <em>(32)</em>
            </span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>
            Mobile Application Design{' '}
            <span className="material-icons">check_circle</span>
          </h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined ornge_icon"> star </i>
            <span className="rating">
              4.5 <em>(32)</em>
            </span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>
            CMS Developer <span className="material-icons">check_circle</span>
          </h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined ornge_icon"> star </i>
            <span className="rating">
              4.5 <em>(32)</em>
            </span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
      <div className="service_row white_shadow_bx">
        <div className="srvc_top">
          <h3>
            CMS Developer <span className="material-icons">check_circle</span>
          </h3>
          <div className="jb_rating star_color">
            <i className="material-icons-outlined ornge_icon"> star </i>
            <span className="rating">
              4.5 <em>(32)</em>
            </span>
          </div>
        </div>
        <div className="results_para">
          <p>
            Over the next 6 years he occasionally poppe dnto my office to
            politely suggest whate tho ught my priorities should business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesTab;
